.category-filter-section {
  margin-top:-10px;
  text-align:center; font-size:0;
  background-color:color(grey);
  z-index:2;

  @include media-breakpoint-up(lg) {
    margin-top:calc(-10px - 54 * ((100vw - 992px) / 448));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-64px;
  }

  &.mt-0 {
    padding-top:26px;
  }

  .category-filter-wrapper {
    display:block; float:left;
    width:calc(100% + 40px);
    margin-left:-20px; margin-right:-20px;
    padding-left:6px; padding-right:6px;
  }

  ul {
    display:flex; float:none; flex-direction:row; justify-content:flex-start;
    width:auto;
    overflow:auto;

    @include media-breakpoint-up(lg) {
      justify-content:center;
    }

    li {
      position:relative;
      width:auto;
      padding:0 10px 20px 10px;

      @include media-breakpoint-up(xs-375) {
        padding:0 calc(10px + 4 * ((100vw - 375px) / 1065)) calc(20px + 12 * ((100vw - 375px) / 1065)) calc(10px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding:0 14px 32px 14px;
      }

      a {
        display:block; float:left;
        width:auto;
        padding:4px;
        font-size:16px; line-height:18px; color:color(black); font-family:$font_frutiger; letter-spacing:0.02em;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          font-size:calc(16px + 2 * ((100vw - 375px) / 1065)); line-height:calc(18px + 6 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(red);
          }
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:18px; line-height:24px;
        }
      }
      &:before {
        content:'';
        position:absolute; left:50%; bottom:0;
        width:0%; height:3px;
        background-color:color(red);
        @include prefix(transform, translateX(-50%), webkit moz o ms);
      }

      &.active {
        a {
          color:color(red); font-family:$font_frutiger_bold;
        }
        &:before {
          width:calc(100% - 14px);
        }
      }
    }
  }
}

.filter-wrapper {
  display:flex; justify-content:space-between; align-items:flex-start; flex-direction:column;
  padding-bottom:22px; margin-bottom:16px;
  border-bottom:2px solid color(grey);

  @include media-breakpoint-up(xs-375) {
    padding-bottom:calc(22px + 10 * ((100vw - 375px) / 1065)); margin-bottom:calc(16px + 10 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl) {
    align-items:center; flex-direction:row;
  }
  @include media-breakpoint-up(xl-1440) {
    padding-bottom:32px; margin-bottom:26px;
  }

  &.pb-0 {
    border-bottom:0 none;
  }

  p {
    width:auto;
    font-size:12px; line-height:20px; color:color(dark-grey); font-family:$font_frutiger; letter-spacing:0;

    @include media-breakpoint-up(xs-375) {
      font-size:calc(12px + 2 * ((100vw - 375px) / 1065)); line-height:calc(18px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      font-size:14px; line-height:24px;
    }
  }
  > ul {
    width:calc(100% + 20px);
    margin-left:-20px;

    @include media-breakpoint-up(xl) {
      width:auto;
      margin-left:0;
    }

    > li {
      width:50%;
      padding-left:20px; margin-top:10px;

      @include media-breakpoint-up(xs-360) {
        width:33.33%;
      }
      @include media-breakpoint-up(md) {
        width:25%;
      }
      @include media-breakpoint-up(lg) {
        width:auto;
      }
      @include media-breakpoint-up(xl) {
        padding-left:calc(20px + 12 * ((100vw - 1200px) / 240)); margin-top:0;
      }
      @include media-breakpoint-up(xl-1440) {
        padding-left:32px;
      }

      [type="radio"]:checked, [type="radio"]:not(:checked),
      [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
        position:absolute; left:-9999px;
        padding:0; border:0 none;
        width:0; height:0;
      }
      [type="radio"]:checked + label, [type="radio"]:not(:checked) + label,
      [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
        position:relative;
        display:inline-block;
        padding-left:26px; margin:0;
        cursor:pointer;
        font-size:14px; line-height:20px; color:color(dark); font-family:$font_frutiger;
      }
      [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
        content:'';
        position:absolute; left:0; top:2px;
        width:16px; height:16px;
        border:2px solid color(med-grey);
        background-color:transparent;
        @include prefix(border-radius, 50%, webkit moz o ms);
        @include prefix(transition, all .2s ease-out, webkit moz o ms);
      }
      [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
        content:'';
        position:absolute; left:0; top:0;
        width:20px; height:20px;
        border:2px solid color(med-grey);
        background-color:transparent;
        @include prefix(border-radius, 4px, webkit moz o ms);
        @include prefix(transition, all .2s ease-out, webkit moz o ms);
      }
      [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
        content:'';
        position:absolute;
        left:4px; top:6px;
        width:8px; height:8px;
        background-color:color(dark);
        opacity:0;
        @include prefix(border-radius, 50%, webkit moz o ms);
        @include prefix(transition, all .2s ease-out, webkit moz o ms);
      }
      [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
        content:$icon-mendrisio-check;
        position:absolute;
        left:0; top:0;
        width:20px; height:20px;
        font-size:12px; line-height:20px; color:color(dark); font-family:'icomoon'!important; font-weight:normal; font-style:normal; text-align:center;
        opacity:0;
        @include prefix(transition, all .2s ease-out, webkit moz o ms);
      }
      [type="checkbox"]:checked + label:before {
        border:2px solid color(dark);
      }
      [type="radio"]:checked + label:after,
      [type="checkbox"]:checked + label:after {
        opacity:1;
      }
    }
  }

  .select2-container {
    &.select2-container--open {
      .select2-dropdown--below {
        .select2-results__options {
          .select2-results__option {
            &:first-child {
              display:none;
            }
          }
        }
      }
    }
  }
  > .filter-select {
    width:100%;
    margin-top:14px;

    @include media-breakpoint-up(sm) {
      width:calc(50% - 14px);
      margin-right:14px;
    }
    @include media-breakpoint-up(md) {
      width:calc(33.33% - 14px);
    }
    @include media-breakpoint-up(lg) {
      width:100%; max-width:295px;
      margin-left:28px; margin-top:0; margin-right:0;
    }
  }
  > .filter-radio {
    width:auto;
    margin-top:14px; margin-right:14px;

    @include media-breakpoint-up(lg) {
      margin-left:28px; margin-top:0; margin-right:0;
    }

    [type="radio"]:checked, [type="radio"]:not(:checked) {
      position:absolute; left:-9999px;
      padding:0; border:0 none;
      width:0; height:0;
    }
    [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
      position:relative;
      display:inline-block;
      height:56px;
      padding:18px; margin:0;
      border-radius:4px;
      font-size:14px; line-height:20px; color:color(black); font-family:$font_frutiger; letter-spacing:0.28px;      
      background-color:color(white);
      cursor:pointer;
      @include prefix(transition, all .2s ease-out, webkit moz o ms);
    }
    [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      border-radius:4px; border:1px solid color(med-grey)!important;
      background-color:transparent;
      @include prefix(transition, all .2s ease-out, webkit moz o ms);
    }
    [type="radio"]:checked + label {
      color:color(white);   
      background-color:color(red);
    }
    [type="radio"]:checked + label:before {
      border:1px solid color(red)!important;
    }
  }

  &.list {
    margin-top:20px; margin-bottom:0;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(20px + 12 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl) {
      justify-content:flex-end;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:32px;
    }
  }
  &.left, &.right, &.full {
    @include media-breakpoint-up(sm) {
      flex-direction:row; flex-wrap:wrap;

      > p {
        width:100%;
      }
    }
    @include media-breakpoint-up(lg) {
      align-items:center;

      > p {
        width:auto;
      }
    }
  }
  &.full {
    > div {
      display:flex;

      @include media-breakpoint-up(sm) {
        flex-direction:row; flex-wrap:wrap;

        > p {
          width:100%;
        }
      }
      @include media-breakpoint-up(lg) {
        align-items:center;

        > p {
          width:auto;
        }
      }
    }
  }
  &.left {
    justify-content:flex-start;
  }
  &.right {
    justify-content:flex-end;
  }
  &.results {
    flex-direction:row; justify-content:space-between; align-items:flex-end; flex-wrap:wrap;
    margin-top:-26px; padding-bottom:24px; margin-bottom:0;
    overflow:hidden;

    .results-link {
      display:flex; justify-content:flex-end; align-items:flex-start; flex-direction:column;
      float:left;
      width:100%;
      margin-bottom:36px;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(36px + 10 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(md) {
        flex-direction:row;
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:46px;
      }

      p {
        font-size:14px; line-height:24px; color:color(dark-grey); font-family:$font_frutiger; letter-spacing:0; white-space:nowrap;

        @include media-breakpoint-up(md) {
          margin-right:calc(20px + 6 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-right:26px;
        }
      }
      ul {
        width:auto;
        margin-right:-20px;

        @include media-breakpoint-up(xs-375) {
          margin-right:calc(-20px - 9 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(md) {
          margin-top:-8px;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-right:-29px;
        }

        li {
          width:auto;
          margin-right:20px; margin-top:8px;

          @include media-breakpoint-up(xs-375) {
            margin-right:calc(20px + 9 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-right:29px;
          }

          a {
            position:relative;
            display:block; float:left;
            width:auto;
            font-size:14px; line-height:24px; color:color(red); font-family:$font_frutiger; letter-spacing:0; white-space:nowrap;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            strong {
              font-family:$font_frutiger_bold;
            }

            &:after {
              content:'';
              position:absolute; left:0; bottom:0;
              width:100%; height:1px;
              background-color:color(red);
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }

            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(dark);

                &:after {
                  background-color:color(dark);
                }
              }
            }
          }
        }
      }
    }

    > h2, .h2 {
      width:auto;
      font-size:24px; line-height:26px;

      @include media-breakpoint-up(xs-375) {
        font-size:calc(24px + 4 * ((100vw - 375px) / 1065)); line-height:calc(26px + 6 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        font-size:28px; line-height:32px;
      }
    }
    > p {
      color:color(black);

      strong {
        font-family:$font_frutiger_bold; color:color(red);
      }
    }
  }

  > .filter-wrapper {
    padding:0; margin:0; border:0 none;
    width:100%;

    @include media-breakpoint-up(lg) {
      width:55%;
    }

    &.date-filter {
      z-index:2;

      @include media-breakpoint-down(sm) {
        flex-direction:row; flex-wrap:wrap;
      }
      @include media-breakpoint-up(lg) {
        width:45%;
      }

      .filter-calendar {
        .label {
          z-index:2;
        }
        .agenda-calendar-wrapper {
          top:0;
          width:100%;
          padding:66px 6px 20px 6px;
          border:0 none!important;
          z-index:1;
          @include prefix(box-shadow, 0px 4px 40px 0px rgba(0,0,0,0.06), webkit moz o ms);

          @include media-breakpoint-up(sm) {
            width:calc(320px + 180 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            width:500px;
          }

          @include media-breakpoint-up(xs-375) {
            padding:calc(66px + 10 * ((100vw - 375px) / 1065)) 6px calc(20px + 4 * ((100vw - 375px) / 1065)) 6px;
          }
          @include media-breakpoint-up(xl-1440) {
            padding:76px 6px 24px 6px;
          }

          .btn {
            margin:0 12px;
          }
        }

        &.open {
          .label {
            border-color:color(white)!important;
          }
        }
      }
      .filter-select {  
        @include media-breakpoint-up(sm) {
          max-width:295px;
        }
        @include media-breakpoint-up(lg) {
          max-width:225px;
        }
        @include media-breakpoint-up(xl) {
          max-width:295px;
        }
      }
    }

    &.left {
      margin-top:-14px;
      
      @include media-breakpoint-up(lg) {
        margin-top:0;
      }
    }

    > .filter-select, > .filter-radio {
      margin-top:14px;
      
      @include media-breakpoint-up(lg) {
        margin-top:0; margin-left:20px;
      }
      @include media-breakpoint-up(xl) {
        margin-right:0;
      }
    }
    > .filter-select {  
      @include media-breakpoint-up(md) {
        width:calc(50% - 14px);
      }
      @include media-breakpoint-up(lg) {
        width:100%; max-width:225px;
      }
      @include media-breakpoint-up(xl) {
        max-width:295px;
      }
    }
  }
}

.filter-select-wrapper {
  position:relative;
  z-index:3;
}
