.district-map-col {
  @include media-breakpoint-down(lg) {
    display:flex; justify-content:center;
  }
}

.district-map-wrapper {
  position:relative;
  float:left;
  width:100%; max-width:440px;

  @include media-breakpoint-up(xl) {
    width:calc(440px + 140 * ((100vw - 1200px) / 240)); max-width:none;
  }
  @include media-breakpoint-up(xl-1440) {
    width:580px;
  }

  .district-list {
    position:absolute; left:0; top:0;
    display:none;
    width:100%; height:100%;
    z-index:2;

    @include media-breakpoint-up(lg) {
      display:block;
    }

    li {
      position:absolute;
      width:auto; min-width:112px;
      border-radius:4px;
      background-color:color(white);
      @include prefix(box-shadow, 2px 2px 10px 0px rgba(0,0,0,0.08), webkit moz o ms);
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        border:1px solid color(med-grey); border-radius:4px;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
        z-index:1;
      }
      a {
        position:relative;
        display:block; float:left;
        width:100%;
        padding:10px 30px 20px 10px;
        border-radius:4px;
        font-size:15px; line-height:22px; color:color(dark); font-family:$font_frutiger_bold; font-weight:normal;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
        z-index:2;

        &:before {
          content:'';
          position:absolute; left:10px; bottom:8px;
          width:48px; height:2px;
          background-color:color(red);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
          z-index:1;
        }
        &:after {
          content:$icon-mendrisio-chevron-right;
          position:absolute; right:10px; top:10px;
          width:9px; height:22px;
          font-size:9px; line-height:22px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
          z-index:1;
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          background-color:color(red);

          &:before {
            border:1px solid color(red);
          }
          a {
            color:color(white);

            &:before {
              width:calc(100% - 10px);
              background-color:color(white);
            }
            &:after {
              color:color(white);
            }
          }
        }
      }
    }
  }

  .district-list-mobile {
    position:absolute; left:0; top:0;
    display:block;
    width:100%; height:100%;
    z-index:2;

    @include media-breakpoint-up(lg) {
      display:none;
    }

    li {
      position:absolute;
      width:auto;

      span {
        display:block; float:left;
        width:auto;
        font-size:10px; line-height:22px; color:color(dark); font-family:$font_frutiger_bold; font-weight:normal;
        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          font-size:calc(10px + 4 * ((100vw - 375px) / 617));
        }
        @include media-breakpoint-up(lg) {
          font-size:14px;
        }
      }
    }
  }

  .distric-map {
    position:relative;
    float:left;
    width:100%;
    z-index:1;

    .mobile {
      position:relative;
      display:block;
      z-index:1;
      background-color:color(med-grey,0.15);

      @include media-breakpoint-up(lg) {
        display:none;
      }
    }
    .base {
      position:relative;
      display:none;
      z-index:1;

      @include media-breakpoint-up(lg) {
        display:block;
      }
    }
    .load-map {
      position:absolute; left:0; top:0;
      display:none;
      width:100%;
      z-index:2;

      @include media-breakpoint-up(lg) {
        display:block;
      }
    }
  }
}

.district-select {
  margin-bottom:22px;

  @include media-breakpoint-up(lg) {
    display:none;
  }

  .select2-container {
    &.select2-container--open {
      .select2-dropdown--below {
        .select2-results__options {
          .select2-results__option {
            &:first-child {
              display:none;
            }
          }
        }
      }
    }
  }
}

.map-wrapper {
  float:left;
  width:100%;

  > a.open-map {
    position:relative;
    display:block; float:left;
    width:auto;
    margin-bottom:14px;
    border-bottom:1px solid color(dark-grey);
    font-size:14px; line-height:24px; color:color(dark-grey); font-family:$font_frutiger;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    @include media-breakpoint-up(xs-375) {
      margin-bottom:calc(14px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(lg) {
      &:hover {
        color:color(black);
        border-color:color(black);
      }
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:18px;
    }
  }

  .map {
    float:left;
    width:100%;
    border-radius:6px;
    overflow:hidden;
  }
}

.district-list-wrapper {
  position:relative;
  float:left;
  width:calc(100% + 40px);
  margin-left:-20px; margin-right:-20px; margin-top:30px;
  padding:48px 20px 20px 20px;
  text-align:center; font-size:0;
  background-color:color(grey);

  @include media-breakpoint-up(md) {
    margin-top:0;
    padding:48px 20px;
  }
  @include media-breakpoint-up(xl) {
    width:calc(100% + (40px + 76 * ((100vw - 1200px) / 240)));
    margin-left:calc(-20px - 38 * ((100vw - 1200px) / 240)); margin-right:calc(-20px - 38 * ((100vw - 1200px) / 240));
    padding:calc(48px + 10 * ((100vw - 1200px) / 240)) calc(20px + 38 * ((100vw - 1200px) / 240));
  }
  @include media-breakpoint-up(xl-1440) {
    width:calc(100% + 116px);
    margin-left:-58px; margin-right:-58px; margin-top:0;
    padding:58px;
  }
  .district-list-select {
    position:absolute; right:20px; top:-28px;
    width:calc(100% - 40px)!important;
    text-align:left;

    @include media-breakpoint-up(md) {
      width:calc(284px + 120 * ((100vw - 768px) / 672))!important;
    }
    @include media-breakpoint-up(xl) {
      right:calc(20px + 38 * ((100vw - 1200px) / 240));
    }
    @include media-breakpoint-up(xl-1440) {
      right:58px;
      width:404px!important;
    }

    > .select2 {
      width:100%!important;
    }
    .select2-container {
      text-align:left;

      &.select2-container--open {
        .select2-dropdown--below {
          .select2-results__options {
            .select2-results__option {
              &:first-child {
                display:none;
              }
            }
          }
        }
      }
    }
  }

  .district-list, .district-list-mobile {
    text-align:left;
  }
  .district-list {
    display:none;
    text-align:left;

    @include media-breakpoint-up(md) {
      display:block;
    }
  }
  .district-list-mobile {
    text-align:left;

    @include media-breakpoint-up(md) {
      display:none;
    }
  }

  .district-map-wrapper {
    display:inline-block; float:none;
    max-width:440px;

    @include media-breakpoint-up(md) {
      float:left;
      width:calc(530px + 300 * ((100vw - 768px) / 672)); max-width:none;
    }
    @include media-breakpoint-up(xl-1440) {
      width:830px;
    }

    .distric-map {
      .base {
        display:none;

        @include media-breakpoint-up(md) {
          display:block;
        }
      }
      .mobile {
        background-color:transparent;

        @include media-breakpoint-up(md) {
          display:none;
        }
      }
    }
  }
}
