.event-calendar-wrapper {
  float:left;
  width:100%;

  @include media-breakpoint-up(lg) {
    padding-left:calc(0px + 30 * ((100vw - 992px) / 448));
  }
  @include media-breakpoint-up(xl-1440) {
    padding-left:30px;
  }

  .mbsc-datepicker {
    padding:28px 28px 44px 28px;
    border-radius:6px;
    color:color(black);
    background-color:color(grey);

    .mbsc-calendar {
      padding-bottom:0;
    }

    .mbsc-calendar-controls {
      padding:0 0 0 8px;

      .mbsc-calendar-button {
        margin:0; padding:0; border:0 none;
        opacity:1;

        span {
          padding-left:0;
          font-size:18px; line-height:22px; font-family:$font_frutiger_bold; color:color(black); font-weight:normal;
        }

        &.mbsc-calendar-button-prev, &.mbsc-calendar-button-next {
          width:35px; height:35px;

          .mbsc-button-icon {
            svg {
              display:none;
            }
            &:before {
              position:absolute; left:50%; top:50%;
              width:15px; height:15px;
              font-size:15px; line-height:15px; color:color(black); font-family:'icomoon'!important; font-weight:bold; font-style:normal;
              @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            }
          }

          &.mbsc-disabled {
            opacity:0.3;
          }
        }
        &.mbsc-calendar-button-prev {
          .mbsc-button-icon {
            &:before {
              content:$icon-mendrisio-chevron-left;
            }
          }
        }
        &.mbsc-calendar-button-next {
          .mbsc-button-icon {
            &:before {
              content:$icon-mendrisio-chevron-right;
            }
          }
        }
      }
    }
    .mbsc-calendar-body {
      margin-top:36px;
    }
    .mbsc-calendar-picker {
      .mbsc-calendar-slide {
        color:color(black);
        background-color:transparent;
        padding:0 8px;

        .mbsc-calendar-table {
          position:relative;
          border-bottom:1px solid color(dark-grey,0.4);

          .mbsc-calendar-week-days {
            position:relative;
            padding-bottom:6px;
            background-color:color(grey);
            z-index:2;
          }
          .mbsc-calendar-week-day {
            font-size:12px; line-height:30px; color:color(black); font-family:$font_frutiger_bold; font-weight:normal;
          }
          .mbsc-calendar-row {
            border-top:1px solid color(dark-grey,0.4); border-right:1px solid color(dark-grey,0.4);
            box-sizing:border-box;

            .mbsc-calendar-cell {
              border-left:1px solid color(dark-grey,0.4);
              box-sizing:border-box;

              &:after {
                content:none!important;
              }
              .mbsc-calendar-cell-text, .mbsc-calendar-month-name {
                opacity:1;
                cursor:auto;
              }

              &.mbsc-disabled {
                .mbsc-calendar-cell-inner {
                  .mbsc-calendar-day-text {
                    font-family:$font_frutiger; color:color(dark)!important;
                  }
                  &:before {
                    content:none;
                  }
                }

                &.mbsc-calendar-day-outer {
                  .mbsc-calendar-cell-text, .mbsc-calendar-month-name {
                    opacity:0.5;
                  }
                }
              }

              .mbsc-calendar-cell-inner {
                position:relative;
                border-width:2px;
                @include prefix(transition, all .2s ease-out, webkit moz o ms);

                &:before {
                  content:'';
                  position:absolute; left:-3px; top:-3px;
                  width:calc(100% + 6px); height:calc(100% + 6px);
                  border:2px solid color(red);
                }

                .mbsc-calendar-day-text {
                  width:100%;
                  margin:0;
                  border-radius:0;
                  font-size:12px; line-height:30px; font-family:$font_frutiger_bold; color:color(red)!important; font-weight:normal;
                }
              }
              &.mbsc-hover {
                .mbsc-calendar-cell-inner {
                  background-color:color(dark-grey,0.1)!important;
                }
              }
              &.mbsc-selected {
                .mbsc-calendar-cell-inner {
                  background-color:color(red)!important;

                  .mbsc-calendar-day-text {
                    font-family:$font_frutiger_bold; color:color(white)!important; font-weight:normal;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.agenda-calendar-wrapper {
  float:left;
  width:100%;

  .mbsc-datepicker {
    padding:0 0 10px 0!important;
    border-radius:0;
    color:color(black);
    background-color:transparent;

    @include media-breakpoint-up(xs-375) {
      padding:0 0 calc(10px + 10 * ((100vw - 375px) / 1065)) 0!important;
    }
    @include media-breakpoint-up(xl-1440) {
      padding:0 0 20px 0!important;
    }

    .mbsc-calendar {
      padding-bottom:0;
    }

    .mbsc-calendar-controls {
      padding:0 12px;

      .mbsc-calendar-button {
        margin:0; padding:0; border:0 none;
        opacity:1;

        span {
          padding-left:0;
          font-size:18px; line-height:22px; font-family:$font_frutiger_bold; color:color(black); font-weight:normal;
        }

        &.mbsc-calendar-button-prev, &.mbsc-calendar-button-next {
          width:35px; height:35px;

          .mbsc-button-icon {
            svg {
              display:none;
            }
            &:before {
              position:absolute; left:50%; top:50%;
              width:13px; height:13px;
              font-size:13px; line-height:13px; color:color(alt-grey); font-family:'icomoon'!important; font-weight:bold; font-style:normal;
              @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            }
          }

          &.mbsc-disabled {
            opacity:0.3;
          }
        }
        &.mbsc-calendar-button-prev {
          .mbsc-button-icon {
            &:before {
              content:$icon-mendrisio-chevron-left;
            }
          }
        }
        &.mbsc-calendar-button-next {
          .mbsc-button-icon {
            &:before {
              content:$icon-mendrisio-chevron-right;
            }
          }
        }
      }
    }
    .mbsc-calendar-body {
      margin-top:32px!important;
    }
    .mbsc-calendar-picker {
      .mbsc-calendar-slide {
        color:color(black);
        background-color:transparent;

        .mbsc-calendar-table {
          position:relative;

          .mbsc-calendar-week-days {
            position:relative;
            z-index:2;
          }
          .mbsc-calendar-week-day {
            font-size:14px; line-height:24px; color:color(black); font-family:$font_frutiger; font-weight:normal;
          }
          .mbsc-calendar-row {
            box-sizing:border-box;

            .mbsc-calendar-cell {
              border:0 none!important;
              box-sizing:border-box;

              &:after {
                content:none!important;
              }
              .mbsc-calendar-cell-text, .mbsc-calendar-month-name {
                opacity:1;
                cursor:auto;
              }

              &.mbsc-disabled {
                .mbsc-calendar-cell-inner {
                  .mbsc-calendar-day-text {
                    color:color(btn-grey)!important;
                  }
                  &:before {
                    content:none;
                  }
                }
              }

              .mbsc-calendar-cell-inner {
                position:relative;
                padding:8px;
                border-width:0;
                @include prefix(transition, all .2s ease-out, webkit moz o ms);

                @include media-breakpoint-up(xs-375) {
                  padding:calc(8px + 4 * ((100vw - 375px) / 1065));
                }
                @include media-breakpoint-up(xl-1440) {
                  padding:12px;
                }

                .mbsc-calendar-day-text {
                  position:relative;
                  width:100%;
                  margin:0;
                  border-radius:0;
                  font-size:16px; line-height:30px; font-family:$font_frutiger; color:color(black)!important; font-weight:normal;
                  cursor:pointer;
                  z-index:3;

                  &.mbsc-calendar-today {
                    color:color(red)!important; font-family:$font_frutiger_bold;
                  }
                } 
                &:before {
                  content:'';
                  position:absolute; left:50%; top:50%;
                  width:46px; height:46px;
                  border:0 none; border-radius:50%;
                  opacity:0;
                  z-index:2;
                  @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                  @include prefix(transition, all .3s ease-out, webkit moz o ms);

                  @include media-breakpoint-up(xs-375) {
                    width:calc(46px + 8 * ((100vw - 375px) / 1065)); height:calc(46px + 8 * ((100vw - 375px) / 1065));
                  }
                  @include media-breakpoint-up(xl-1440) {
                    width:54px; height:54px;
                  }
                }
              }
              &.mbsc-hover {
                .mbsc-calendar-cell-inner {
                  &:before {
                    background-color:color(med-grey,.6);
                    opacity:1;
                  }
                }
              }
              &.mbsc-range-day-start, &.mbsc-range-day-end {
                .mbsc-calendar-cell-inner {
                  background-color:transparent!important;

                  .mbsc-calendar-day-text {
                    color:color(white)!important; font-family:$font_frutiger_bold;
                  } 
                  &:before {
                    background-color:color(red);
                    opacity:1;
                  }
                  &:after {
                    content:'';
                    position:absolute; top:0;
                    width:50%; height:100%;
                    background-color:color(med-grey,.6);
                    z-index:1;
                  }
                }
              }
              &.mbsc-range-day-start {
                .mbsc-calendar-cell-inner {
                  &:after {
                    right:0; left:inherit;
                  }
                }
              }
              &.mbsc-range-day-end {
                .mbsc-calendar-cell-inner {
                  &:after {
                    left:0; right:inherit;
                  }
                }
              }
              &.mbsc-range-day-start.mbsc-range-day-end {
                .mbsc-calendar-cell-inner {
                  &:after {
                    content:none;
                  }
                }
              }
              &.mbsc-range-day {
                .mbsc-calendar-cell-inner {
                  background-color:color(med-grey,.6);
                }
              }
            }
          }
        }
      }
    }
  }
}

aside {
  .event-calendar-wrapper {
    padding-left:0;

    .mbsc-datepicker {
      padding:24px 20px 44px 20px;

      .mbsc-calendar-controls {
        .mbsc-calendar-button {
          span {
            font-size:16px; line-height:20px;
          }

          &.mbsc-calendar-button-prev, &.mbsc-calendar-button-next {
            width:30px; height:30px;

            .mbsc-button-icon {
              &:before {
                width:12px; height:12px;
                font-size:12px; line-height:12px;
              }
            }
          }
        }
      }
      .mbsc-calendar-body {
        margin-top:26px;
      }
      .mbsc-calendar-picker {
        .mbsc-calendar-slide {
          .mbsc-calendar-table {
            .mbsc-calendar-week-day {
              font-size:10px;
            }
            .mbsc-calendar-row {
              .mbsc-calendar-cell {
                .mbsc-calendar-cell-inner {
                  .mbsc-calendar-day-text {
                    font-size:10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
