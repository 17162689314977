.pagination-wrapper {
  width:calc(100% + 12px);
  margin-top:40px;
  padding-top:12px; padding-bottom:12px;
  margin-left:-6px; margin-right:-6px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(40px + 13 * ((100vw - 375px) / 1065));
    padding-top:calc(12px + 3 * ((100vw - 375px) / 1065)); padding-bottom:calc(12px + 3 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:53px;
    padding-top:15px; padding-bottom:15px;
  }

  .row {
    > div {
      ul {
        display:flex; align-items:center; justify-content:center;

        li {
          position:relative;
          width:auto;
          padding:0 8px;

          @include media-breakpoint-up(xs-375) {
            padding:0 calc(8px + 6 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding:0 14px;
          }

          a {
            display:block; float:left;
            width:auto;
            padding:4px;
            font-size:16px; line-height:20px; color:color(black); font-family:$font_frutiger; letter-spacing:0.02em;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(xs-375) {
              font-size:calc(16px + 2 * ((100vw - 375px) / 1065)); line-height:calc(20px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(red);
              }
            }
            @include media-breakpoint-up(xl-1440) {
              font-size:18px; line-height:24px;
            }
          }
          &:before {
            content:'';
            position:absolute; left:50%; bottom:-16px;
            width:0%; height:3px;
            background-color:color(red);
            @include prefix(transform, translateX(-50%), webkit moz o ms);

            @include media-breakpoint-up(xs-375) {
              bottom:calc(-16px - 2 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              bottom:-18px;
            }
          }

          &.prev, &.next {
            a {
              position:relative;
              width:38px; height:38px;
              border-radius:50%;
              background-color:color(grey);
              @include prefix(transition, all .3s ease-out, webkit moz o ms);

              i {
                position:absolute; left:50%; top:50%;
                width:14px; height:14px;
                margin-top:-1px;
                font-size:14px; line-height:14px; color:color(dark-grey);
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
              }
              @include media-breakpoint-up(lg) {
                &:hover {
                  background-color:color(grey,0.8);

                  i {
                    color:color(black);
                  }
                }
              }
            }
          }
          &.prev {
            margin-left:0; margin-right:10px;
            padding-left:0;

            @include media-breakpoint-up(xs-375) {
              margin-right:calc(10px + 50 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-right:60px;
            }
          }
          &.next {
            margin-left:10px; margin-right:0;
            padding-right:0;

            @include media-breakpoint-up(xs-375) {
              margin-left:calc(10px + 50 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-left:60px;
            }
          }

          &.active {
            a {
              color:color(red); font-family:$font_frutiger_bold;
            }
            &:before {
              width:calc(100% - 14px);
            }
          }
        }
      }
    }
  }

  &.bkg-grey {
    margin-top:0;

    .row {
      > div {
        ul {
          li {
            &.prev, &.next {
              a {
                background-color:color(white);

                @include media-breakpoint-up(lg) {
                  &:hover {
                    background-color:color(white,0.8);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.left {
    .row {
      > div {
        ul {
          @include media-breakpoint-up(lg) {
            justify-content:flex-start;
          }
        }
      }
    }
  }
}
