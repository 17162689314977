// Color variables

$color-list: (
  white:#FFFFFF,
  black:#000000,
  dark:#101415,
  red:#DE1F22,
  grey:#F2F4F3,
  alt-grey:#333333,
  med-grey:#D9D9D9,
  light-grey:#F9F9F9,
  dark-grey:#717171,
  btn-grey:#9E9E9E,
  scroll-grey:#BCBEC0,
  scl-instagram:#833AB4,
  scl-linkedin:#0A66C2,
  scl-youtube:#FF0000,
  blue:#1291D1
);

// Color class loop

@each $color, $value in $color-list {
  .txt-#{$color} { color:$value!important; }
  .bkg-#{$color} { background-color:$value!important; }

  .hero-box.bkg-#{$color} {
    > div {
      background-color:$value!important;
    }
  }
}

// Color var function

@function color($color, $opacity: 1) {
  $base-color: map-get($color-list, $color);
  @return rgba(red($base-color), green($base-color), blue($base-color), $opacity);
}
