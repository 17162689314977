$icomoon-font-family:"icomoon" !default;
$icomoon-font-path:"../fonts" !default;

@font-face {
  font-family:'#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/icomoon.ttf?d27s9o') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?d27s9o') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?d27s9o##{$icomoon-font-family}') format('svg');
  font-weight:normal;
  font-style:normal;
  font-display:block;
  font-display:swap;
}

[class^="icon-mendrisio-"], [class*=" icon-mendrisio-"] {
  font-family:'icomoon' !important;
  speak:never;
  font-style:normal;
  font-weight:normal;
  font-variant:normal;
  text-transform:none;
  line-height:1;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}

$icon-mendrisio-location:"\e91c";
$icon-mendrisio-calendar:"\e91d";
$icon-mendrisio-upload:"\e91a";
$icon-mendrisio-lang:"\e919";
$icon-mendrisio-recurring:"\e917";
$icon-mendrisio-time:"\e918";
$icon-mendrisio-check:"\e916";
$icon-mendrisio-arrow-external:"\e910";
$icon-mendrisio-minus:"\e911";
$icon-mendrisio-plus:"\e912";
$icon-mendrisio-link:"\e913";
$icon-mendrisio-share:"\e914";
$icon-mendrisio-download:"\e90d";
$icon-mendrisio-menu:"\e90e";
$icon-mendrisio-alert-round:"\e91b";
$icon-mendrisio-alert:"\e900";
$icon-mendrisio-arrow-diagonal:"\e901";
$icon-mendrisio-arrow-left:"\e915";
$icon-mendrisio-arrow-right:"\e902";
$icon-mendrisio-chevron-left:"\e903";
$icon-mendrisio-chevron-right:"\e904";
$icon-mendrisio-close:"\e905";
$icon-mendrisio-search:"\e906";
$icon-mendrisio-email:"\e907";
$icon-mendrisio-grid:"\e908";
$icon-mendrisio-send:"\e909";
$icon-mendrisio-social-instagram:"\e90a";
$icon-mendrisio-social-linkedin:"\e90f";
$icon-mendrisio-social-youtube:"\e90b";
$icon-mendrisio-social-youtube-alt:"\e90c";

.icon-mendrisio-location {
  &:before {
    content: $icon-mendrisio-location; 
  }
}
.icon-mendrisio-calendar {
  &:before {
    content: $icon-mendrisio-calendar; 
  }
}
.icon-mendrisio-upload {
  &:before {
    content:$icon-mendrisio-upload;
  }
}
.icon-mendrisio-lang {
  &:before {
    content:$icon-mendrisio-lang;
  }
}
.icon-mendrisio-recurring {
  &:before {
    content:$icon-mendrisio-recurring;
  }
}
.icon-mendrisio-time {
  &:before {
    content:$icon-mendrisio-time;
  }
}
.icon-mendrisio-check {
  &:before {
    content:$icon-mendrisio-check;
  }
}
.icon-mendrisio-arrow-external {
  &:before {
    content:$icon-mendrisio-arrow-external;
  }
}
.icon-mendrisio-minus {
  &:before {
    content:$icon-mendrisio-minus;
  }
}
.icon-mendrisio-plus {
  &:before {
    content:$icon-mendrisio-plus;
  }
}
.icon-mendrisio-link {
  &:before {
    content:$icon-mendrisio-link;
  }
}
.icon-mendrisio-share {
  &:before {
    content:$icon-mendrisio-share;
  }
}
.icon-mendrisio-download {
  &:before {
    content:$icon-mendrisio-download;
  }
}
.icon-mendrisio-menu {
  &:before {
    content:$icon-mendrisio-menu;
  }
}
.icon-mendrisio-alert-round {
  &:before {
    content: $icon-mendrisio-alert-round;
  }
}
.icon-mendrisio-alert {
  &:before {
    content:$icon-mendrisio-alert;
  }
}
.icon-mendrisio-arrow-diagonal {
  &:before {
    content:$icon-mendrisio-arrow-diagonal;
  }
}
.icon-mendrisio-arrow-left {
  &:before {
    content:$icon-mendrisio-arrow-left;
  }
}
.icon-mendrisio-arrow-right {
  &:before {
    content:$icon-mendrisio-arrow-right;
  }
}
.icon-mendrisio-chevron-left {
  &:before {
    content:$icon-mendrisio-chevron-left;
  }
}
.icon-mendrisio-chevron-right {
  &:before {
    content:$icon-mendrisio-chevron-right;
  }
}
.icon-mendrisio-close {
  &:before {
    content:$icon-mendrisio-close;
  }
}
.icon-mendrisio-search {
  &:before {
    content:$icon-mendrisio-search;
  }
}
.icon-mendrisio-email {
  &:before {
    content:$icon-mendrisio-email;
  }
}
.icon-mendrisio-grid {
  &:before {
    content:$icon-mendrisio-grid;
  }
}
.icon-mendrisio-send {
  &:before {
    content:$icon-mendrisio-send;
  }
}
.icon-mendrisio-social-instagram {
  &:before {
    content:$icon-mendrisio-social-instagram;
  }
}
.icon-mendrisio-social-linkedin {
  &:before {
    content:$icon-mendrisio-social-linkedin;
  }
}
.icon-mendrisio-social-youtube {
  &:before {
    content:$icon-mendrisio-social-youtube;
  }
}
.icon-mendrisio-social-youtube-alt {
  &:before {
    content:$icon-mendrisio-social-youtube-alt;
  }
}
