aside {
  display:block; float:left;
  width:100%;
  margin-top:40px;
  filter:drop-shadow(0px 0px 40px rgba(0,0,0,0.06));

  @include media-breakpoint-up(md) {
    margin-top:0;
  }
  @include media-breakpoint-up(lg) {
    padding-left:calc(0px + 15 * ((100vw - 992px) / 448));
  }
  @include media-breakpoint-up(xl-1440) {
    padding-left:15px;
  }

  a{
    &.mail{
      @include media-breakpoint-up(md) {
        font-size:10px;
      }
      @include media-breakpoint-up(xl-1440) {
        font-size:14px;
      }
    }
  }

  &.left {
    @include media-breakpoint-up(lg) {
      padding-left:0; padding-right:calc(0px + 50 * ((100vw - 992px) / 448));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-right:50px;
    }
  }

  &.sticky {
    position:sticky; top:100px;

    @include media-breakpoint-up(lg) {
      top:calc(140px + 30 * ((100vw - 992px) / 448));
    }
    @include media-breakpoint-up(xl-1440) {
      top:170px;
    }
  }
  &.light {
    padding:0;
    filter:none;
  }
  &.mt-sm-reset {
    @include media-breakpoint-down(md) {
      margin-top:0;
    }
  }

  .side-block {
    float:left;
    width:100%;
    margin-top:11px; padding:22px 20px 24px 20px;
    border-radius:6px;
    background-color:color(white);

    @include media-breakpoint-up(xs-375) {
      padding:calc(22px + 4 * ((100vw - 375px) / 1065)) calc(20px + 4 * ((100vw - 375px) / 1065)) calc(24px + 4 * ((100vw - 375px) / 1065)) calc(20px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding:26px 24px 28px 24px;
    }

    &:first-child {
      margin-top:0;
    }

    h5, .h5 {
      margin-bottom:2px;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(2px + 5 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:7px;
      }
    }
    h6, .h6 {
      margin-bottom:5px; margin-top:22px;
      font-size:14px; line-height:22px; font-family:$font_frutiger; color:color(red); text-transform:inherit;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(5px + 3 * ((100vw - 375px) / 1065)); margin-top:calc(22px + 8 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:8px; margin-top:30px;
      }
    }
    p {
      margin-top:14px;
      font-size:14px; line-height:24px; color:color(dark-grey); font-family:$font_frutiger; word-break:break-word;

      strong {
        color:color(dark);
      }
    }
    .btn-link {
      margin-top:20px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(20px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:22px;
      }
    }
    .link-list {
      li {
        margin-top:11px;

        a {
          position:relative;
          display:block; float:left;
          width:auto;
          padding:4px 0 4px 36px;
          font-size:14px; line-height:20px; color:color(black); font-family:$font_frutiger;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            padding:4px 0 4px calc(36px + 6 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding:4px 0 4px 42px;
          }
          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(red);
            }
          }

          i {
            position:absolute; left:0; top:0;
            width:28px; height:28px;
            border-radius:50%;
            font-size:14px; line-height:28px; text-align:center; color:color(black); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
            background-color:color(grey);
          }
        }
      }
    }
    .section-list {
      width:calc(100% + 40px);
      margin-left:-20px; margin-right:-20px;
      margin-bottom:-10px;

      @include media-breakpoint-up(xs-375) {
        width:calc(100% + calc(40px + 8 * ((100vw - 375px) / 1065)));
        margin-left:calc(-20px - 4 * ((100vw - 375px) / 1065)); margin-right:calc(-20px - 4 * ((100vw - 375px) / 1065));
        margin-bottom:calc(-10px - 3 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        width:calc(100% + 48px);
        margin-left:-24px; margin-right:-24px;
        margin-bottom:-13px;
      }

      li {
        position:relative;

        &:before {
          content:'';
          position:absolute; bottom:0; left:0;
          width:calc(100% - 20px); height:1px;
          background-color:color(med-grey);
          z-index:1;
        }
        &:last-child {
          &:before {
            content:none;
          }
        }
        a {
          position:relative;
          display:block; float:left;
          width:100%;
          padding:10px 50px 10px 20px;
          font-size:14px; line-height:24px; color:color(dark-grey); font-family:$font_frutiger;
          z-index:2;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            padding:calc(10px + 3 * ((100vw - 375px) / 1065)) 50px calc(10px + 3 * ((100vw - 375px) / 1065)) calc(20px + 5 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding:13px 50px 13px 25px;
          }

          &:before {
            content:'';
            position:absolute; left:0; top:50%;
            width:3px; height:0;
            background-color:color(red);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
            @include prefix(transform, translateY(-50%), webkit moz o ms);
          }
          &:after {
            content:$icon-mendrisio-chevron-right;
            position:absolute; right:20px; top:16px;
            width:13px; height:13px;
            font-size:13px; line-height:13px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal;

            @include media-breakpoint-up(xs-375) {
              top:calc(16px + 2 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              top:18px;
            }
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(black);

              &:before {
                height:100%;
              }
            }
          }
        }

        &.active {
          a {
            color:color(black); font-family:$font_frutiger_bold; font-weight:normal;

            &:before {
              height:100%;
            }
            &:after {
              content:none;
            }
          }
        }
      }
    }
    .anchor-list {
      width:calc(100% + 40px);
      margin-left:-20px; margin-right:-20px;
      margin-bottom:-10px;

      @include media-breakpoint-up(xs-375) {
        width:calc(100% + calc(40px + 8 * ((100vw - 375px) / 1065)));
        margin-left:calc(-20px - 4 * ((100vw - 375px) / 1065)); margin-right:calc(-20px - 4 * ((100vw - 375px) / 1065));
        margin-bottom:calc(-10px - 3 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        width:calc(100% + 48px);
        margin-left:-24px; margin-right:-24px;
        margin-bottom:-13px;
      }

      li {
        position:relative;

        &:before {
          content:'';
          position:absolute; bottom:0; left:0;
          width:100%; height:1px;
          background-color:color(med-grey);
          z-index:1;
        }
        &:last-child {
          &:before {
            content:none;
          }
        }
        a {
          position:relative;
          display:block; float:left;
          width:100%;
          padding:10px 40px 10px 20px;
          font-size:14px; line-height:24px; color:color(dark-grey); font-family:$font_frutiger;
          z-index:2;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            padding:calc(10px + 3 * ((100vw - 375px) / 1065)) 40px calc(10px + 3 * ((100vw - 375px) / 1065)) calc(20px + 5 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding:13px 40px 13px 25px;
          }

          &:before {
            content:'';
            position:absolute; left:0; top:50%;
            width:3px; height:0;
            background-color:color(red);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
            @include prefix(transform, translateY(-50%), webkit moz o ms);
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(black);

              &:before {
                height:100%;
              }
            }
          }
        }
      }
    }
  }

  .event-calendar-wrapper {
    margin-bottom:24px;

    @include media-breakpoint-up(md) {
      margin-bottom:0;
    }
  }
}

.scroll-anchor {
  position:relative;
  float:left;
  width:100%;
  margin-top:-100px;

  @include media-breakpoint-up(lg) {
    margin-top:calc(-140px - 30 * ((100vw - 992px) / 448));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-170px;
  }
}

.anchor-select {
  display:flex; flex-direction:row; align-items:center; justify-content:space-between;
  margin-bottom:34px;

  h5, .h5 {
    width:auto;
    padding-right:24px;
    font-size:16px;
  }
}
