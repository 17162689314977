.accordion-wrapper {
  float:left;
  width:100%;
  margin-top:15px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(15px + 10 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:25px;
  }

  > div {
    position:relative;
    float:left;
    width:100%;
    padding:8px 0;

    @include media-breakpoint-up(xs-375) {
      padding:calc(8px + 4 * ((100vw - 375px) / 1065)) 0;
    }
    @include media-breakpoint-up(xl-1440) {
      padding:12px 0;
    }

    &:after {
      content:'';
      position:absolute; left:0; bottom:0;
      width:100%; height:1px;
      background-color:color(med-grey);
    }

    .accordion-head {
      position:relative;
      float:left;
      width:100%;
      padding:10px 48px 10px 0;
      cursor:pointer;

      @include media-breakpoint-up(xs-375) {
        padding:10px calc(48px + 22 * ((100vw - 375px) / 1065)) 10px 0;
      }
      @include media-breakpoint-up(xl-1440) {
        padding:10px 70px 10px 0;
      }

      h3, .h3 {
        display:flex; justify-content:space-between;
        font-size:20px; color:color(black);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          font-size:calc(20px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xs-480) {
          justify-content:flex-start;
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:22px;
        }

        span {
          position:relative;
          display:inline-block; float:none;
          width:24px; height:24px;
          margin:1px 14px;
          border-radius:50%;
          font-size:12px; line-height:24px; color:color(red); font-family:$font_frutiger_bold; text-align:center;

          @include media-breakpoint-up(xs-375) {
            margin:calc(1px + 1 * ((100vw - 375px) / 1065)) calc(14px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin:2px 18px;
          }

          &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:100%; height:100%;
            border-radius:50%; border:1.65px solid color(red);
          }
        }
      }
      &:after {
        content:$icon-mendrisio-plus;
        position:absolute; right:10px; top:9px;
        width:28px; height:28px;
        border-radius:50%;
        font-size:10px; line-height:28px; text-align:center; color:color(black); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        background-color:color(grey);

        @include media-breakpoint-up(xs-375) {
          right:calc(10px + 12 * ((100vw - 375px) / 1065)); top:calc(9px + 1 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          right:22px; top:10px;
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          h3, .h3 {
            color:color(red);
          }
        }
      }
    }
    .accordion-content {
      display:none; float:left;
      width:100%;
      padding:10px 0 20px 0;

      @include media-breakpoint-up(xs-375) {
        padding:calc(10px + 10 * ((100vw - 375px) / 1065)) 0 calc(20px + 20 * ((100vw - 375px) / 1065)) 0;
      }
      @include media-breakpoint-up(xl-1440) {
        padding:20px 0 40px 0;
      }

      .text-article {
        > * {
          &:first-child {
            margin-top:0!important;
          }
        }
      }

      .accordion-wrapper {
        margin-top:0;
      }
    }

    &.open {
      .accordion-head {
        h3, .h3 {
          color:color(red);
        }
        &:after {
          content:$icon-mendrisio-minus;
        }
      }
    }

    &.no-content {
      .accordion-head {
        cursor:default!important;
        pointer-events:none!important;

        &:after {
          content:none!important;
        }
        span {
          display:none!important;
        }
      }
      .accordion-subhead {
        padding-bottom:0!important;
      }
      .accordion-content {
        display:none!important;
      }
    }
  }

  &.box {
    filter:drop-shadow(0px 0px 40px rgba(0,0,0,0.06));

    > div {
      padding:14px 0; border-radius:0 6px 6px 0; margin-top:10px;
      background-color:color(white);

      @include media-breakpoint-up(xs-375) {
        padding:calc(14px + 10 * ((100vw - 375px) / 1065)) 0; margin-top:calc(10px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding:24px 0; margin-top:12px;
      }

      &:first-child {
        margin-top:0;
      }

      &:after {
        left:0; top:0;
        width:3px; height:58px;
        background-color:color(red);

        @include media-breakpoint-up(xs-375) {
          height:calc(58px + 8 * ((100vw - 375px) / 1065)) 0;
        }
        @include media-breakpoint-up(xl-1440) {
          height:66px;
        }
      }

      .accordion-head {
        padding:10px 20px 10px 58px;

        @include media-breakpoint-up(xs-375) {
          padding:10px calc(20px + 22 * ((100vw - 375px) / 1065)) 10px calc(58px + 20 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:10px 42px 10px 78px;
        }

        h3, .h3 {
          font-size:18px; color:color(dark); font-family:$font_frutiger_bold;

          @include media-breakpoint-up(xs-375) {
            font-size:calc(18px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            font-size:20px;
          }
        }
        &:after {
          content:$icon-mendrisio-plus;
          position:absolute; left:16px; top:9px;
          width:28px; height:28px;
          border-radius:50%;
          font-size:10px; line-height:28px; text-align:center; color:color(black); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          background-color:color(grey);

          @include media-breakpoint-up(xs-375) {
            left:calc(16px + 20 * ((100vw - 375px) / 1065)); top:calc(9px + 1 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            left:36px; top:10px;
          }
        }
      }
      .accordion-subhead {
        position:relative;
        float:left;
        width:100%;
        padding:0 20px 0 58px;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          padding:0 calc(20px + 22 * ((100vw - 375px) / 1065)) 0 calc(58px + 20 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:0 42px 0 78px;
        }

        p {
          margin-top:7px;
          font-size:15px; line-height:22px; color:color(black);

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(7px + 3 * ((100vw - 375px) / 1065));
            font-size:calc(15px + 1 * ((100vw - 375px) / 1065)); line-height:calc(22px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:10px;
            font-size:16px; line-height:24px;
          }

          a {
            font-size:13px; font-family:$font_frutiger;

            @include media-breakpoint-up(xs-375) {
              font-size:calc(13px + 1 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              font-size:14px;
            }
          }

          &:first-child {
            margin-top:0;
          }
        }
      }
      .accordion-content {
        display:none; float:left;
        width:100%;
        padding:0 20px 3px 58px;

        @include media-breakpoint-up(xs-375) {
          padding:calc(0px + 2 * ((100vw - 375px) / 1065)) calc(20px + 22 * ((100vw - 375px) / 1065)) calc(3px + 3 * ((100vw - 375px) / 1065)) calc(58px + 20 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:2px 42px 6px 78px;
        }

        .text-article {
          > * {
            &:first-child {
              margin-top:0!important;
            }
          }
        }
      }

      &.open {
        .accordion-head {
          h3, .h3 {
            color:color(red);
          }
          &:after {
            content:$icon-mendrisio-minus;
          }
        }
        .accordion-subhead {
          padding-bottom:20px;

          @include media-breakpoint-up(xs-375) {
            padding-bottom:calc(20px + 8 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding-bottom:28px;
          }
        }
      }
    }
  }

  &.teaser {
    margin-top:0;

    > div {
      padding:10px 20px 20px 20px; border-radius:6px;
      border:1px solid color(med-grey);
      background-color:transparent;

      @include media-breakpoint-up(xs-375) {
        padding:calc(10px + 12 * ((100vw - 375px) / 1065)) calc(20px + 16 * ((100vw - 375px) / 1065)) calc(20px + 12 * ((100vw - 375px) / 1065)) calc(20px + 16 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding:22px 36px 32px 36px;
      }

      &:after {
        content:none;
      }

      .accordion-head {
        padding:10px 48px 10px 0;

        h3, .h3 {
          font-size:18px; color:color(dark); font-family:$font_frutiger_bold;

          @include media-breakpoint-up(xs-375) {
            font-size:calc(18px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            font-size:20px;
          }
        }
        &:after {
          right:0;
        }
      }
      .accordion-subhead {
        position:relative;
        float:left;
        width:100%;
        padding:8px 0 0 0;

        table {
          tr {
            margin-top:4px;

            td {
              font-size:14px; line-height:20px; color:color(dark-grey); vertical-align:text-top;

              strong {
                padding-right:26px;
                color:color(red); font-size:11px; text-transform:uppercase; font-family:$font_frutiger;
              }
            }
          }
        }
      }
      .accordion-content {
        display:none; float:left;
        width:100%;
        padding:20px 0 0 0;

        @include media-breakpoint-up(xs-375) {
          padding:calc(20px + 4 * ((100vw - 375px) / 1065)) 0 0 0;
        }
        @include media-breakpoint-up(xl-1440) {
          padding:24px 0 0 0;
        }

        .office-content {
          position:relative;
          display:block; float:left;
          width:100%;

          div {
            position:relative;
            float:left;
            width:100%;
            padding-left:20px;

            @include media-breakpoint-up(xs-375) {
              padding-left:calc(20px + 10 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              padding-left:30px;
            }

            p {
              margin-top:14px;
              font-size:14px; line-height:24px; color:color(dark-grey); font-family:$font_frutiger;

              strong {
                color:color(dark);
              }

              &:first-child {
                margin-top:0;
              }
            }

            &:before {
               content:'';
               position:absolute; left:0; top:0;
               width:2px; height:100%;
               background-color:color(red);
            }
          }
          .btn-link {
            float:right;
            padding-right:26px; margin-top:12px;
            font-size:14px; line-height:20px;

            @include media-breakpoint-up(xs-375) {
              padding-right:calc(26px + 4 * ((100vw - 375px) / 1065)); margin-top:calc(12px + 4 * ((100vw - 375px) / 1065));
              font-size:calc(14px + 2 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              padding-right:30px; margin-top:16px;
              font-size:16px;
            }

            &:after {
              width:20px; height:20px;
              font-size:16px; line-height:20px; text-align:center;

              @include media-breakpoint-up(xs-375) {
                font-size:calc(16px + 3 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                font-size:19px;
              }
            }
          }
        }
      }
    }
  }
}

.teaser-accordion-list {
  margin-top:24px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(24px + 30 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:54px;
  }

  &.mt-0 {
    margin-top:-16px!important;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(-16px - 8 * ((100vw - 375px) / 1065))!important;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:-24px!important;
    }
  }

  > div {
    margin-top:16px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:24px;
    }
  }
}
