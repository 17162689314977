.btn {
  display:inline-block; float:none;
  width:auto;
  padding:13px 33px;
  border-radius:4px!important; border:0 none;
  font-size:14px; line-height:20px; color:color(white)!important; font-family:$font_frutiger_bold; letter-spacing:0.34px;
  background-color:color(red);
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  @include media-breakpoint-up(lg) {
    padding:calc(13px + 5 * ((100vw - 992px) / 448)) calc(33px + 20 * ((100vw - 992px) / 448));
    font-size:calc(14px + 3 * ((100vw - 992px) / 448));
  }
  @include media-breakpoint-up(xl-1440) {
    padding:18px 53px;
    font-size:17px;
  }
  @include media-breakpoint-up(lg) {
    &:hover {
      background-color:color(dark);
    }
  }

  i {
    display:inline-block; float:none;
    font-size:13px; line-height:20px;
    margin-left:16px;

    @include media-breakpoint-up(xs-375) {
      margin-left:calc(16px + 14 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-left:30px;
    }
  }

  &.small {
    padding:18px 26px;
    font-size:16px;

    @include media-breakpoint-up(xs-360) {
      padding:18px 40px;
    }
  }

  &.grey {
    background-color:color(btn-grey);

    @include media-breakpoint-up(lg) {
      &:hover {
        background-color:color(dark);
      }
    }
  }
  &.white {
    color:color(red)!important;
    background-color:color(white);

    @include media-breakpoint-up(lg) {
      &:hover {
        color:color(white)!important;
        background-color:color(red);
      }
    }
  }
}

.btn-link {
  position:relative;
  display:inline-block; float:none;
  width:auto;
  padding-right:25px;
  font-size:14px; line-height:16px; letter-spacing:0.02em; color:color(red); font-family:$font_frutiger_bold; font-weight:normal;
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  &:after {
    content:$icon-mendrisio-arrow-right;
    position:absolute; right:0; top:0;
    width:16px; height:16px;
    font-size:16px; line-height:16px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      color:color(dark);

      &:after {
        color:color(dark);
      }
    }
  }
}

.btn-more {
  position:relative;
  display:inline-block; float:none;
  padding-right:20px;
  font-size:17px; line-height:20px; color:color(red); font-family:$font_frutiger_bold; letter-spacing:0.02em;
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  &:after {
    content:$icon-mendrisio-plus;
    position:absolute; right:0; top:5px;
    width:10px; height:10px;
    font-size:10px; line-height:10px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
  }
  @include media-breakpoint-up(lg) {
    &:hover {
      color:color(dark);

      &:after {
        color:color(dark);
      }
    }
  }
}
