.footer {
  position:relative;
  float:left;
  width:100%;
  margin-top:50px;
  padding-left:6px; padding-right:6px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(50px + 2 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:52px;
  }

  > .container {
    position:relative;
    padding-top:38px; padding-bottom:40px;

    @include media-breakpoint-up(xs-375) {
      padding-top:calc(38px + 14 * ((100vw - 375px) / 1065)); padding-bottom:calc(40px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-top:52px; padding-bottom:44px;
    }

    &:before {
      content:'';
      position:absolute; left:50%; top:0;
      width:calc(100% - 28px); height:2px;
      background-color:color(grey);
      @include prefix(transform, translateX(-50%), webkit moz o ms);
    }
  }

  .footer-logo {
    float:left;
    width:117px;
    flex-shrink:0;

    @include media-breakpoint-up(xs-375) {
      width:calc(117px + 47 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      width:164px;
    }
  }
  .footer-content {
    display:flex; flex-wrap:wrap;
    float:left;
    width:100%;
    margin-top:24px;

    @include media-breakpoint-up(md) {
      margin-top:0;
    }

    > div {
      float:left;
      width:50%;
      padding-right:10px; margin-top:16px;

      @include media-breakpoint-up(sm) {
        width:33.3%;
      }
      @include media-breakpoint-up(md) {
        margin-top:0;
        padding-right:20px;
      }
      @include media-breakpoint-up(lg) {
        width:27.3%;
        padding-right:30px;
      }

      h6, .h6 {
        margin-bottom:6px;

        @include media-breakpoint-up(xs-375) {
          margin-bottom:calc(6px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-bottom:8px;
        }
      }
      a, p {
        width:auto;
        margin-top:0;
        line-height:20px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(0px + 6 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:6px;
        }
      }
      a {
        display:flex; float:left;
        font-size:14px; font-family:$font_frutiger_bold; color:color(red); font-weight:normal;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        &.phone {
          color:color(dark);
        }
        i {
          margin-left:8px;
          font-size:16px; line-height:20px;
        }
        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(dark);
          }
        }
      }
      p {
        font-size:13px; color:color(dark-grey); font-family:$font_frutiger; letter-spacing:0;
      }
    }
  }
  .footer-social {
    width:auto;
    margin-top:40px;

    @include media-breakpoint-up(md) {
      margin-top:20px;
    }

    li {
      width:auto;
      margin-right:10px;

      &:last-child {
        margin-right:0;
      }
      a {
        position:relative;
        display:block; float:left;
        width:50px; height:50px;

        i {
          position:absolute; left:50%; top:50%;
          width:20px; height:20px;
          font-size:20px; line-height:20px; color:color(dark);
          @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
        }
        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:100%;
          border:1px solid color(med-grey); border-radius:10px;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            &:before {
              border:2px solid color(red);
            }
          }
        }
      }
    }
  }
  .footer-logo-bottom {
    float:right;
    width:94px;
    margin-top:40px;
    flex-shrink:0;

    @include media-breakpoint-up(md) {
      margin-top:20px;
    }
    @include media-breakpoint-up(lg) {
      @include prefix(transform, translateY(70px), webkit moz o ms);
    }
  }
  .footer-bottom {
    margin-top:40px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(40px + 10 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:50px;
    }

    > div {
      .footer-menu {
        display:block; float:left;
        width:100%;

        ul {
          margin-bottom:5px;

          @include media-breakpoint-up(md) {
            margin-bottom:0;
          }

          li {
            width:auto;
            margin-right:25px; margin-bottom:15px;

            @include media-breakpoint-up(xs-375) {
              margin-right:calc(25px + 20 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(md) {
              margin-bottom:0;
            }
            @include media-breakpoint-up(xl-1440) {
              margin-right:45px;
            }

            &:last-child {
              margin-right:0;
            }

            a {
              display:block; float:left;
              width:auto;
              font-size:12px; line-height:16px; color:color(dark); text-transform:uppercase;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);

              @include media-breakpoint-up(lg) {
                &:hover {
                  color:color(red);
                }
              }
            }
          }
        }
      }
      .footer-copyright {
        float:left;
        width:100%;

        p {
          font-size:13px; line-height:16px; color:color(dark); font-family:$font_frutiger; letter-spacing:0;
        }
      }
    }
  }
}
