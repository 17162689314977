input, textarea, select {
  @include prefix(border-radius, 0!important, webkit moz o ms);
  @include prefix(appearance, none!important, webkit moz o ms);
}

form {
  display:block; float:left;
  width:100%;

  fieldset {
    display:block; float:left;
    width:100%;

    input, textarea {
      display:block; float:left;
      width:100%;
      border:0 none; border-radius:0;
      background-color:transparent;
    }
  }
}

.form-wrapper {
  display:block; float:left;
  width:100%;
  padding:24px 20px 32px 20px;
  background-color:color(white);
  @include prefix(box-shadow, 0px 4px 40px 0px rgba(0,0,0,0.06), webkit moz o ms);

  @include media-breakpoint-up(xs-375) {
    padding:calc(24px + 6 * ((100vw - 375px) / 1065)) calc(20px + 12 * ((100vw - 375px) / 1065)) calc(32px + 20 * ((100vw - 375px) / 1065)) calc(20px + 12 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    padding:30px 32px 52px 32px;
  }

  .mandatory {
    font-size:12px; line-height:22px; color:color(dark-grey); font-family:$font_frutiger; text-align:right;

    @include media-breakpoint-up(xs-375) {
      font-size:calc(12px + 2 * ((100vw - 375px) / 1065)); line-height:calc(22px + 2 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      font-size:14px; line-height:24px;
    }

    span {
      color:color(red);
    }
  }

  .form-title {
    margin-top:34px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(34px + 16 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:50px;
    }

    &.mt-small {
      margin-top:8px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(8px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:10px;
      }
    }

    h3, .h3 {
      font-size:18px; line-height:28px; font-family:$font_frutiger_bold; color:color(black); letter-spacing:0.02em;

      @include media-breakpoint-up(xs-375) {
        font-size:calc(18px + 4 * ((100vw - 375px) / 1065)); line-height:calc(28px + 6 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        font-size:22px; line-height:34px;
      }
    }
  }

  .form-field, .form-button {
    position:relative;
    float:left;
    width:100%;
  }
  .form-field {
    margin-top:20px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(20px + 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:28px;
    }

    label, .label {
      display:block; float:left;
      width:100%;
      margin-bottom:8px;
      font-size:12px; line-height:22px; color:color(dark-grey); font-family:$font_frutiger;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(8px + 4 * ((100vw - 375px) / 1065));
        font-size:calc(12px + 2 * ((100vw - 375px) / 1065)); line-height:calc(22px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:12px;
        font-size:14px; line-height:24px;
      }

      span {
        color:color(red);
      }
    }

    input, textarea {
      display:block; float:left;
      width:100%;
      border:2px solid color(grey);
      padding:9px 13px; border-radius:6px!important;
      font-size:16px; line-height:23px; color:color(dark); font-family:$font_frutiger;
      background-color:color(grey);

      &::-webkit-input-placeholder { color:color(dark-grey); font-size:13px; }
      &::-moz-placeholder { color:color(dark-grey); font-size:13px; }
      &:-ms-input-placeholder { color:color(dark-grey); font-size:13px; }
      &:-moz-placeholder { color:color(dark-grey); font-size:13px; }

      @include media-breakpoint-up(lg) {
        font-size:13px;
      }

      &:disabled {
        color:color(med-grey);

        &::-webkit-input-placeholder { color:color(med-grey); }
        &::-moz-placeholder { color:color(med-grey); }
        &:-ms-input-placeholder { color:color(med-grey); }
        &:-moz-placeholder { color:color(med-grey); }
      }

      &.focus {
        border:2px solid color(blue,0.7);
        background-color:color(blue,0.05);
      }
    }

    textarea {
      min-height:252px;
    }

    .select2-container {
      .select2-selection {
        height:45px;
        border-radius:6px; border:1px solid color(grey)!important;
        background-color:color(grey);

        .select2-selection__rendered {
          padding:11px 42px 11px 15px;
          font-size:13px; line-height:23px; color:color(dark-grey); font-family:$font_frutiger; letter-spacing:0;
        }
        .select2-selection__arrow {
          height:45px;

          &:after {
            right:15px;
          }
        }

        &.active {
          .select2-selection__rendered {
            color:color(dark);
          }
        }
      }

      &.select2-container--open {
        .select2-selection {
          border-radius:6px; border-bottom-left-radius:0; border-bottom-right-radius:0;
          border:1px solid color(med-grey)!important; border-bottom:0 none!important;
          background-color:color(white);
        }
        .select2-dropdown--below {
          border-radius:6px; border-top-left-radius:0; border-top-right-radius:0;

          .select2-results__options {
            .select2-results__option {
              padding:7px 15px;
              font-size:13px; line-height:20px; letter-spacing:0;

              &:first-child {
                display:none;
              }
            }
          }
        }
      }
    }

    [type="radio"]:checked, [type="radio"]:not(:checked),
    [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
      position:absolute; left:-9999px;
      padding:0; border:0 none;
      width:0; height:0;
    }
    [type="radio"]:checked + label, [type="radio"]:not(:checked) + label,
    [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
      position:relative;
      display:inline-block;
      padding:2px 0 2px 26px; margin:0;
      cursor:pointer;
      font-size:14px; line-height:24px; color:color(dark); font-family:$font_frutiger;

      a {
        color:color(red);
        text-decoration:underline!important;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(dark);
          }
        }
      }
    }
    [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
      content:'';
      position:absolute; left:0; top:4px;
      width:16px; height:16px;
      border:2px solid color(med-grey);
      background-color:transparent;
      @include prefix(border-radius, 50%, webkit moz o ms);
      @include prefix(transition, all .2s ease-out, webkit moz o ms);
    }
    [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
      content:'';
      position:absolute; left:0; top:2px;
      width:20px; height:20px;
      border:2px solid color(med-grey);
      background-color:transparent;
      @include prefix(border-radius, 4px, webkit moz o ms);
      @include prefix(transition, all .2s ease-out, webkit moz o ms);
    }
    [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
      content:'';
      position:absolute;
      left:4px; top:8px;
      width:8px; height:8px;
      background-color:color(red);
      opacity:0;
      @include prefix(border-radius, 50%, webkit moz o ms);
      @include prefix(transition, all .2s ease-out, webkit moz o ms);
    }
    [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
      content:$icon-mendrisio-check;
      position:absolute;
      left:0; top:2px;
      width:20px; height:20px;
      font-size:12px; line-height:20px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal; text-align:center;
      opacity:0;
      @include prefix(transition, all .2s ease-out, webkit moz o ms);
    }
    [type="radio"]:checked + label:after,
    [type="checkbox"]:checked + label:after {
      opacity:1;
    }

    .field-list {
      display:flex; flex-direction:column;
      margin-bottom:8px;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(8px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:12px;
      }

      li {
        margin-bottom:10px;

        input {
          width:calc(100% - 26px);
          margin-left:26px; margin-top:2px;
        }
      }
    }
    .phone-group {
      display:flex; flex-direction:row;
      float:left;
      width:100%;

      .select2-container {
        width:auto!important; min-width:90px;

        .select2-selection {
          border-top-right-radius:0!important; border-bottom-right-radius:0!important;
          border-right:0 none!important;

          .select2-selection__rendered {
            padding:11px 24px 11px 15px;
          }
          .select2-selection__arrow {
            width:24px;

            &:after {
              right:10px;
              width:6px; height:6px;
              font-size:6px; line-height:6px; color:color(dark-grey);
            }
          }

          &.active {
            .select2-selection__rendered {
              color:color(dark-grey);
            }
          }
        }
        &.select2-container--open {
          .select2-dropdown--below {
            .select2-results__options {
              .select2-results__option {
                &:first-child {
                  display:block;
                }
              }
            }
          }
        }
      }
      .prefix {
        display:block; float:left;
        min-width:38px;
        padding:11px 0 11px 10px;
        font-size:14px; line-height:23px; color:color(dark); font-family:$font_frutiger;
        background-color:color(grey);
      }
      input {
        padding-left:8px;
        border-top-left-radius:0!important; border-bottom-left-radius:0!important;
      }
    }
    &.file {
      .file-drop-area {
        position:relative;
        float:left;
        width:100%; min-height:176px;
        padding:26px 20px 28px 20px; border:2px dashed color(med-grey); border-radius:6px;
        text-align:center; font-size:0;
        background-color:color(grey);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-360) {
          min-height:calc(176px + 18 * ((100vw - 360px) / 1080));
          padding:calc(26px + 20 * ((100vw - 360px) / 1080)) 20px calc(28px + 30 * ((100vw - 360px) / 1080)) 20px;
        }
        @include media-breakpoint-up(xl-1440) {
          min-height:194px;
          padding:44px 20px 54px 20px;
        }

        .file-input {
          position:absolute; left:0; top:0;
          height:100%; width:100%;
          cursor:pointer;
          opacity:0;
          z-index:1;
        }
        .selection-button {
          position:relative;
          display:inline-block; float:none;
          width:100%;
          margin:0;
          font-size:13px; line-height:20px; color:color(dark-grey); font-family:$font_frutiger; text-align:center;
          cursor:pointer;
          //z-index:2;

          span {
            text-decoration:underline;
          }
        }
        a.reset-file {
          position:absolute; left:50%; bottom:32px;
          display:none;
          padding:0 26px 0 0;
          color:color(dark-grey); font-size:11px; line-height:20px; text-decoration:none!important; font-family:$font_frutiger_bold; text-transform:uppercase;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
          @include prefix(transform, translateX(-50%), webkit moz o ms);
          z-index:2;

          i {
            position:absolute; right:0; top:50%;
            width:20px; height:20px;
            margin:0!important;
            font-size:7px; line-height:20px; color:color(dark-grey); text-align:center; font-weight:bold;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
            @include prefix(transform, translateY(-50%), webkit moz o ms);

            &:after {
              content:'';
              position:absolute; left:0; top:0;
              width:100%; height:100%;
              border:1px solid color(dark-grey);
              @include prefix(border-radius, 50%, webkit moz o ms);
            }
          }
          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(dark);
              i {
                color:color(dark);

                &:after {
                  border:1px solid color(dark);
                }
              }
            }
          }
        }
        &:before {
          content:$icon-mendrisio-upload;
          display:inline-block; float:none;
          width:42px; height:42px;
          margin-bottom:4px;
          font-size:42px; line-height:42px; color:color(dark-grey); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        }
        .file-msg {
          //display:none!important;
          width:100%;
          margin:0 0 6px 0;
          font-size:13px; color:color(dark-grey); font-family:$font_frutiger;
          text-overflow:ellipsis; white-space:nowrap; cursor:pointer;
          display:inline-block;
          overflow:hidden;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(lg) {
            &:hover, &:focus {
              color:color(dark);
            }
          }
        }
        &.is-active {
          background-color:color(med-grey,0.7);
        }
        &.file-ready {
          /*.selection-button {
            display:none;
          }*/
          a.reset-file {
            display:inline-block; float:none;
          }
          .selection-button {
            display:none;
          }
          .file-msg {
            font-size:15px; text-decoration:underline; font-family:$font_frutiger_bold;
            //display:block!important;
          }
        }
      }
    }

    &.error {
      input, textarea {
        padding-right:42px;
        border:2px solid color(red);
        color:color(red);
        background-color:color(red,0.05);
        background-image:url('../img/icons/alert-round.svg'); background-size:15px; background-repeat:no-repeat; background-position:top 13px right 13px;
      }
      .error {
        position:absolute; left:0; top:100%;
        display:block;
        width:100%;
        font-size:12px; line-height:24px; color:color(red); font-family:$font_frutiger_bold;
      }
    }

    &.captcha {
      img {
        width:auto;
      }
    }
  }
  .form-button {
    margin-top:16px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:20px;
    }

    .btn {
      float:right;
    }
  }
}

.custom-select {
  display:none;
}

.select2-container {
  width:100%!important;

  .select2-selection {
    height:56px;
    border-radius:4px; border:1px solid color(med-grey)!important;
    background-color:color(white);

    .select2-selection__rendered {
      padding:18px 42px 18px 18px;
      font-size:14px; line-height:20px; color:color(black); font-family:$font_frutiger; letter-spacing:0.28px;
    }
    .select2-selection__arrow {
      width:42px; height:56px;

      b {
        display:none;
      }
      &:after {
        content:$icon-mendrisio-chevron-right;
        position:absolute; right:18px; top:50%;
        width:12px; height:12px;
        font-size:12px; line-height:12px; color:color(black); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        @include prefix(transform, translateY(-50%) rotate(90deg), webkit moz o ms);
      }
    }
  }

  &.select2-container--open {
    .select2-selection {
      border-bottom:0 none!important;
      border-bottom-left-radius:0; border-bottom-right-radius:0;

      .select2-selection__arrow {
        &:after {
          @include prefix(transform, translateY(-50%) rotate(-90deg), webkit moz o ms);
        }
      }
    }
    .select2-dropdown--below {
      border-radius:4px; border-top-left-radius:0; border-top-right-radius:0;
      border:1px solid color(med-grey)!important; border-top:0 none!important;
      box-sizing:border-box;

      .select2-results__options {
        max-height:238px;
        padding-bottom:10px;

        @include media-breakpoint-up(lg) {
          max-height:none;
        }

        .select2-results__option {
          padding:7px 18px;
          font-size:14px; line-height:20px; color:color(dark-grey); font-family:$font_frutiger; letter-spacing:0.28px;

          &.select2-results__option--selected {
            font-family:$font_frutiger_bold; color:color(white)!important; font-weight:normal;
            background-color:color(red)!important;
          }
          &.select2-results__option--highlighted {
            background-color:color(light-grey);
          }
        }
      }
    }
  }
}

.select-alt {
  position:relative;
  float:left;
  width:100%;

  .label {
    position:relative;
    display:block; float:left;
    width:100%; height:56px;
    padding:18px 42px 18px 18px;
    border-radius:4px; border:1px solid color(med-grey)!important;
    font-size:14px; line-height:20px; color:color(black); font-family:$font_frutiger; letter-spacing:0.28px;
    background-color:color(white);
    cursor:pointer;

    &:after {
      content:$icon-mendrisio-chevron-right;
      position:absolute; right:18px; top:50%;
      width:12px; height:12px;
      font-size:12px; line-height:12px; color:color(black); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
      @include prefix(transform, translateY(-50%) rotate(90deg), webkit moz o ms);
    }
  }
  ul {
    position:absolute; left:0; top:100%;
    display:none;
    width:100%;
    padding-bottom:10px;
    border-radius:4px; border-top-left-radius:0; border-top-right-radius:0;
    border:1px solid color(med-grey)!important; border-top:0 none!important;
    box-sizing:border-box;
    background-color:color(white);

    li {
      padding:7px 18px;

      [type="radio"]:checked, [type="radio"]:not(:checked),
      [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
        position:absolute; left:-9999px;
        padding:0; border:0 none;
        width:0; height:0;
      }
      [type="radio"]:checked + label, [type="radio"]:not(:checked) + label,
      [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
        position:relative;
        display:inline-block;
        padding-left:26px; margin:0;
        cursor:pointer;
        font-size:14px; line-height:20px; color:color(dark); font-family:$font_frutiger;
      }
      [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
        content:'';
        position:absolute; left:0; top:2px;
        width:16px; height:16px;
        border:2px solid color(med-grey);
        background-color:transparent;
        @include prefix(border-radius, 50%, webkit moz o ms);
        @include prefix(transition, all .2s ease-out, webkit moz o ms);
      }
      [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
        content:'';
        position:absolute; left:0; top:0;
        width:20px; height:20px;
        border:2px solid color(med-grey);
        background-color:transparent;
        @include prefix(border-radius, 4px, webkit moz o ms);
        @include prefix(transition, all .2s ease-out, webkit moz o ms);
      }
      [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
        content:'';
        position:absolute;
        left:4px; top:6px;
        width:8px; height:8px;
        background-color:color(dark);
        opacity:0;
        @include prefix(border-radius, 50%, webkit moz o ms);
        @include prefix(transition, all .2s ease-out, webkit moz o ms);
      }
      [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
        content:$icon-mendrisio-check;
        position:absolute;
        left:0; top:0;
        width:20px; height:20px;
        font-size:12px; line-height:20px; color:color(dark); font-family:'icomoon'!important; font-weight:normal; font-style:normal; text-align:center;
        opacity:0;
        @include prefix(transition, all .2s ease-out, webkit moz o ms);
      }
      [type="checkbox"]:checked + label:before {
        border:2px solid color(dark);
      }
      [type="radio"]:checked + label:after,
      [type="checkbox"]:checked + label:after {
        opacity:1;
      }
    }
  }

  &.open {
    .label {
      border-bottom:0 none!important;
      border-bottom-left-radius:0; border-bottom-right-radius:0;

      &:after {
        @include prefix(transform, translateY(-50%) rotate(-90deg), webkit moz o ms);
      }
    }
    ul {
      display:block;
    }
  }
}

.filter-calendar {
  position:relative;
  float:left;
  width:100%;

  .label {
    position:relative;
    display:block; float:left;
    width:100%; height:56px;
    padding:18px 42px 18px 18px;
    border-radius:4px; border:1px solid color(med-grey)!important;
    font-size:14px; line-height:20px; color:color(black); font-family:$font_frutiger; letter-spacing:0.28px;
    background-color:color(white);
    cursor:pointer;

    &:after {
      content:$icon-mendrisio-chevron-right;
      position:absolute; right:18px; top:50%;
      width:12px; height:12px;
      font-size:12px; line-height:12px; color:color(black); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
      @include prefix(transform, translateY(-50%) rotate(90deg), webkit moz o ms);
    }
  }
  > .event-calendar-wrapper, > .agenda-calendar-wrapper {
    position:absolute; left:0; top:100%;
    display:none;
    width:100%;
    padding-left:0;
    border-radius:4px; border-top-left-radius:0; border-top-right-radius:0;
    border:1px solid color(med-grey)!important; border-top:0 none!important;
    box-sizing:border-box;
    background-color:color(white);

    .mbsc-datepicker {
      padding:0 10px 14px 10px;
      border-radius:0;
      background-color:transparent;

      .mbsc-calendar-controls {
        .mbsc-calendar-button {
          span {
            font-size:16px;
          }
        }
      }
      .mbsc-calendar-body {
        margin-top:10px;
      }
      .mbsc-calendar-picker {
        .mbsc-calendar-slide {
          .mbsc-calendar-table {
            .mbsc-calendar-week-days {
              background-color:transparent;
            }
          }
        }
      }
    }
  }

  &.open {
    .label {
      border-bottom:0 none!important;
      border-bottom-left-radius:0; border-bottom-right-radius:0;

      &:after {
        @include prefix(transform, translateY(-50%) rotate(-90deg), webkit moz o ms);
      }
    }
    > .event-calendar-wrapper, > .agenda-calendar-wrapper {
      display:block;
    }
  }
}
