.hero-wrapper {
  margin-top:0; padding-bottom:310px;
  background-position:center; background-repeat:no-repeat; background-size:cover;
  overflow:visible;
  z-index:2;

  @include media-breakpoint-up(xs-375) {
    padding-bottom:calc(310px + 200 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    padding-bottom:calc(510px + 40 * ((100vw - 1440px) / 480));
  }
  @include media-breakpoint-up(xxl-1920) {
    padding-bottom:550px;
  }

  .content {
    position:absolute; left:0; top:50%;
    width:100%;
    margin-top:-30px;
    padding-left:6px; padding-right:6px;
    z-index:3;
    @include prefix(transform, translateY(-50%), webkit moz o ms);

    @include media-breakpoint-up(lg) {
      top:115px;
      margin-top:0;
      @include prefix(transform, translateY(0%), webkit moz o ms);
    }

    h1, .h1 {
      font-size:26px; line-height:32px; color:color(white); text-align:center; font-family:$font_frutiger_light;

      @include media-breakpoint-up(xs-375) {
        font-size:calc(26px + 9 * ((100vw - 375px) / 1065)); line-height:calc(32px + 10 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(lg) {
        text-align:left;
      }
      @include media-breakpoint-up(xl-1440) {
        font-size:35px; line-height:42px;
      }

      strong {
        font-family:$font_frutiger_bold; font-weight:normal;
      }

      strong.main {
        display:block; float:left;
        width:100%;
        margin-bottom:10px;
        font-size:40px; line-height:40px;

        @include media-breakpoint-up(xs-375) {
          margin-bottom:calc(10px + 5 * ((100vw - 375px) / 1065));
          font-size:calc(40px + 30 * ((100vw - 375px) / 1065)); line-height:calc(40px + 30 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-bottom:15px;
          font-size:70px; line-height:70px;
        }
      }
    }
    .btn {
      margin-top:22px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(22px + 20 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:42px;
      }
    }
  }
  .search {
    position:absolute; left:0; bottom:-35px;
    width:100%;
    z-index:4;

    @include media-breakpoint-up(lg) {
      bottom:calc(45px + 40 * ((100vw - 992px) / 448));
    }
    @include media-breakpoint-up(xl-1440) {
      bottom:85px;
    }
  }
  .hero-video {
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    overflow:hidden;
    z-index:1;

    > div {
      position:absolute; left:50%; top:50%;
      width:640px;
      @include prefix(transform, translate(-50%,-50%), webkit moz o ms);

      @include media-breakpoint-up(sm-640) {
        width:100%;
      }

      &.ratio-16x9 {
        padding-bottom:360px;

        @include media-breakpoint-up(sm-640) {
          padding-bottom:56.25%;
        }
      }
      &.ratio-4x3{
        padding-bottom:480px;

        @include media-breakpoint-up(sm-640) {
          padding-bottom:75%;
        }
      }

      iframe {
        position:absolute; left:0; top:0;
        width:100%; height:100%;
      }
    }
  }

  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    background:color(black,0.4);
    z-index:2;

    @include media-breakpoint-up(lg) {
      background:rgb(0,0,0); background:linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
    }
  }

  &.v-slider {
    padding:0;    

    &:before {
      content:none;
    }
    .content {
      z-index:3;
    }
    .hero-slider {
      position:relative;
      float:left;
      width:100%;
      z-index:1;

      .swiper-slide {
        height:424px;
        background-position:center; background-repeat:no-repeat; background-size:cover;

        @include media-breakpoint-up(xs-375) {
          height:calc(424px + 260 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          height:684px;
        }

        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:100%;
          background:color(black,0.4);
          z-index:2;
      
          @include media-breakpoint-up(lg) {
            background:rgb(0,0,0); background:linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
          }
        }        
        video {
          position:absolute; left:50%; top:50%;
          width:100%; height:100%;
          object-fit:cover;
          @include prefix(transform, translate(-50%, -50%), webkit moz o ms);
          z-index:1;
        }

        .content {
          text-align:center;
          
          @include media-breakpoint-up(lg) {
            text-align:left;
          }
        }
      }
    }
    .hero-slider-navigation {
      position:absolute; left:0; bottom:50px;
      width:100%;
      z-index:4;

      @include media-breakpoint-up(lg) {
        bottom:calc(126px + 100 * ((100vw - 992px) / 448));
      }
      @include media-breakpoint-up(xl-1440) {
        bottom:226px;
      }

      .row {
        > div {
          display:flex; align-items:center; justify-content:center;
          
          @include media-breakpoint-up(lg) {
            justify-content:flex-start;
          }

          .swiper-button-prev, .swiper-button-next {
            position:relative; left:inherit; right:inherit; top:inherit; bottom:inherit;
            background-color:transparent;
            @include prefix(transform, translateY(0%), webkit moz o ms);
            @include prefix(filter, none, webkit moz o ms);

            &:before {
              color:color(white);
            }
          }
          .swiper-pagination {
            width:auto;
            margin:0 12px;

            .swiper-pagination-bullet {
              &.swiper-pagination-bullet-active {
                &:before {
                  border:2px solid color(white);
                }
              }
            }
          }
        }
      }
    }
    .search {
      bottom:-35px;
      z-index:5;

      @include media-breakpoint-up(lg) {
        bottom:calc(45px + 75 * ((100vw - 992px) / 448));
      }
      @include media-breakpoint-up(xl-1440) {
        bottom:120px;
      }
    }
  }
}

.hero-section {
  position:relative;
  margin-top:0;
  padding-top:14px; padding-bottom:14px;
  background-color:color(grey);

  .container {
    position:relative;
    z-index:2;

    .row {
      &:first-child {
        > div {
          display:flex; flex-direction:column; justify-content:flex-start;

          @include media-breakpoint-up(lg) {
            min-height:378px;
          }
        }
      }
      > div {
        .breadcrumb {
          position:relative;
          display:block; float:left;
          width:100%;
          margin-bottom:0;
          z-index:1;

          ul {
            li {
              position:relative;
              width:auto;
              padding-right:10px; margin-right:10px;

              @include media-breakpoint-up(xs-375) {
                padding-right:calc(10px + 3 * ((100vw - 375px) / 1065)); margin-right:calc(10px + 3 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                padding-right:13px; margin-right:13px;
              }

              &:after {
                content:$icon-mendrisio-chevron-right;
                position:absolute; right:0; top:50%;
                width:7px; height:7px;
                font-size:7px; line-height:7px; color:color(med-grey); font-family:'icomoon'!important; font-weight:bold; font-style:normal;
                @include prefix(transform, translate(50%,-50%), webkit moz o ms);
              }

              a, span {
                display:block; float:left;
                width:auto;
                font-size:14px; line-height:24px; color:color(dark-grey); font-family:$font_frutiger;
              }
              a {
                text-decoration:underline!important;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                @include media-breakpoint-up(lg) {
                  &:hover {
                    color:color(dark);
                  }
                }
              }

              &:last-child {
                padding-right:0; margin-right:0;

                &:after {
                  content:none;
                }
              }
            }
          }
        }
        .content {
          position:relative;
          margin:30px 0;
          z-index:2;

          @include media-breakpoint-up(lg) {
            margin:auto 0;
          }

          h1, .h1 {

            &.small {
              font-size:30px; line-height:34px;

              @include media-breakpoint-up(xs-375) {
                font-size:calc(30px + 6 * ((100vw - 375px) / 1065)); line-height:calc(34px + 4 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                font-size:36px; line-height:38px;
              }
            }
          }

          p {
            margin-top:8px;
            font-size:20px; line-height:28px; letter-spacing:0;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(8px + 10 * ((100vw - 375px) / 1065));
              font-size:calc(20px + 5 * ((100vw - 375px) / 1065)); line-height:calc(28px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:18px;
              font-size:25px; line-height:32px;
            }
          }
        }
      }
    }
  }
  .hero-image {
    position:relative;
    float:left;
    width:calc(100% + 12px); height:240px;
    margin:-14px -6px 14px -6px;
    z-index:1;
    background-position:center; background-repeat:no-repeat; background-size:cover;

    @include media-breakpoint-up(lg) {
      position:absolute; right:0; top:0;
      width:calc(460px + 20 * ((100vw - 992px) / 208)); height:100%;
      margin:0;
    }
    @include media-breakpoint-up(xl) {
      width:calc(480px + 120 * ((100vw - 1200px) / 240));
    }
    @include media-breakpoint-up(xl-1440) {
      width:calc(600px + 200 * ((100vw - 1440px) / 480));
    }
    @include media-breakpoint-up(xxl-1920) {
      width:800px;
    }
  }

  &.light {
    background-color:transparent;

    .container {
      .row {
        > div {
          min-height:0;

          .content {
            margin:30px 0 0 0; padding-left:20px;

            @include media-breakpoint-up(xs-375) {
              margin:calc(30px + 10 * ((100vw - 375px) / 1065)) 0 0 0; padding-left:calc(20px + 10 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin:40px 0 0 0; padding-left:30px;
            }

            &:before {
              content:'';
              position:absolute; left:0; top:0;
              width:3px; height:100%;
              background-color:color(red);
            }
          }
        }
      }
    }
  }

  &.intro {
    .container {
      .row {
        > div {
          .content {
            .label {
              float:left;
              width:100%;
              margin-bottom:13px; margin-top:-10px;

              @include media-breakpoint-up(xs-375) {
                margin-bottom:calc(13px + 5 * ((100vw - 375px) / 1065)); margin-top:calc(-10px - 4 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                margin-bottom:18px; margin-top:-14px;
              }

              > * {
                margin-top:10px!important;

                @include media-breakpoint-up(xs-375) {
                  margin-top:calc(10px + 4 * ((100vw - 375px) / 1065))!important;
                }
                @include media-breakpoint-up(xl-1440) {
                  margin-top:14px!important;
                }
              }

              > time, > span, > a {
                display:block; float:left;
                width:auto;
                padding:5px 13px 4px 13px; border-radius:5px;
                margin-right:18px;
                font-size:11px; line-height:18px; font-family:$font_frutiger_bold; letter-spacing:0.1em; text-transform:uppercase;

                @include media-breakpoint-up(xs-375) {
                  margin-right:calc(18px + 4 * ((100vw - 375px) / 1065));
                }
                @include media-breakpoint-up(xl-1440) {
                  margin-right:24px;
                }
              }
              > time {
                color:color(white);
                background-color:color(red);

                strong {
                  display:block; float:left;
                  width:auto;
                  margin-right:6px;
                  font-size:18px;
                }
              }
              > span, > a {
                position:relative;
                color:color(dark);
                background-color:color(white);

                &:before {
                  content:'';
                  position:absolute; left:0; top:0;
                  width:100%; height:100%;
                  border:2px solid color(dark-grey,0.3); border-radius:5px;
                }

                &.dark {
                  color:color(white);
                  background-color:color(dark);

                  &:before {
                    content:none;
                  }
                }
              }
              > .status {
                position:relative;
                display:block; float:left;
                width:auto;
                padding-left:17px; margin-top:0;
                font-size:13px; line-height:27px; color:color(dark-grey); letter-spacing:0.02em; font-family:$font_frutiger_bold;

                &:before {
                  content:'';
                  position:absolute; left:0; top:10px;
                  width:7px; height:7px;
                  border-radius:50%;
                  background-color:color(red);
                }
              }
            }

            .info {
              display:flex; flex-direction:column; justify-content:flex-start; align-items:flex-start;
              float:left;
              width:100%;
              margin-top:16px;

              @include media-breakpoint-up(xs-375) {
                margin-top:calc(16px + 14 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(sm) {
                flex-direction:row; justify-content:space-between; align-items:center;
              }
              @include media-breakpoint-up(xl-1440) {
                margin-top:30px;
              }

              p {
                width:auto;
                margin-top:10px;
                font-size:18px; line-height:24px; color:color(black); font-family:$font_frutiger; font-style:italic;
              }
              ul {
                width:auto;
                margin-top:5px;

                li {
                  width:100%;
                  margin-top:5px;

                  @include media-breakpoint-up(xs-480) {
                    width:auto;
                    padding-right:calc(16px + 10 * ((100vw - 480px) / 960)); margin-right:calc(16px + 10 * ((100vw - 480px) / 960));
                    border-right:1px solid color(med-grey);
                  }
                  @include media-breakpoint-up(xl-1440) {
                    padding-right:26px; margin-right:26px;
                  }

                  span {
                    position:relative;
                    display:block; float:left;
                    width:auto;
                    padding-left:22px;
                    font-size:15px; line-height:24px; color:color(black); font-family:$font_frutiger_light;

                    i {
                      position:absolute; left:0; top:5px;
                      width:14px; height:14px;
                      font-size:14px; line-height:14px; color:color(red);
                    }
                  }

                  &:last-child {
                    padding-right:0; margin-right:0;
                    border-right:0 none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.news {
    padding-bottom:60px;
    z-index:0;

    @include media-breakpoint-up(xs-375) {
      padding-bottom:calc(60px + 20 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-bottom:80px;
    }

    .container {
      .row {
        > div {
          min-height:0;

          .breadcrumb {
            position:relative;
            background-color:color(white);

            &:before {
              content:'';
              position:absolute; left:50%; top:-14px;
              width:100vw; height:calc(100% + 28px);
              background-color:color(white);
              @include prefix(transform, translateX(-50%), webkit moz o ms);
            }
            ul {
              position:relative;
              z-index:2;
            }
          }

          .content {
            margin:44px 0 0 0; padding-left:20px;

            @include media-breakpoint-up(xs-375) {
              margin:calc(44px + 20 * ((100vw - 375px) / 1065)) 0 0 0; padding-left:calc(20px + 10 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin:64px 0 0 0; padding-left:30px;
            }

            .news-intro {
              float:left;
              width:100%;
              margin-bottom:13px; margin-top:-10px;

              @include media-breakpoint-up(xs-375) {
                margin-bottom:calc(13px + 5 * ((100vw - 375px) / 1065)); margin-top:calc(-10px - 4 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                margin-bottom:18px; margin-top:-14px;
              }

              > * {
                margin-top:10px!important;

                @include media-breakpoint-up(xs-375) {
                  margin-top:calc(10px + 4 * ((100vw - 375px) / 1065))!important;
                }
                @include media-breakpoint-up(xl-1440) {
                  margin-top:14px!important;
                }
              }

              > time, > span, > a {
                display:block; float:left;
                width:auto;
                margin-right:18px;

                @include media-breakpoint-up(xs-375) {
                  margin-right:calc(18px + 6 * ((100vw - 375px) / 1065));
                }
                @include media-breakpoint-up(xl-1440) {
                  margin-right:24px;
                }
              }
              > time {
                font-size:13px; line-height:27px; color:color(dark); font-family:$font_frutiger_light; letter-spacing:0.02em;
              }
              > span, > a {
                padding:5px 13px 4px 13px; border-radius:5px;
                font-size:11px; line-height:18px; color:color(white); font-family:$font_frutiger; letter-spacing:0.1em; text-transform:uppercase;
                background-color:color(dark);
              }
            }

            h1, .h1 {
              width:calc(100% - 38px);
              font-size:30px; line-height:34px;

              @include media-breakpoint-up(xs-375) {
                font-size:calc(30px + 6 * ((100vw - 375px) / 1065)); line-height:calc(34px + 4 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                font-size:36px; line-height:38px;
              }
            }
            .share {
              display:block; float:right;
              width:18px; height:18px;
              margin:6px 0 6px 20px;

              @include media-breakpoint-up(xs-375) {
                margin:calc(6px + 3 * ((100vw - 375px) / 1065)) 0 calc(6px + 3 * ((100vw - 375px) / 1065)) 20px;
              }
              @include media-breakpoint-up(xl-1440) {
                margin:9px 0 9px 20px;
              }

              i {
                width:18px; height:18px;
                font-size:18px; line-height:18px; color:color(red);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
              }

              @include media-breakpoint-up(lg) {
                &:hover {
                  i {
                    color:color(dark);
                  }
                }
              }
            }

            &:before {
              content:'';
              position:absolute; left:0; bottom:0;
              width:3px; height:calc(100% - 40px);
              background-color:color(red);

              @include media-breakpoint-up(xs-375) {
                height:calc(100% - calc(40px + 5 * ((100vw - 375px) / 1065)));
              }
              @include media-breakpoint-up(xl-1440) {
                height:calc(100% - 45px);
              }
            }
          }
        }
      }
    }
  }

  &.profile {
    .container {
      .row {
        &:first-child {
          > div {
            flex-direction:row; flex-wrap:wrap; align-items:flex-start;
          }
        }
        > div {
          .image {
            float:left;
            width:100%;
            margin-top:30px;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(30px + 10 * ((100vw - 375px) / 1065)); padding-right:calc(20px + 6 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xs-480) {
              width:50%;
              padding-right:20px;
            }
            @include media-breakpoint-up(md) {
              width:34.5%;
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:40px; padding-right:26px;
            }

            img {
              border-radius:6px;
            }
          }
          .content {
            width:100%;

            @include media-breakpoint-up(xs-480) {
              width:50%;
            }
            @include media-breakpoint-up(md) {
              width:65.5%;
            }

            p.role {
              display:flex; flex-direction:row;
              margin-top:10px;
              font-size:14px; line-height:22px; font-family:$font_frutiger; color:color(dark-grey);

              @include media-breakpoint-up(xs-375) {
                margin-top:calc(10px + 4 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                margin-top:14px;
              }

              strong {
                font-size:11px; color:color(red); text-transform:uppercase; font-family:$font_frutiger; font-weight:normal;
                padding-right:16px;

                @include media-breakpoint-up(xs-375) {
                  padding-right:calc(16px + 4 * ((100vw - 375px) / 1065));
                }
                @include media-breakpoint-up(xl-1440) {
                  padding-right:20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.search-wrapper {
  position:relative;
  float:right;
  width:100%;

  @include media-breakpoint-up(lg) {
    width:50%;
  }

  form {
    position:relative;
    z-index:2;

    fieldset {
      position:relative;
      border-radius:6px;
      background-color:color(white);
      @include prefix(box-shadow, 0px 4px 20px 0px rgba(0,0,0,0.1), webkit moz o ms);

      @include media-breakpoint-up(lg) {
        @include prefix(box-shadow, 0px 4px 20px 0px rgba(0,0,0,0.25), webkit moz o ms);
      }

      &:before {
        content:none;
        position:absolute; left:14px; top:50%;
        width:20px; height:20px;
        font-size:20px; line-height:20px; color:color(dark); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
        z-index:2;

        @include media-breakpoint-up(xs-480) {
          content:$icon-mendrisio-search;
        }
        @include media-breakpoint-up(lg) {
          left:calc(14px + 14 * ((100vw - 992px) / 448));
          width:calc(20px + 5 * ((100vw - 992px) / 448)); height:calc(20px + 5 * ((100vw - 992px) / 448));
          font-size:calc(20px + 5 * ((100vw - 992px) / 448)); line-height:calc(20px + 5 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          left:28px;
          width:25px; height:25px;
          font-size:25px; line-height:25px;
        }
      }
      .btn-form {
        position:absolute; right:10px; top:50%;
        width:40px; height:50px;
        border:0 none; border-radius:4px;
        background-color:color(red);
        @include prefix(transform, translateY(-50%), webkit moz o ms);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
        z-index:2;

        @include media-breakpoint-up(xs-360) {
          width:46px;
        }
        @include media-breakpoint-up(xs-480) {
          width:66px;
        }
        @include media-breakpoint-up(lg) {
          right:calc(10px + 3 * ((100vw - 992px) / 448));
          width:calc(66px + 10 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          right:13px;
          width:76px;
        }

        i {
          position:absolute; left:50%; top:50%;
          width:14px; height:14px;
          font-size:14px; line-height:14px; color:color(white);
          @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
        }
        @include media-breakpoint-up(lg) {
          &:hover {
            width:90px;
            background-color:color(dark);
          }
        }
      }
      input {
        padding:24px 60px 24px 16px;
        font-size:16px; line-height:22px; color:color(dark);
        z-index:1;

        @include media-breakpoint-up(xs-360) {
          padding:24px 66px 24px 18px;
        }
        @include media-breakpoint-up(xs-480) {
          padding:24px calc(88px + 40 * ((100vw - 480px) / 960)) 24px calc(46px + 22 * ((100vw - 480px) / 960));
        }
        @include media-breakpoint-up(lg) {
          font-size:17px;
        }
        @include media-breakpoint-up(xl-1440) {
          padding:24px 128px 24px 68px;
        }

        &::-webkit-input-placeholder {
          color:color(dark); font-size:12px;

          @include media-breakpoint-up(xs-360) {
            font-size:14px;
          }
          @include media-breakpoint-up(xs-480) {
            font-size:16px;
          }
          @include media-breakpoint-up(lg) {
            font-size:17px;
          }
        }
        &::-moz-placeholder {
          color:color(dark); font-size:12px;

          @include media-breakpoint-up(xs-360) {
            font-size:14px;
          }
          @include media-breakpoint-up(xs-480) {
            font-size:16px;
          }
          @include media-breakpoint-up(lg) {
            font-size:17px;
          }
        }
        &:-ms-input-placeholder {
          color:color(dark); font-size:12px;

          @include media-breakpoint-up(xs-360) {
            font-size:14px;
          }
          @include media-breakpoint-up(xs-480) {
            font-size:16px;
          }
          @include media-breakpoint-up(lg) {
            font-size:17px;
          }
        }
        &:-moz-placeholder {
          color:color(dark); font-size:12px;

          @include media-breakpoint-up(xs-360) {
            font-size:14px;
          }
          @include media-breakpoint-up(xs-480) {
            font-size:16px;
          }
          @include media-breakpoint-up(lg) {
            font-size:17px;
          }
        }
      }
    }
  }
  .search-layer {
    position:absolute; left:0; top:0;
    display:none;
    width:100%; height:auto;
    padding:86px 20px 24px 20px;
    border-radius:6px;
    background-color:color(white);
    @include prefix(box-shadow, 0px 4px 20px 0px rgba(0,0,0,0.06), webkit moz o ms);
    z-index:1;

    @include media-breakpoint-up(xs-375) {
      padding:calc(86px + 4 * ((100vw - 375px) / 1065)) calc(20px + 8 * ((100vw - 375px) / 1065)) calc(24px + 8 * ((100vw - 375px) / 1065)) calc(20px + 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding:90px 28px 32px 28px;
    }

    p {
      font-size:13px; line-height:20px; color:color(black);
      opacity:0.7;
    }
    ul {
      width:calc(100% + 8px);
      margin-right:-8px;

      @include media-breakpoint-up(xs-375) {
        width:calc(100% + (8px + 4 * ((100vw - 375px) / 1065)));
        margin-right:calc(-8px - 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        width:calc(100% + 12px);
        margin-right:-12px;
      }

      li {
        width:auto;
        margin-top:8px; margin-right:8px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(8px + 4 * ((100vw - 375px) / 1065)); margin-right:calc(8px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:12px; margin-right:12px;
        }

        a {
          position:relative;
          display:block; float:left;
          width:auto;
          padding:6px 14px;
          font-size:14px; line-height:20px; color:color(black); font-family:$font_frutiger_bold; font-weight:normal;

          @include media-breakpoint-up(xs-375) {
            padding:calc(6px + 1 * ((100vw - 375px) / 1065)) calc(14px + 9 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding:7px 23px;
          }

          &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:100%; height:100%;
            border:2px solid color(black);
            border-radius:6px;
            opacity:0.2;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              &:before {
                opacity:0.7;
              }
            }
          }
        }
      }
    }
  }
}

.search-section {
  overflow:visible;
  margin-top:-22px;
  z-index:3;

  @include media-breakpoint-up(lg) {
    margin-top:calc(-22px - 30 * ((100vw - 992px) / 448));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-52px;
  }

  @include media-breakpoint-up(md) {
    .search-wrapper {
      width:65%;
    }
  }
  @include media-breakpoint-up(lg) {
    .search-wrapper {
      width:50%;
    }
  }

  &.top {
    margin-top:-20px; padding-bottom:20px;
    background-color:color(grey);

    @include media-breakpoint-up(lg) {
      margin-top:calc(-20px - 78 * ((100vw - 992px) / 448)); padding-bottom:calc(20px + 6 * ((100vw - 992px) / 448));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:-98px; padding-bottom:26px;
    }
  }
}
