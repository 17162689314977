.alert-wrapper {
  position:relative;
  float:left;
  width:100%;
  padding:11px 0;
  background-color:color(black, 0.05);

  .container {
    .row {
      > div {
        position:relative;
        display:flex; justify-content:center; align-items:center;
        padding-left:44px; padding-right:44px;

        .close {
          position:absolute; right:8px; top:2px;
          width:24px; height:24px;
          z-index:2;

          @include media-breakpoint-up(xs-375) {
            right:calc(8px - 8 * ((100vw - 375px) / 617));
          }
          @include media-breakpoint-up(lg) {
            left:8px; right:inherit;
          }

          i {
            position:absolute; left:50%; top:50%;
            width:11px; height:11px;
            font-size:11px; line-height:11px; color:color(dark,0.7);
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(lg) {
              width:13px; height:13px;
              font-size:13px; line-height:13px;
            }
          }
          @include media-breakpoint-up(lg) {
            &:hover {
              i {
                color:color(black);
              }
            }
          }
        }
        .alert-btn {
          display:block; float:none;
          width:auto;
          font-size:0;

          span, p {
            display:inline-block; float:none;
            width:auto;
            font-family:$font_frutiger;
          }
          span {
            position:relative;
            padding:5px 7px 4px 7px;
            border-radius:3px;
            font-size:14px; line-height:19px; color:color(white);
            background-color:color(red);

            i {
              position:absolute; left:6px; top:8px;
              width:12px; height:12px;
              font-size:12px; line-height:12px; color:color(white);
            }
            &:has(> i) {
              padding:5px 7px 4px 20px;
            }
          }
          p {
            padding:5px 0 4px 7px;
            font-size:12px; line-height:16px; color:color(dark);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(xs-375) {
              font-size:calc(12px + 2 * ((100vw - 375px) / 1065)); line-height:calc(16px + 2 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              font-size:14px; line-height:19px;
            }

            i {
              display:inline-block; float:none;
              margin-left:10px;
              font-size:9px; line-height:19px; color:color(dark);
              @include prefix(transition, all .3s ease-out, webkit moz o ms);

              @include media-breakpoint-up(lg) {
                font-size:11px;
              }
            }
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              p {
                color:color(red);

                i {
                  color:color(red);
                }
              }
            }
          }
        }
      }
    }
  }
}
