.prefooter {
  margin-top:60px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(60px + 56 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:116px;
  }

  p {
    margin-top:4px;
    font-size:15px; line-height:22px; color:color(dark); font-family:$font_frutiger; letter-spacing:0;
  }

  .newsletter-wrapper {
    position:relative;
    float:right;
    width:100%;
    margin-top:0;

    @include media-breakpoint-up(lg) {
      margin-top:4px;
    }

    form {
      position:relative;
      z-index:2;

      fieldset {
        position:relative;
        border-radius:6px;
        background-color:color(red);
        @include prefix(box-shadow, 0px 4px 8px 0px rgba(0,0,0,0.1), webkit moz o ms);

        &:before {
          content:$icon-mendrisio-email;
          position:absolute; left:20px; top:50%;
          width:20px; height:20px;
          font-size:20px; line-height:20px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          @include prefix(transform, translateY(-50%), webkit moz o ms);
          z-index:2;
        }
        .btn-form {
          position:absolute; right:16px; top:50%;
          width:38px; height:38px;
          border:0 none; border-radius:50%;
          background-color:color(white);
          @include prefix(transform, translateY(-50%), webkit moz o ms);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
          z-index:2;

          i {
            position:absolute; left:50%; top:50%;
            width:20px; height:20px;
            margin-left:2px;
            font-size:20px; line-height:20px; color:color(red);
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              width:48px;
              border-radius:6px;
            }
          }
        }
        input {
          padding:20px 84px 20px 54px;
          font-size:16px; line-height:20px; color:color(white);
          z-index:1;

          &::-webkit-input-placeholder { color:color(white); }
          &::-moz-placeholder { color:color(white); }
          &:-ms-input-placeholder { color:color(white); }
          &:-moz-placeholder { color:color(white); }
        }
      }
    }
    .search-layer {
      position:absolute; left:0; top:0;
      display:none;
      width:100%; height:auto;
      border-radius:6px;
      background-color:color(white);
      z-index:1;
    }
  }
}
