/* Prefixes */

@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
    }
    #{$property}: $value;
}

/* --- */

@font-face {
  font-family: 'frutiger_lt_com65_bold';
  src: url('../fonts/frutigerltcom-bold-webfont.woff2') format('woff2'),
       url('../fonts/frutigerltcom-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display:swap;
}

@font-face {
  font-family: 'frutiger_lt_com45_light';
  src: url('../fonts/frutigerltcom-light-webfont.woff2') format('woff2'),
       url('../fonts/frutigerltcom-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display:swap;
}

@font-face {
  font-family: 'frutiger_lt_com55_roman';
  src: url('../fonts/frutigerltcom-roman-webfont.woff2') format('woff2'),
       url('../fonts/frutigerltcom-roman-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display:swap;
}

$font_frutiger:'frutiger_lt_com55_roman', sans-serif;        // 400
$font_frutiger_light:'frutiger_lt_com45_light', sans-serif;  // 300
$font_frutiger_bold:'frutiger_lt_com65_bold', sans-serif;    // 700

.font-frutiger { font-family:$font_frutiger!important; }
.font-frutiger-light { font-family:$font_frutiger_light!important; }
.font-frutiger-bold { font-family:$font_frutiger_bold!important; }

html, body {
  color:color(dark);
  font-family:$font_frutiger;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  display:block; float:left;
  width:100%;
  margin-bottom:0;
  color:color(dark); letter-spacing:0;
  font-family:$font_frutiger_light;

  strong {
    font-family:$font_frutiger_bold; font-weight:normal;
  }
}

h1, .h1 {
  font-size:36px; line-height:36px; font-family:$font_frutiger_bold; color:color(red); font-weight:normal;

  @include media-breakpoint-up(xs-375) {
    font-size:calc(36px + 14 * ((100vw - 375px) / 1065)); line-height:calc(36px + 14 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:50px; line-height:50px;
  }
}
h2, .h2 {
  font-size:26px; line-height:34px;

  @include media-breakpoint-up(xs-375) {
    font-size:calc(26px + 6 * ((100vw - 375px) / 1065)); line-height:calc(34px + 8 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:32px; line-height:42px;
  }
}
h3, .h3 {
  font-size:18px; line-height:26px; font-family:$font_frutiger;

  @include media-breakpoint-up(xs-375) {
    font-size:calc(18px + 2 * ((100vw - 375px) / 1065)); line-height:calc(26px + 2 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:20px; line-height:28px;
  }
}
h4, .h4 {
  font-size:15px; line-height:24px; font-family:$font_frutiger;
}
h5, .h5 {
  font-size:18px; line-height:28px; color:color(red); font-family:$font_frutiger;
}
h6, .h6 {
  font-size:14px; line-height:20px; font-family:$font_frutiger; text-transform:uppercase;
}

p {
  display:block; float:left;
  width:100%;
  margin-bottom:0;
  font-size:16px; line-height:26px; color:color(dark); font-family:$font_frutiger_light; letter-spacing:0.02em;

  @include media-breakpoint-up(xs-375) {
    font-size:calc(16px + 2 * ((100vw - 375px) / 1065)); line-height:calc(26px + 2 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:18px; line-height:28px;
  }

  strong {
    font-family:$font_frutiger_bold; font-weight:normal;
  }
  a {
    color:color(red);
    text-decoration:underline!important;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    @include media-breakpoint-up(lg) {
      &:hover {
        color:color(dark);
      }
    }
  }
}

a {
  &.phone {
    text-decoration:none!important; color:color(dark)!important;

    @include media-breakpoint-up(lg) {
      cursor:default;
      pointer-events:none;
    }
  }
  p {
    letter-spacing:0; font-family:$font_frutiger;
  }
}
