.services-list {
  margin-top:-14px;

  > div {
    margin-top:14px;
  }
}

.services-slider {
  float:left;
  width:calc(100% + 20px);
  margin-left:-10px; margin-right:-10px; margin-top:-25px;

  .swiper {
    float:left;
    width:100%;
    overflow:visible;

    @include media-breakpoint-up(lg) {
      overflow:hidden;
    }

    .swiper-slide {
      padding:30px 10px;
      margin-bottom:-30px;

      .service-btn {
        margin-top:10px;

        &:first-child {
          margin-top:0;
        }
      }
    }
  }
}

.services-block {
  display:block; float:left;
  width:100%;
  margin-top:34px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(34px + 30 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:64px;
  }

  > h3, > .h3 {
    font-size:20px; line-height:26px; color:color(red);

    @include media-breakpoint-up(xs-375) {
      font-size:calc(20px + 4 * ((100vw - 375px) / 1065)); line-height:calc(26px + 2 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      font-size:24px; line-height:28px;
    }
  }

  .services-list {
    margin-top:0;

    > div {
      margin-top:14px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(14px + 16 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:30px;
      }
    }
  }
}

.services-block-wrapper {
  > .row {
    &:first-child {
      .services-block {
        margin-top:0;
      }
    }
  }
}

.service-btn {
  position:relative;
  display:flex; align-items:center;
  width:100%; height:100%;
  padding:30px 48px 30px 106px;
  border-radius:0 6px 6px 0;
  background-color:color(white);
  @include prefix(box-shadow, 0px 4px 40px 0px rgba(0,0,0,0.06), webkit moz o ms);
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  @include media-breakpoint-up(xs-375) {
    padding:calc(30px + 14 * ((100vw - 375px) / 1065)) 48px calc(30px + 14 * ((100vw - 375px) / 1065)) 106px;
  }
  @include media-breakpoint-up(xl-1440) {
    padding:44px 48px 44px 106px;
  }

  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:3px; height:100%;
    background-color:color(red);
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
    z-index:1;
  }
  &:after {
    content:'';
    position:absolute; right:0; top:0;
    width:100%; height:100%;
    border-radius:6px;
    border:3px solid color(red);
    opacity:0;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
    z-index:2;
  }
  picture {
    position:absolute; left:30px; top:50%;
    width:52px; height:auto;
    @include prefix(transform, translateY(-50%), webkit moz o ms);
    z-index:2;
  }
  > div {
    position:relative;
    width:100%;

    h3, .h3 {
      width:100%;
      font-size:16px; line-height:22px; font-family:$font_frutiger_bold; font-weight:normal;

      @include media-breakpoint-up(xs-375) {
        font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(22px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        font-size:20px; line-height:26px;
      }
    }
    h4, .h4 {
      width:100%;
      font-size:16px; line-height:20px; font-family:$font_frutiger_bold; font-weight:normal; letter-spacing:0.02em;
    }
    p {
      display:none;
      margin-top:4px;
      font-size:15px; line-height:22px; color:color(dark-grey);

      @include media-breakpoint-up(xl) {
        display:block;
      }
    }

    &:after {
      content:$icon-mendrisio-chevron-right;
      position:absolute; right:-30px; top:50%;
      width:13px; height:13px;
      font-size:13px; line-height:13px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
      @include prefix(transform, translateY(-50%), webkit moz o ms);
      z-index:1;
    }

    > span {
      position:absolute; left:0; top:0;
      padding:2px 3px 2px 3px; border-radius:4px;
      margin-top:-5px;
      font-size:12px; line-height:14px; color:color(dark); font-family:$font_frutiger; letter-spacing:0.07em; text-transform:uppercase;
      background-color:color(grey);
      @include prefix(transform, translate(-50%,-100%), webkit moz o ms);
      z-index:3;
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      border-radius:6px;

      &:before {
        border-radius:6px;
        opacity:0;
      }
      &:after {
        opacity:1;
      }
    }
  }

  &.light {
    padding:28px 48px 28px 36px;
    border-radius:6px;
    @include prefix(box-shadow, none, webkit moz o ms);
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    @include media-breakpoint-up(xs-375) {
      padding:calc(28px + 4 * ((100vw - 375px) / 1065)) 48px calc(28px + 4 * ((100vw - 375px) / 1065)) calc(36px + 30 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding:32px 48px 32px 66px;
    }

    &:before {
      content:'';
      position:absolute; right:0; top:0;
      width:100%; height:100%;
      border-radius:6px;
      border:1px solid color(med-grey);
      background-color:transparent;
      z-index:1;
    }
  }
}

.swiper-pagination {
  position:relative; left:inherit!important; bottom:inherit!important;
  float:left;
  font-size:0;
  margin-top:22px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(22px + 2 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:24px;
  }

  .swiper-pagination-bullet {
    position:relative;
    width:16px; height:16px;
    margin:0 3px;
    background-color:transparent;
    opacity:1;

    &:before {
      content:'';
      position:absolute; left:50%; top:50%;
      width:8px; height:8px;
      border:2px solid color(med-grey); border-radius:50%;
      background-color:color(med-grey);
      @include prefix(transition, all .2s ease-out, webkit moz o ms);
      @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
    }

    &.swiper-pagination-bullet-active {
      &:before {
        width:100%!important; height:100%!important;
        border:2px solid color(red);
        background-color:transparent;
      }
    }
    @include media-breakpoint-up(xl-1440) {
      &:hover {
        &:before {
          width:12px; height:12px;
        }
      }
    }
  }
}

.swiper-button-prev, .swiper-button-next {
  top:50%;
  width:44px; height:44px;
  margin:0; border-radius:50%;
  background-color:color(white);
  @include prefix(filter, drop-shadow(0px 5px 10px #00000040), webkit moz o ms);
  @include prefix(transform, translateY(-50%), webkit moz o ms);

  &:after {
    display:none;
  }
  &:before {
    position:absolute; left:50%; top:50%;
    width:14px; height:14px;
    font-size:14px; line-height:14px; color:color(black); font-family:'icomoon'!important; font-weight:bold; font-style:normal;
    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
  }
}
.swiper-button-prev {
  left:28px;

  &:before {
    content:$icon-mendrisio-chevron-left;
    margin-left:-1px;
  }
}
.swiper-button-next {
  right:28px;

  &:before {
    content:$icon-mendrisio-chevron-right;
    margin-right:-1px;
  }
}

.information-slider {
  float:left;
  width:calc(100% + 20px);
  margin-left:-10px; margin-right:-10px;

  @include media-breakpoint-up(lg) {
    width:calc(100% + 16px);
    margin-left:-8px; margin-right:-8px;
  }

  .swiper {
    float:left;
    width:100%;
    filter:drop-shadow(0px 0px 30px rgba(0,0,0,0.1));
    overflow:visible;

    @include media-breakpoint-up(lg) {
      overflow:hidden;
    }

    .swiper-slide {
      height:auto;
      padding-left:10px; padding-right:10px;

      @include media-breakpoint-up(lg) {
        padding-left:8px; padding-right:8px;
      }
    }

    .information {
      display:block; float:left;
      width:100%; height:100%;

      a:not(.download) {
        position:relative;
        display:block; float:left;
        width:100%; height:100%;
        padding:30px 20px 65px 20px;
        background-color:color(white);
        z-index:1;

        @include media-breakpoint-up(sm) {
          padding:calc(30px + 5 * ((100vw - 576px) / 864)) calc(20px + 65 * ((100vw - 576px) / 864)) calc(65px + 60 * ((100vw - 576px) / 864)) calc(20px + 30 * ((100vw - 576px) / 864));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:35px 85px 125px 50px;
        }

        .head {
          display:flex; flex-direction:column-reverse; align-items:flex-end;
          width:100%;

          @include media-breakpoint-up(sm) {
            flex-direction:row; align-items:flex-start;
          }

          time {
            display:block; float:left;
            width:100%;
            margin-bottom:8px;
            font-size:12px; line-height:22px; color:color(dark-grey); font-family:$font_frutiger;
          }
          h3, .h3 {
            color:color(red);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }
          h4, .h4 {
            margin-top:12px;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(12px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:16px;
            }
          }
          picture {
            width:64.5%;
            margin:-30px -20px -14px -25px;
            flex-shrink:0;

            @include media-breakpoint-up(sm) {
              width:50.5%;
              margin:calc(-30px - 5 * ((100vw - 576px) / 864)) calc(-20px - 65 * ((100vw - 576px) / 864)) 0 calc(24px + 10 * ((100vw - 576px) / 864));
            }
            @include media-breakpoint-up(xl-1440) {
              margin:-35px -85px 0 34px;
            }

            img {
              border-radius:6px;
            }
          }
        }
        .content {
          float:left;
          width:100%;
          margin-top:24px;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(24px + 18 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:42px;
          }

          p {
            font-size:13px; line-height:20px; color:color(dark-grey); font-family:$font_frutiger;
          }
          .simplebar-wrapper {
            max-height:140px;

            @include media-breakpoint-up(md) {
              max-height:none;
            }
          }
          .icon-mendrisio-chevron-right {
            position:absolute; right:16px; bottom:30px;
            float:right;
            width:13px; height:13px;
            font-size:13px; line-height:13px; color:color(red);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(xs-375) {
              bottom:calc(30px + 32 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(md) {
              right:36px;
            }
            @include media-breakpoint-up(xl-1440) {
              bottom:62px; right:46px;
            }
          }
          .download {
            position:relative;
            display:block; float:left;
            width:auto;
            margin-top:42px; padding:5px 0 5px 42px;
            font-size:14px; line-height:22px; color:color(red); font-family:$font_frutiger_bold; font-weight:normal;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(xs-375) {
              font-size:calc(14px + 1 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              font-size:15px;
            }

            i {
              position:absolute; left:0; top:0;
              width:32px; height:32px;
              border-radius:50%;
              font-size:18px; line-height:32px; text-align:center; color:color(white);
              background-color:color(red);
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }
          }
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            .head {
              h3, .h3 {
                color:color(dark);
              }
            }
            .content {
              .icon-mendrisio-chevron-right {
                color:color(dark);
              }
              .download {
                color:color(dark);

                i {
                  background-color:color(dark);
                }
              }
            }
          }
        }
      }

      &.alt {
        a:not(.download) {
          padding-right:20px;

          @include media-breakpoint-up(xs-375) {
            padding-right:calc(20px + 55 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding-right:75px;
          }

          .content {
            margin-top:12px;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(12px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:16px;
            }
            .simplebar-wrapper {
              max-height:100px;

              @include media-breakpoint-up(md) {
                max-height:none;
              }
            }
          }
        }

        a.download {
          position:absolute; left:30px; bottom:20px;
          display:block; float:left;
          width:auto;
          padding:5px 0 5px 36px;
          font-size:14px; line-height:22px; color:color(red); font-family:$font_frutiger_bold; font-weight:normal;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
          z-index:2;

          @include media-breakpoint-up(xs-375) {
            left:calc(30px + 28 * ((100vw - 375px) / 1065)); bottom:calc(20px + 35 * ((100vw - 375px) / 1065));
            padding:5px 0 5px calc(36px + 6 * ((100vw - 375px) / 1065));
            font-size:calc(14px + 1 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            left:58px; bottom:55px;
            padding:5px 0 5px 42px;
            font-size:15px;
          }

          i {
            position:absolute; left:0; top:0;
            width:28px; height:28px;
            border-radius:50%;
            font-size:14px; line-height:28px; text-align:center; color:color(white);
            background-color:color(red);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(xs-375) {
              width:calc(28px + 4 * ((100vw - 375px) / 1065)); height:calc(28px + 4 * ((100vw - 375px) / 1065));
              font-size:calc(14px + 4 * ((100vw - 375px) / 1065)); line-height:calc(28px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              width:32px; height:32px;
              font-size:18px; line-height:32px;
            }
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(dark);

              i {
                background-color:color(dark);
              }
            }
          }
        }
      }
    }
  }
}

.news-slider, .project-slider {
  float:left;
  width:calc(100% + 20px);
  margin-left:-10px; margin-right:-10px;

  @include media-breakpoint-up(lg) {
    width:calc(100% + 28px);
    margin-left:-14px; margin-right:-14px;
  }

  .swiper {
    float:left;
    width:100%;
    overflow:visible;

    @include media-breakpoint-up(lg) {
      overflow:hidden;
    }

    .swiper-slide {
      padding-left:10px; padding-right:10px;

      @include media-breakpoint-up(lg) {
        padding-left:14px; padding-right:14px;
      }
    }
  }
}

.news-list, .project-list {
  > div {
    position:relative;
    padding-top:26px; padding-bottom:26px;

    @include media-breakpoint-up(xs-375) {
      padding-top:calc(26px + 30 * ((100vw - 375px) / 1065)); padding-bottom:calc(26px + 30 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-top:56px; padding-bottom:56px;
    }

    @include media-breakpoint-down(sm) {
      &:nth-child(even) {
        &:before {
          content:'';
          position:absolute; left:50%; top:0;
          width:calc(200vw + 10px); height:100%;
          background-color:color(grey);
          z-index:1;
          @include prefix(transform, translateX(-50%), webkit moz o ms);
        }
      }
    }
    @include media-breakpoint-between(sm, lg) {
      &:nth-child(4n + 3) {
        &:before {
          content:'';
          position:absolute; left:50%; top:0;
          width:calc(200vw + 10px); height:100%;
          background-color:color(grey);
          z-index:1;
          @include prefix(transform, translateX(-50%), webkit moz o ms);
        }
      }
    }
    @include media-breakpoint-up(lg) {
      &:nth-child(6n + 4) {
        &:before {
          content:'';
          position:absolute; left:50%; top:0;
          width:calc(200vw + 10px); height:100%;
          background-color:color(grey);
          z-index:1;
          @include prefix(transform, translateX(-50%), webkit moz o ms);
        }
      }
    }

    .news, .project {
      position:relative;
      z-index:2;
    }
  }

  &.no-pag {
    margin-bottom:-26px;

    @include media-breakpoint-up(xs-375) {
      margin-bottom:calc(-26px - 30 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:-56px;
    }
  }
  &.no-margin {
    margin-top:-30px;

    > div {
      padding-top:30px; padding-bottom:0;
    }
  }
  &.no-band {
    > div {
      &:before {
        content:none!important;
      }
    }
  }
  &.no-grid {
    float:left;
    margin-right:-14px; margin-left:-14px;

    > div {
      float:left;
      width:100%;
      padding-right:14px; padding-left:14px;

      @include media-breakpoint-up(sm) {
        width:50%;
      }
      @include media-breakpoint-up(lg) {
        width:44.44%;
      }
    }
  }
}

.news, .project {
  display:block; float:left;
  width:100%;

  a {
    position:relative;
    display:block; float:left;
    width:100%;

    > span {
      position:absolute; left:16px; top:16px;
      padding:5px 13px 4px 13px; border-radius:5px;
      font-size:11px; line-height:18px; color:color(white); font-family:$font_frutiger; letter-spacing:0.1em; text-transform:uppercase;
      background-color:color(dark);
      z-index:3;

      @include media-breakpoint-up(xs-375) {
        left:calc(16px + 6 * ((100vw - 375px) / 1065)); top:calc(16px + 6 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        left:22px; top:22px;
      }
    }

    > span + picture {
      &:after {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        background:rgb(0,0,0);
        background:linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
      }
    }

    picture {
      position:relative;
      border-radius:6px;
      overflow:hidden;

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        background-color:color(dark);
        opacity:0;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }
    }
    .content {
      float:left;
      width:100%;
      margin-top:20px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(20px + 14 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:34px;
      }

      h3, .h3 {
        position:relative;
        padding-right:28px;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          padding-right:calc(28px + 10 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-right:38px;
        }

        &:after {
          content:$icon-mendrisio-chevron-right;
          position:absolute; right:8px; top:6px;
          width:13px; height:13px;
          font-size:13px; line-height:13px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal;

          @include media-breakpoint-up(xs-375) {
            right:calc(8px + 4 * ((100vw - 375px) / 1065)); top:calc(6px + 1 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            right:12px; top:7px;
          }
        }
      }
      p {
        margin-top:12px;
        font-size:15px; line-height:24px; color:color(dark-grey); font-family:$font_frutiger;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(12px + 8 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:20px;
        }
      }
      .status {
        position:relative;
        display:block; float:left;
        width:100%;
        padding-left:17px; margin-top:10px;
        font-size:13px; line-height:21px; color:color(dark-grey); letter-spacing:0.02em; font-family:$font_frutiger_bold;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(10px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:12px;
        }

        &:before {
          content:'';
          position:absolute; left:0; top:7px;
          width:7px; height:7px;
          border-radius:50%;
          background-color:color(red);
        }
      }
    }
    time {
      display:block; float:left;
      width:100%;
      margin-top:20px;
      font-size:13px; line-height:24px; color:color(dark); font-family:$font_frutiger_light; letter-spacing:0.02em;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(20px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:24px;
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        picture {
          &:before {
            opacity:0.4;
          }
        }
        .content {
          h3, .h3 {
            color:color(red);
          }
        }
      }
    }
  }
}

.events-slider {
  float:left;
  width:calc(100% + 40px);
  margin:-26px -20px 0 -20px;

  @include media-breakpoint-up(md) {
    width:100%;
    margin:0;
  }

  .swiper {
    float:left;
    width:100%;
    padding-top:14px;
    @include prefix(filter, drop-shadow(0px 5px 10px rgba(0,0,0,0.1)), webkit moz o ms);

    @include media-breakpoint-up(md) {
      padding-top:46px;
    }

    .swiper-slide {
      position:relative;
      height:auto;
      padding-top:12px;
      padding-left:52px; padding-right:20px;

      @include media-breakpoint-up(md) {
        padding-left:24px; padding-right:24px;
      }
      @include media-breakpoint-up(xl) {
        padding-left:0; padding-right:0;
      }

      .event {
        a {
          time {
            left:-24px;

            @include media-breakpoint-up(xl) {
              left:0;
            }
          }
        }
      }

      &:nth-child(odd) {
        @include media-breakpoint-up(xl) {
          padding-left:24px;

          .event {
            border-top-left-radius:6px; border-bottom-left-radius:6px;

            a {
              time {
                left:-24px;
              }
            }
          }
        }
      }
      &:nth-child(even) {
        @include media-breakpoint-up(xl) {
          padding-right:24px;

          .event {
            border-top-right-radius:6px; border-bottom-right-radius:6px;
          }
        }

        &:before {
          content:none;
          position:absolute; left:0; top:50%;
          width:1px; height:calc(100% - 160px);
          background-color:color(dark-grey);
          @include prefix(transform, translateY(-50%), webkit moz o ms);
          opacity:0.2;
          z-index:3;

          @include media-breakpoint-up(xl) {
            content:'';
            height:calc(100% - calc(160px + 40 * ((100vw - 1200px) / 240)));
          }
          @include media-breakpoint-up(xl-1440) {
            height:calc(100% - 200px);
          }
        }
      }
      &.swiper-slide-blank {
        position:relative;

        @include media-breakpoint-up(xl) {
          &:after {
            content:'';
            position:absolute; right:0; bottom:0;
            width:calc(100% + 1px); height:calc(100% - 12px);
            background-color:color(white);
            border-top-right-radius:6px; border-bottom-right-radius:6px;
          }
        }
      }
    }

    .event-group {
      position:relative;
      float:left;
      width:100%; height:100%;
      border-radius:6px;
      @include prefix(filter, drop-shadow(0px 5px 10px rgba(0,0,0,0.1)), webkit moz o ms);

      &:before {
        content:none;
        position:absolute; left:50%; top:50%;
        width:1px; height:calc(100% - 160px);
        background-color:color(dark-grey);
        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
        opacity:0.2;
        z-index:3;

        @include media-breakpoint-up(md) {
          content:'';
          height:calc(100% - calc(160px + 40 * ((100vw - 768px) / 672)));
        }
        @include media-breakpoint-up(xl-1440) {
          height:calc(100% - 200px);
        }
      }
    }
  }
}

.events-box-slider, .events-box-slider-alt, .events-box-slider-vr {
  float:left;
  width:calc(100% + 40px);
  margin:0 -20px 0 -20px;

  .swiper {
    float:left;
    width:100%;
    padding-top:14px; padding-bottom:22px; padding-left:20px;

    @include media-breakpoint-up(xs-375) {
      padding-top:calc(14px + 32 * ((100vw - 375px) / 1065)); padding-bottom:calc(22px + 2 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(md) {
      padding-left:0;
    }
    @include media-breakpoint-up(xl-1440) {
      padding-top:46px; padding-bottom:24px;
    }

    .swiper-slide {
      position:relative;
      height:auto;
      padding-left:20px; padding-right:20px;

      .event {
        @include prefix(filter, none, webkit moz o ms);
      }

      &.swiper-slide-active, &.swiper-slide-next {
        @include prefix(filter, drop-shadow(0px 4px 10px rgba(0,0,0,0.06)), webkit moz o ms);
      }
    }
  }

  .swiper-pagination {
    margin-top:0;
  }
}

.events-box-slider-alt, .events-box-slider-vr {
  width:calc(100% + 28px);
  margin:-14px -14px 0 -14px;

  .swiper {
    padding-left:0;

    .swiper-slide {
      padding-left:14px; padding-right:14px;
      @include prefix(filter, drop-shadow(0px 4px 10px rgba(0,0,0,0.06)), webkit moz o ms);
    }
  }
}

.events-list {
  margin-top:0;

  @include media-breakpoint-up(xs-375) {
    margin-right:-6px!important; margin-left:-6px!important;
  }
  @include media-breakpoint-up(md) {
    margin-top:calc(-12px + 24 * ((100vw - 768px) / 672));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:12px;
  }
  > div {
    margin-top:12px;
    padding-left:36px!important;

    @include media-breakpoint-up(md) {
      margin-top:calc(40px + 45 * ((100vw - 768px) / 672));
      padding-right:20px!important; padding-left:20px!important;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:85px;
    }
  }
}
.events-list-vr {
  margin-top:0;

  @include media-breakpoint-up(md) {
    margin-top:calc(0px + 6 * ((100vw - 768px) / 672));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:6px;
  }
  > div {
    margin-top:12px;

    @include media-breakpoint-up(md) {
      margin-top:calc(18px + 10 * ((100vw - 768px) / 672));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:28px;
    }
  }
}

.events-list, .events-box-slider, .events-box-slider-alt, .events-box-slider-vr, .events-list-vr {
  .event {
    border-radius:6px;
    @include prefix(filter, drop-shadow(0px 4px 30px rgba(0,0,0,0.06)), webkit moz o ms);

    a {
      padding:18px 20px 28px 68px;

      @include media-breakpoint-up(md) {
        padding:24px 26px 28px 26px;
      }

      > time {
        left:-32px; top:18px;
        width:65px; height:65px;

        strong {
          margin-top:2px;
          font-size:26px;
        }

        @include media-breakpoint-up(xs-375) {
          width:calc(65px + 20 * ((100vw - 375px) / 1065)); height:calc(65px + 20 * ((100vw - 375px) / 1065));

          strong {
            margin-top:calc(2px + 2 * ((100vw - 375px) / 1065));
            font-size:calc(26px + 8 * ((100vw - 375px) / 1065));
          }
        }
        @include media-breakpoint-up(md) {
          left:-14px; top:calc(-22px - 20 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl-1440) {
          top:-42px;
          width:85px; height:85px;

          strong {
            margin-top:4px;
            font-size:34px;
          }
        }
      }
      > span {
        position:relative;
        padding:7px 12px;
        margin-bottom:16px; margin-left:auto;
        font-size:11px; line-height:14px; color:color(dark); font-family:$font_frutiger_bold; text-transform:uppercase; letter-spacing:1.1px; font-weight:normal;

        @include media-breakpoint-up(xs-375) {
          margin-bottom:calc(16px + 14 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-bottom:30px;
        }
      }
      h3, .h3 {
        font-size:18px; line-height:28px;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(18px + 6 * ((100vw - 375px) / 1065)); line-height:calc(28px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:24px; line-height:30px;
        }
      }
      p {
        &.note {
          margin-top:10px;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(10px + 8 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:18px;
          }
        }
      }
    }
  }
}

.events-box-slider-alt, .events-box-slider-vr, .events-list-vr {
  .event {
    border-radius:6px;
    @include prefix(filter, drop-shadow(0px 4px 30px rgba(0,0,0,0.06)), webkit moz o ms);

    a {
      padding:18px 18px 28px 28px;

      @include media-breakpoint-up(md) {
        padding:24px 22px 28px 22px;
      }
      > span {
        margin-bottom:18px; margin-left:auto;

        @include media-breakpoint-up(xs-375) {
          margin-bottom:calc(18px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(md) {
          margin-left:0;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-bottom:20px;
        }
      }
      .content {
        float:left;
        width:100%;
      }
      h3, .h3 {
        font-size:18px; line-height:26px;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(18px + 2 * ((100vw - 375px) / 1065)); line-height:calc(26px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:20px; line-height:28px;
        }
      }
      p {
        margin-top:14px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(14px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:18px;
        }
        &.note {
          margin-top:14px;
          font-family:$font_frutiger_light;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(14px + 8 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:22px;
          }
        }
      }
      .info {
        padding-top:6px;

        span {
          text-align:left;
          margin-top:14px;

          @include media-breakpoint-up(xl) {
            &:first-child {
              margin-right:10px;
            }
            &:last-child {
              margin-left:10px;
            }
          }
        }
      }
    }
  }
}

.events-box-slider-vr {
  margin:-24px -14px 0 -14px;

  .swiper {
    padding-top:14px;

    @include media-breakpoint-up(xs-375) {
      padding-top:calc(14px + 26 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-top:40px;
    }
  }
}
.events-box-slider-vr, .events-list-vr {
  .event {
    a {
      padding:20px 20px 36px 20px;
      text-align:left;

      .label {
        float:left;
        width:100%;
        margin-top:-5px;

        > span {
          position:relative;
          display:block; float:left;
          width:auto;
          padding:7px 12px;
          margin:5px 5px 0 0;
          font-size:11px; line-height:14px; color:color(dark); font-family:$font_frutiger; text-transform:uppercase; letter-spacing:1.1px; font-weight:normal;
    
          &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:100%; height:100%;
            border:2px solid color(dark-grey,0.3); border-radius:5px;
          }
        }
      }
      picture {
        margin-top:20px;
      }
      .content {
        float:left;
        width:100%;
        margin-top:20px; margin-bottom:25px;

        .location {
          position:relative;
          display:block; float:left;
          width:auto;
          padding-left:23px; margin-bottom:20px;
          font-size:15px; line-height:24px; color:color(black); font-family:$font_frutiger;
  
          i {
            position:absolute; left:0; top:4px;
            width:16px; height:16px;
            font-size:16px; line-height:16px; color:color(red);
          }
        }
      }
      h3, .h3 {
        font-size:18px; line-height:24px;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(18px + 6 * ((100vw - 375px) / 1065)); line-height:calc(24px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:24px; line-height:28px;
        }
      }
      p {
        display:block;
        margin-top:10px;
      }
      .info {
        flex-direction:column; align-items:flex-start; justify-content:flex-start;
        margin-top:0;
        padding-top:0;

        @include media-breakpoint-up(sm) {
          margin-top:auto;
        }

        span {
          margin:15px 0 0 0!important;
          font-size:15px; line-height:20px; color:color(dark);
  
          i {
            top:3px;
          }
        }
        hr {
          display:block; float:left;
          width:100%; height:2px;
          margin:15px 0 0 0; padding:0;
          background-color:color(grey);
          opacity:1;
        }
      }
    }
  }
}

.event {
  position:relative;
  display:block; float:left;
  width:100%; height:100%;
  z-index:2;
  border-radius:6px;
  background-color:color(white);

  @include media-breakpoint-up(xl) {
    border-radius:0;
  }

  a {
    position:relative;
    display:flex; flex-direction:column; align-items:center; justify-content:flex-start;
    width:100%; height:100%;
    padding:18px 20px 28px 68px;
    text-align:left; font-size:0;

    @include media-breakpoint-up(md) {
      text-align:center;
      padding:24px calc(30px + 6 * ((100vw - 768px) / 672)) calc(24px + 4 * ((100vw - 768px) / 672)) calc(30px + 6 * ((100vw - 768px) / 672));
    }
    @include media-breakpoint-up(xl-1440) {
      padding:24px 36px 28px 36px;
    }

    > time {
      position:absolute; left:-32px; top:18px;
      display:flex; flex-direction:column; justify-content:center; align-items:center;
      width:65px; height:65px;
      border-radius:4px;
      font-size:12px; line-height:21px; color:color(white); font-family:$font_frutiger_light; text-transform:uppercase;
      background-color:color(red);

      strong {
        margin-top:3px;
        font-size:26px; line-height:24px; font-family:$font_frutiger; font-weight:normal;
      }

      @include media-breakpoint-up(xs-375) {
        width:calc(65px + 30 * ((100vw - 375px) / 1065)); height:calc(65px + 30 * ((100vw - 375px) / 1065));
        border-radius:calc(4px + 2 * ((100vw - 375px) / 1065));
        font-size:calc(12px + 4 * ((100vw - 375px) / 1065)); line-height:calc(21px + 4 * ((100vw - 375px) / 1065));

        strong {
          margin-top:calc(3px + 3 * ((100vw - 375px) / 1065));
          font-size:calc(26px + 12 * ((100vw - 375px) / 1065)); line-height:calc(24px + 6 * ((100vw - 375px) / 1065));
        }
      }
      @include media-breakpoint-up(md) {
        left:0; top:-44px;
      }
      @include media-breakpoint-up(xl-1440) {
        width:95px; height:95px;
        border-radius:6px;
        font-size:16px; line-height:25px;

        strong {
          margin-top:6px;
          font-size:38px; line-height:30px; font-family:$font_frutiger; font-weight:normal;
        }
      }
    }
    > span {
      position:relative;
      padding:7px 12px;
      margin-bottom:16px; margin-left:auto;
      font-size:11px; line-height:14px; color:color(dark); font-family:$font_frutiger_bold; text-transform:uppercase; letter-spacing:1.1px; font-weight:normal;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(16px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(md) {
        margin-left:0;
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:18px;
      }

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        border:2px solid color(dark-grey,0.3); border-radius:5px;
      }
    }
    h3, .h3 {
      color:color(red);
      @include prefix(transition, all .3s ease-out, webkit moz o ms);
    }
    p {
      display:none;
      margin-top:17px;
      font-size:16px; line-height:24px; color:color(dark-grey); font-family:$font_frutiger;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(17px + 10 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(md) {
        display:block;
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:27px;
      }

      &.note {
        display:block;
        margin-top:13px;
        font-size:15px; color:color(dark); font-style:italic;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(13px + 28 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:41px;
        }
      }
    }
    .info {
      display:flex; flex-direction:column; justify-content:flex-start; align-items:flex-start;
      width:100%;
      padding-top:17px; margin-top:auto;

      @include media-breakpoint-up(xs-375) {
        padding-top:calc(17px + 10 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl) {
        flex-direction:row; justify-content:space-between; align-items:center;
      }
      @include media-breakpoint-up(xl-1440) {
        padding-top:27px;
      }

      span {
        position:relative;
        padding-left:22px; margin-top:10px;
        font-size:15px; line-height:24px; color:color(black); font-family:$font_frutiger_light;

        i {
          position:absolute; left:0; top:5px;
          width:14px; height:14px;
          font-size:14px; line-height:14px; color:color(red);
        }
      }
    }

    @include media-breakpoint-up(xl-1440) {
      &:hover {
        h3, .h3 {
          color:color(dark);
        }
      }
    }
  }
}

.social-slider {
  position:relative;
  float:left;
  width:calc(100% + 20px);
  margin-left:-10px; margin-right:-10px;

  @include media-breakpoint-up(lg) {
    width:calc(100% + 28px);
    margin-left:-14px; margin-right:-14px;
  }

  .swiper {
    float:left;
    width:100%;
    overflow:visible;

    @include media-breakpoint-up(lg) {
      overflow:hidden;
    }

    .swiper-wrapper {
      padding-left:10px; padding-right:10px;

      @include media-breakpoint-up(lg) {
        padding-left:0; padding-right:0;
      }
    }

    .swiper-slide {
      width:100%;
      padding-left:8px; padding-right:8px;

      @include media-breakpoint-up(lg) {
        width:calc(330px + 28 * ((100vw - 992px) / 448));
        padding-left:14px; padding-right:14px;
      }
      @include media-breakpoint-up(xl-1440) {
        width:358px;
      }

      .social {
        display:block; float:left;
        width:100%;

        a {
          display:block; float:left;
          width:100%;
          border-radius:7px;
          background-color:color(white);
          overflow:hidden;

          > div {
            display:flex; align-items:center;
            padding:10px 20px 10px 15px;

            @include media-breakpoint-up(xs-375) {
              padding:calc(10px + 4 * ((100vw - 375px) / 1065)) 20px calc(10px + 10 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              padding:14px 20px 20px 15px;
            }

            span {
              position:relative;
              display:block; float:left;
              width:46px; height:46px;
              margin-right:9px; margin-left:-10px;
              flex-shrink:0;

              @include media-breakpoint-up(xs-360) {
                margin-right:11px; margin-left:0;
              }

              img {
                position:absolute; left:50%; top:50%;
                width:20px;
                margin-top:2px;
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
              }
              &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                border:1px solid color(med-grey); border-radius:50%;
                opacity:0.6;
              }
            }
            p {
              font-size:12px; line-height:20px; color:color(dark); font-family:$font_frutiger_bold; font-weight:normal;

              @include media-breakpoint-up(xs-360) {
                font-size:14px;
              }
            }
            i {
              width:24px; height:24px;
              margin-left:9px;
              font-size:24px; line-height:24px; color:color(med-grey);

              @include media-breakpoint-up(xs-360) {
                margin-left:11px;
              }
            }
          }
          > picture {
            position:relative;
            overflow:hidden;

            &:before {
              content:'';
              position:absolute; left:0; top:0;
              width:100%; height:100%;
              background-color:color(dark);
              opacity:0;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
              z-index:1;
            }
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              picture {
                &:before {
                  opacity:0.4;
                }
              }
            }
          }
        }

        &.video {
          a {
            > picture {
              &:after {
                content:$icon-mendrisio-social-youtube;
                position:absolute; left:50%; top:50%;
                width:60px; height:60px;
                font-size:60px; line-height:60px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
                opacity:0.7;
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                z-index:2;
              }
            }

            @include media-breakpoint-up(lg) {
              &:hover {
                picture {
                  &:after {
                    opacity:0.8;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .swiper-pagination {
    @include media-breakpoint-up(lg) {
      display:none;
    }
  }
  .swiper-button-prev, .swiper-button-next {
    display:none;

    @include media-breakpoint-up(lg) {
      display:block;
    }
  }

  .swiper {
    &.blocked {
      .swiper-wrapper {
        transform:translate3d(50%, 0, 0)!important;
      }
      & + .swiper-button-prev {
        display:none!important;

        & + .swiper-button-next {
          display:none!important;
        }
      }
    }
  }

}

.social-box-wrapper {
  .row {
    margin-top:35px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(35px + 10 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:45px;
    }

    > div {
      margin-top:12px;

      .social-box {
        position:relative;
        display:block; float:left;
        width:100%; height:100%;
        padding:24px 66px 24px 86px;
        border-radius:10px;
        background-color:color(light-grey);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          padding:24px 66px 24px calc(86px + 20 * ((100vw - 375px) / 617));
        }
        @include media-breakpoint-up(lg) {
          padding:84px 74px 54px 42px;
        }

        span {
          position:absolute; left:16px; top:50%;
          width:54px; height:54px;
          @include prefix(transform, translateY(-50%), webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            left:calc(16px + 14 * ((100vw - 375px) / 617));
          }
          @include media-breakpoint-up(lg) {
            left:42px; top:14px;
            width:calc(54px + 6 * ((100vw - 992px) / 448)); height:calc(54px + 6 * ((100vw - 992px) / 448));
            @include prefix(transform, translateY(0%), webkit moz o ms);
          }
          @include media-breakpoint-up(xl-1440) {
            width:60px; height:60px;
          }

          i {
            position:absolute; left:50%; top:50%;
            width:20px; height:20px;
            font-size:20px; line-height:20px; color:color(dark);
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);

            @include media-breakpoint-up(lg) {
              width:calc(20px + 4 * ((100vw - 992px) / 448)); height:calc(20px + 4 * ((100vw - 992px) / 448));
              font-size:calc(20px + 4 * ((100vw - 992px) / 448)); line-height:calc(20px + 4 * ((100vw - 992px) / 448));
            }
            @include media-breakpoint-up(xl-1440) {
              width:24px; height:24px;
              font-size:24px; line-height:24px;
            }
          }
          &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:100%; height:100%;
            border:2px solid color(dark); border-radius:17px;
            opacity:0.5;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }
        }
        h3, .h3 {
          strong {
            color:color(dark);
          }
        }
        p {
          margin-top:6px;
          font-size:13px; line-height:18px; color:color(dark-grey);

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(6px + 2 * ((100vw - 375px) / 1065));
            font-size:calc(13px + 2 * ((100vw - 375px) / 1065)); line-height:calc(18px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:8px;
            font-size:15px; line-height:22px;
          }
        }
        &:after {
          content:$icon-mendrisio-arrow-external;
          position:absolute; right:20px; bottom:50%;
          width:32px; height:32px;
          border-radius:50%;
          font-size:15px; line-height:32px; text-align:center; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          background-color:color(red);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
          @include prefix(transform, translateY(50%), webkit moz o ms);

          @include media-breakpoint-up(lg) {
            bottom:18px;
            @include prefix(transform, translateY(0%), webkit moz o ms);
          }
        }

        &#instagram {
          span {
            i {
              color:color(scl-instagram);
            }
            &:before {
              border:2px solid color(scl-instagram);
            }
          }
          h3, .h3 {
            strong {
              color:color(scl-instagram);
            }
          }
        }
        &#linkedin {
          span {
            i {
              color:color(scl-linkedin);
            }
            &:before {
              border:2px solid color(scl-linkedin);
            }
          }
          h3, .h3 {
            strong {
              color:color(scl-linkedin);
            }
          }
        }
        &#youtube {
          span {
            i {
              color:color(scl-youtube);
            }
            &:before {
              border:2px solid color(scl-youtube);
            }
          }
          h3, .h3 {
            strong {
              color:color(scl-youtube);
            }
          }
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            background-color:color(white);

            span {
              &:before {
                opacity:0.8;
              }
            }
            &:after {
              background-color:color(dark);
            }
          }
        }
      }
    }
  }
}

.service-teaser-list {
  margin-top:10px;
  filter:drop-shadow(0px 0px 45px rgba(0,0,0,0.06));

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(10px + 4 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:14px;
  }

  &.mt-0 {
    > div {
      @include media-breakpoint-up(lg) {
        margin-top:0;
      }
    }
  }

  > div {
    margin-top:20px;

    .service-teaser {
      display:block; float:left;
      width:100%; height:100%;

      a {
        position:relative;
        display:flex; float:left; flex-direction:column; align-items:flex-start;
        width:100%; height:100%;
        padding:30px 20px 36px 20px;
        border-radius:0 0 7px 7px;
        background-color:color(white);

        @include media-breakpoint-up(xs-375) {
          padding:calc(30px + 30 * ((100vw - 375px) / 1065)) calc(20px + 10 * ((100vw - 375px) / 1065)) calc(36px + 30 * ((100vw - 375px) / 1065)) calc(20px + 10 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:60px 30px 66px 30px;
        }

        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:3px;
          background-color:color(red);
        }
        h3, .h3 {
          font-size:20px; line-height:26px;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            font-size:calc(20px + 4 * ((100vw - 375px) / 1065)); line-height:calc(26px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(lg) {
            max-width:234px;
            padding-right:calc(0px + 24 * ((100vw - 992px) / 448));
          }
          @include media-breakpoint-up(xl-1440) {
            padding-right:24px;
            font-size:24px; line-height:30px;
          }
        }
        p {
          margin-top:10px; margin-bottom:20px;
          font-size:14px; line-height:22px; letter-spacing:0; color:color(dark-grey);

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(10px + 16 * ((100vw - 375px) / 1065)); margin-bottom:calc(20px + 36 * ((100vw - 375px) / 1065));
            font-size:calc(14px + 2 * ((100vw - 375px) / 1065)); line-height:calc(22px + 1 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(lg) {
            max-width:234px;
            padding-right:calc(0px + 24 * ((100vw - 992px) / 448));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:26px; margin-bottom:56px; padding-right:24px;
            font-size:16px; line-height:23px;
          }
        }
        span {
          position:relative;
          float:right;
          padding-right:24px; margin-top:auto; margin-left:auto;
          font-size:14px; line-height:20px; letter-spacing:0.32px; color:color(red); font-family:$font_frutiger_bold; font-weight:normal;

          @include media-breakpoint-up(xs-375) {
            padding-right:calc(24px + 6 * ((100vw - 375px) / 1065));
            font-size:calc(14px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding-right:30px;
            font-size:16px;
          }

          i {
            position:absolute; right:0; top:0;
            width:20px; height:20px;
            font-size:16px; line-height:20px; color:color(red);

            @include media-breakpoint-up(xs-375) {
              font-size:calc(16px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              font-size:20px;
            }
          }
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            h3, .h3 {
              color:color(red);
            }
          }
        }
      }
    }
  }
}

.gallery-slider {
  float:left;
  width:100%;

  .swiper {
    &.main {
      margin-top:26px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(26px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:30px;
      }

      .swiper-slide {
        picture {
          border-radius:7px;
          overflow:hidden;
        }
      }
    }
    &.thumb {
      display:none;
      width:calc(100% + 12px);
      margin-left:-6px; margin-right:-6px;
      margin-top:12px; margin-bottom:20px;
      padding-bottom:30px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(12px + 4 * ((100vw - 375px) / 1065)); margin-bottom:calc(20px + 10 * ((100vw - 375px) / 1065));
        padding-bottom:calc(30px + 10 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:16px; margin-bottom:30px;
        padding-bottom:40px;
      }

      &.swiper-initialized {
        display:block;
      }

      .swiper-slide {
        padding-left:6px; padding-right:6px;
        cursor:pointer;

        picture {
          position:relative;
          border-radius:3px;
          overflow:hidden;

          img {
            position:relative;
            z-index:1;
          }
          &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:100%; height:100%;
            border:3px solid color(red); border-radius:3px;
            opacity:0;
            z-index:2;
            @include prefix(transition, all .2s ease-out, webkit moz o ms);
          }
        }

        &.swiper-slide-thumb-active {
          picture {
            &:before {
              opacity:1;
            }
          }
        }
      }

      .swiper-scrollbar {
        position:absolute; left:50%; bottom:0;
        @include prefix(transform, translateX(-50%), webkit moz o ms);
      }
    }
  }
}

.swiper-horizontal>.swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
  position:relative; left:inherit; bottom:inherit;
  display:block; float:left;
  width:74%; height:6px;
  background-color:transparent;
  overflow:hidden;

  @include media-breakpoint-up(sm) {
    width:64%;
  }
  @include media-breakpoint-up(md) {
    width:54%;
  }
  @include media-breakpoint-up(lg) {
    width:44%;
  }
  @include media-breakpoint-up(xl) {
    width:34%;
  }

  &:before {
    content:'';
    position:absolute; left:0; top:2px;
    width:100%; height:2px;
    border-radius:2px;
    background-color:color(med-grey);
    z-index:1;
  }

  .swiper-scrollbar-drag {
    border-radius:6px;
    background-color:color(red);
    z-index:2;
  }
}

.news-teaser-list {
  margin-top:-20px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(-20px - 12 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:-32px;
  }

  > div {
    margin-top:20px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(20px + 12 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:32px;
    }
  }
}

.news-teaser {
  display:block; float:left;
  width:100%; height:100%;
  @include prefix(box-shadow, 0px 0px 40px 0px rgba(0,0,0,0.06), webkit moz o ms);

  a {
    display:flex; flex-direction:column-reverse;
    width:100%; height:100%;
    border-radius:6px;
    background-color:color(white);
    overflow:hidden;

    @include media-breakpoint-up(xs-480) {
      flex-direction:row;
    }

    .content {
      position:relative;
      float:left;
      width:100%;
      padding:20px 50px 24px 20px;

      @include media-breakpoint-up(xs-375) {
        padding:calc(20px + 16 * ((100vw - 375px) / 1065)) calc(50px + 40 * ((100vw - 375px) / 1065)) calc(24px + 14 * ((100vw - 375px) / 1065)) calc(20px + 10 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding:36px 90px 38px 30px;
      }

      &:after {
        content:$icon-mendrisio-chevron-right;
        position:absolute; right:20px; bottom:30px;
        width:13px; height:13px;
        font-size:13px; line-height:13px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          right:calc(20px + 20 * ((100vw - 375px) / 1065)); bottom:calc(30px + 16 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          right:40px; bottom:46px;
        }
      }

      time {
        display:block; float:left;
        width:100%;
        margin-bottom:4px;
        font-size:12px; line-height:22px; color:color(dark-grey); font-family:$font_frutiger;

        @include media-breakpoint-up(xs-375) {
          margin-bottom:calc(4px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-bottom:8px;
        }
      }
      h3, .h3 {
        line-height:26px; color:color(red);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          line-height:calc(26px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          line-height:30px;
        }
      }
    }
    .image {
      float:left;
      width:100%; min-height:124px;
      background-position:center; background-repeat:no-repeat; background-size:cover;
      flex-shrink:0;

      @include media-breakpoint-up(xs-480) {
        width:39.3%;
        min-height:calc(124px + 60 * ((100vw - 480px) / 960));
      }
      @include media-breakpoint-up(xl-1440) {
        line-height:30px;
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        .content {
          h3, .h3 {
            color:color(dark);
          }
          &:after {
            color:color(dark);
          }
        }
      }
    }
  }
}

.document-wrapper-list {
  margin-top:6px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(6px + 8 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:14px;
  }

  > li {
    padding:20px 0 20px 0;
    border-bottom:1px solid color(med-grey);

    @include media-breakpoint-up(xs-375) {
      padding:calc(20px + 16 * ((100vw - 375px) / 1065)) 0 calc(20px + 28 * ((100vw - 375px) / 1065)) 0;
    }
    @include media-breakpoint-up(xl-1440) {
      padding:36px 0 48px 0;
    }

    &:has(.document.contract) {
      padding:0;
      margin-top:20px;
      border-bottom:0 none;
  
      @include media-breakpoint-up(xs-375) {
        margin-top:calc(20px + 6 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:26px;
      }
    }

    .document {
      display:flex; flex-wrap:wrap; flex-direction:column; align-items:flex-start;
      width:100%;

      @include media-breakpoint-up(sm) {
        flex-direction:row;
      }

      > ul {
        margin-bottom:10px;

        @include media-breakpoint-up(xs-375) {
          margin-bottom:calc(10px + 6 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-bottom:16px;
        }

        li {
          width:auto;
          margin-right:14px; margin-bottom:8px;

          @include media-breakpoint-up(xs-375) {
            margin-right:calc(14px + 8 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-right:22px;
          }

          &:last-child {
            margin-right:0;
          }

          > time, > span, > a {
            display:block; float:left;
            width:auto;
          }
          > time {
            font-size:14px; line-height:27px; color:color(black); font-family:$font_frutiger_bold; letter-spacing:0.02em;
          }
          > span, > a {
            position:relative;
            padding:5px 13px 4px 13px; border-radius:5px;
            font-size:11px; line-height:18px; color:color(dark); font-family:$font_frutiger_bold; letter-spacing:0.1em; text-transform:uppercase;

            &:before {
              content:'';
              position:absolute; left:0; top:0;
              width:100%; height:100%;
              border-radius:5px;
              border:2px solid color(dark-grey,0.3);
            }
          }
        }
      }
      .code {
        width:100%;
        padding-right:24px;

        @include media-breakpoint-up(sm) {
          width:calc(90px + 20 * ((100vw - 576px) / 864));
        }
        @include media-breakpoint-up(xl-1440) {
          width:110px;
        }

        p {
          font-size:13px; line-height:26px; font-family:$font_frutiger; color:color(black);

          @include media-breakpoint-up(xs-375) {
            font-size:calc(13px + 1 * ((100vw - 375px) / 1065)); line-height:calc(26px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            font-size:14px; line-height:30px;
          }
        }

        & + .content {
          @include media-breakpoint-up(sm) {
            width:calc(100% - (180px + 20 * ((100vw - 576px) / 864)));
          }
          @include media-breakpoint-up(xl-1440) {
            width:calc(100% - 200px);
          }
        }
      }
      .content {
        display:flex; flex-direction:column; align-items:flex-start;
        float:left;
        width:100%;

        @include media-breakpoint-up(sm) {
          width:calc(100% - 90px);
          padding-right:calc(40px + 80 * ((100vw - 576px) / 864));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-right:120px;
        }

        .note {
          display:block; float:left;
          width:100%;
          margin-bottom:8px;
          font-size:14px; line-height:20px; color:color(dark-grey); font-family:$font_frutiger; letter-spacing:0.02em;

          @include media-breakpoint-up(xs-375) {
            margin-bottom:calc(8px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-bottom:10px;
          }
        }
        h3, .h3 {
          font-size:20px; line-height:26px; color:color(red);

          @include media-breakpoint-up(xs-375) {
            font-size:calc(20px + 4 * ((100vw - 375px) / 1065)); line-height:calc(26px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            font-size:24px; line-height:30px;
          }
        }
        p {
          margin-top:12px;
          font-family:$font_frutiger_light; letter-spacing:0.02em;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(12px + 8 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:20px;
          }

          strong {
            font-family:$font_frutiger;
          }
        }
        .download-list {
          margin-top:8px;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(8px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:10px;
          }

          li {
            margin-top:8px;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(8px + 2 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:10px;
            }

            &:before {
              content:none;
            }

            a {
              position:relative;
              display:block; float:left;
              width:auto;
              padding:5px 0 5px 40px;
              font-size:13px; line-height:18px; font-family:$font_frutiger; color:color(dark); text-decoration:none!important;

              @include media-breakpoint-up(xs-375) {
                padding:calc(5px - 2 * ((100vw - 375px) / 1065)) 0 calc(5px - 2 * ((100vw - 375px) / 1065)) calc(40px + 6 * ((100vw - 375px) / 1065));
                font-size:calc(13px + 1 * ((100vw - 375px) / 1065)); line-height:calc(18px + 4 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                padding:3px 0 3px 46px;
                font-size:14px; line-height:22px;
              }
              i {
                position:absolute; left:0; top:0;
                width:28px; height:28px;
                border-radius:50%;
                font-size:14px; line-height:28px; text-align:center; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
                background-color:color(red);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
              }

              @include media-breakpoint-up(lg) {
                &:hover {
                  i {
                    background-color:color(dark);
                  }
                }
              }
            }
          }
        }

        .btn-link {
          margin-top:8px;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(8px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:10px;
          }
        }
      }
      .download, .detail {
        float:left;
        width:100%;
        margin-top:16px;

        @include media-breakpoint-up(sm) {
          width:90px;
          padding:0 10px; margin-top:0;
        }

        a {
          display:flex; flex-direction:row; align-items:center;
          font-size:12px; line-height:16px; color:color(red); letter-spacing:0.02em; font-family:$font_frutiger_bold; text-transform:uppercase; text-align:center;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(sm) {
            flex-direction:column;
          }

          i {
            display:block;
            width:38px; height:38px;
            margin-right:10px;
            border-radius:50%;
            font-size:17px; line-height:38px; color:color(red); text-align:center;
            background-color:color(grey);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(sm) {
              margin-bottom:calc(10px + 2 * ((100vw - 576px) / 864)); margin-right:0;
            }
            @include media-breakpoint-up(xl-1440) {
              margin-bottom:12px;
            }
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(dark);

              i {
                color:color(dark);
              }
            }
          }
        }
      }

      &.download-top {
        > ul {
          @include media-breakpoint-up(sm) {
            padding-right:calc(40px + 80 * ((100vw - 576px) / 864));
          }
          @include media-breakpoint-up(xl-1440) {
            padding-right:120px;
          }
        }
        .download {
          @include media-breakpoint-up(sm) {
            margin-top:calc(-46px - 5 * ((100vw - 576px) / 864));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:-51px;
          }
        }
      }

      &.contract {
        padding:20px 20px 32px 20px;
        border-radius:6px;
        background-color:color(white);
        @include prefix(box-shadow, 0px 4px 40px 0px rgba(0,0,0,0.06), webkit moz o ms);

        @include media-breakpoint-up(sm) {
          padding:calc(20px + 5 * ((100vw - 576px) / 864)) calc(20px + 70 * ((100vw - 576px) / 864)) calc(32px + 20 * ((100vw - 576px) / 864)) calc(20px + 10 * ((100vw - 576px) / 864));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:25px 90px 52px 30px;
        }

        > ul {
          li {
            margin-right:8px;
  
            &:has(time) {
              width:100%;
              margin-right:14px;
    
              @include media-breakpoint-up(xs-375) {
                margin-right:calc(14px + 8 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(sm) {
                width:auto;
              }
              @include media-breakpoint-up(xl-1440) {
                margin-right:22px;
              }
            }
            > span, > a {
              &.bkg-grey {
                &:before {
                  border-color:transparent;
                }
              }
            }
          }
        }
        .content {  
          @include media-breakpoint-up(sm) {
            width:calc(100% - 140px);
          }

          h3, .h3 {
            font-size:18px; line-height:24px; color:color(red);
  
            @include media-breakpoint-up(xs-375) {
              font-size:calc(18px + 4 * ((100vw - 375px) / 1065)); line-height:calc(24px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              font-size:22px; line-height:28px;
            }
          }
        }
        .download, .detail {
          @include media-breakpoint-up(sm) {
            width:140px;
          }
        }
      }
    }
  }
}
