.section-head {
  margin-bottom:24px;

  @include media-breakpoint-up(xs-375) {
    margin-bottom:calc(24px + 2 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-bottom:26px;
  }

  &.mt {
    margin-top:40px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(40px + 16 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:56px;
    }
  }

  h3, .h3 {
    font-size:22px; line-height:30px; font-family:$font_frutiger_light;

    @include media-breakpoint-up(xs-375) {
      font-size:calc(22px + 6 * ((100vw - 375px) / 1065)); line-height:calc(30px + 6 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      font-size:28px; line-height:36px;
    }

    strong {
      font-family:$font_frutiger_bold;
    }
  }
}

.bottom-btn {
  margin-top:28px;

  > div {
    text-align:center; font-size:0;

    .btn {
      float:none;

      @include media-breakpoint-up(lg) {
        float:right;
      }
    }
    .btn-more {
      margin-top:8px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(8px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:12px;
      }
    }
  }
}

.text-article {
  display:block; float:left;
  width:100%;

  > p, > h2, > .h2, > h3, > .h3, > h4, > .h4, > h5, > .h5 {
    @include media-breakpoint-up(md) {
      float:none;
    }
  }

  > p, > a, > ul, > ol, figure {
    margin-top:20px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(20px + 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:28px;
    }
  }
  > h2, > .h2 {
    margin-top:30px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(30px + 14 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:44px;
    }
  }
  > h3, > .h3 {
    margin-top:30px;
    font-size:24px; line-height:30px; font-family:$font_frutiger_light;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(30px + 14 * ((100vw - 375px) / 1065));
      font-size:calc(24px + 4 * ((100vw - 375px) / 1065)); line-height:calc(30px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:44px;
      font-size:28px; line-height:34px;
    }
  }
  > h4, > .h4 {
    margin-top:30px;
    font-size:22px; line-height:28px; font-family:$font_frutiger; color:color(red);

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(30px + 14 * ((100vw - 375px) / 1065));
      font-size:calc(22px + 2 * ((100vw - 375px) / 1065)); line-height:calc(28px + 2 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:44px;
      font-size:24px; line-height:30px;
    }

    & + h5, & + .h5 {
      margin-top:20px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(20px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:24px;
      }
    }
  }
  > h5, > .h5 {
    margin-top:30px;
    font-size:18px; line-height:26px; font-family:$font_frutiger; color:color(black);

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(30px + 14 * ((100vw - 375px) / 1065));
      font-size:calc(18px + 2 * ((100vw - 375px) / 1065)); line-height:calc(26px + 2 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:44px;
      font-size:20px; line-height:28px;
    }
  }

  figure {
    margin-bottom:20px;

    @include media-breakpoint-up(xs-375) {
      margin-bottom:calc(20px + 10 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-down(md) {
      margin-bottom:0!important;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:30px;
    }

    img {
      border-radius:6px;
    }

    &.full {
      margin-bottom:30px;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(30px + 40 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:70px;
      }
    }
    &.auto {
      img {
        width:auto; max-width:100%;
        border-radius:0;
      }
    }

    &.half, &.half-small {
      float:left;
      width:100%;
      margin-bottom:20px!important;

      @include media-breakpoint-up(md) {
        width:50%;

        &.right {
          float:right;
          margin-left:24px;
        }
        &.left {
          float:left;
          margin-right:24px;
        }
      }
      @include media-breakpoint-up(lg) {
        width:54.4%;
      }
    }
    &.half-small {
      @include media-breakpoint-up(lg) {
        width:48.8%;
      }
    }
    &.first + * {
      margin-top:0!important;
    }
    &.top-img {
      width:100%;
      margin-top:-26px!important;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(-26px - 20 * ((100vw - 375px) / 1065))!important;
      }
      @include media-breakpoint-down(md) {
        margin-bottom:0!important;
      }
      @include media-breakpoint-up(md) {
        width:50%;

        &.right {
          float:right;
          margin-left:24px;
        }
        &.left {
          float:left;
          margin-right:24px;
        }
      }
      @include media-breakpoint-up(lg) {
        width:60%;

        &.right {
          margin-right:-10.5%;
        }
        &.left {
          margin-left:-10.5%;
        }
      }
      @include media-breakpoint-up(xl) {
        width:70.4%;

        &.right {
          margin-left:34px; margin-right:-25.5%;
        }
        &.left {
          margin-right:34px; margin-left:-25.5%;
        }
      }
      @include media-breakpoint-up(xl-1440) {
        width:74.4%;
        margin-top:-46px!important;

        &.right {
          margin-right:-27.5%;
        }
        &.left {
          margin-left:-27.5%;
        }
      }
    }

    figcaption {
      display:block; float:left;
      width:100%;
      margin-top:6px;
      font-size:12px; line-height:20px; font-family:$font_frutiger; color:color(dark-grey);

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(6px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:8px;
      }
    }
  }

  > ul, > ol {
    @include media-breakpoint-up(md) {
      width:auto;
      display:grid; float:none;
    }
    li {
      position:relative;
      padding-left:18px; margin-top:5px;
      font-size:16px; line-height:26px; color:color(dark); font-family:$font_frutiger_light; letter-spacing:0.02em;

      @include media-breakpoint-up(xs-375) {
        padding-left:calc(18px + 4 * ((100vw - 375px) / 1065));
        font-size:calc(16px + 2 * ((100vw - 375px) / 1065)); line-height:calc(26px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding-left:22px;
        font-size:18px; line-height:28px;
      }

      &:first-child {
        margin-top:0;
      }

      strong {
        font-family:$font_frutiger_bold; font-weight:normal;
      }
      a {
        color:color(red);
        text-decoration:underline!important;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(dark);
          }
        }
      }
    }
  }
  > ul {
    li {
      &:before {
        content:'';
        position:absolute; left:0; top:11px;
        width:4px; height:4px;
        border-radius:50%;
        background-color:color(red);

        @include media-breakpoint-up(xs-375) {
          top:calc(11px + 1 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          top:12px;
        }
      }
    }
  }
  > ol {
    counter-reset:olli;

    li {
      &:before {
        position:absolute; left:0; top:0;
        content:counter(olli, decimal)'.';
        counter-increment:olli;
        border:0 none;
        text-align:left; font-size:12px; line-height:26px; color:color(red); font-family:$font_frutiger_bold; font-weight:normal;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(12px + 2 * ((100vw - 375px) / 1065)); line-height:calc(26px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:14px; line-height:28px;
        }
      }
    }
  }
  .btn-list {
    display:inline-flex; flex-direction:row; justify-content:center;
    margin-top:10px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(10px + 6 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:16px;
    }

    ul {
      display:flex; flex-direction:column;
      float:none;
      width:auto;

      li {
        display:flex; flex-direction:row; flex-wrap:wrap; justify-content:flex-start;
        float:none;
        width:auto;
        padding-left:0; margin-top:10px;

        &:before {
          content:none;
        }

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(10px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:12px;
        }

        a.btn {
          display:flex; align-items:center; justify-content:space-between;
          width:100%;
          text-decoration:none!important; text-align:left;
        }
      }
    }
  }
  .btn-link {
    font-size:16px;
  }
  .video-wrapper {
    position:relative;
    float:left;
    width:100%;
    padding-bottom:56.25%;
    margin-top:26px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(26px + 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(md) {
      float:none;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:34px;
    }

    iframe {
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      border:0 none;
    }
  }
  > .document-list {
    margin-top:20px;
    padding:10px 0;
    border-radius:6px;
    background-color:color(white);
    @include prefix(filter, drop-shadow(0px 0px 40px rgba(0,0,0,0.06)), webkit moz o ms);

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(20px + 2 * ((100vw - 375px) / 1065));
      padding:calc(10px + 1 * ((100vw - 375px) / 1065)) 0;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:22px;
      padding:11px 0;
    }

    li {
      padding:10px 20px;
      letter-spacing:0;

      @include media-breakpoint-up(xs-375) {
        padding:calc(10px + 1 * ((100vw - 375px) / 1065)) calc(20px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding:11px 22px;
      }

      &:before {
        content:none;
      }

      a {
        position:relative;
        display:block; float:left;
        width:auto;
        padding:5px 0 5px 40px;
        font-size:13px; line-height:18px; font-family:$font_frutiger; color:color(red); text-decoration:none!important;

        @include media-breakpoint-up(xs-375) {
          padding:calc(5px - 2 * ((100vw - 375px) / 1065)) 0 calc(5px - 2 * ((100vw - 375px) / 1065)) calc(40px + 10 * ((100vw - 375px) / 1065));
          font-size:calc(13px + 1 * ((100vw - 375px) / 1065)); line-height:calc(18px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:3px 0 3px 50px;
          font-size:14px; line-height:22px;
        }
        i {
          position:absolute; left:0; top:0;
          width:28px; height:28px;
          border-radius:50%;
          font-size:14px; line-height:28px; text-align:center; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          background-color:color(red);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            i {
              background-color:color(dark);
            }
          }
        }
      }
    }
  }
  > .table-responsive, > .map-wrapper {
    margin-top:20px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(20px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:24px;
    }
  }
}

div {
  > .row {
    &:first-child {
      > div {
        > .text-article {
          > * {
            &:first-child {
              margin-top:0;
            }
          }
        }
      }
    }
  }
}

.contact-box {
  position:relative;
  display:block; float:left;
  width:100%;
  padding:0 24px; margin-top:38px; margin-bottom:10px;
  border-radius:0 6px 6px 0;
  background-color:color(white);
  @include prefix(filter, drop-shadow(0px 0px 40px rgba(0,0,0,0.06)), webkit moz o ms);

  @include media-breakpoint-up(xs-375) {
    padding:0 calc(24px + 12 * ((100vw - 375px) / 1065)); margin-top:calc(38px + 6 * ((100vw - 375px) / 1065)); margin-bottom:calc(10px - 10 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    padding:0 36px; margin-top:44px; margin-bottom:0;
  }

  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:3px; height:100%;
    background-color:color(red);
    z-index:1;
  }
  ul {
    position:relative;
    z-index:2;

    li {
      position:relative;
      padding:20px 0;

      @include media-breakpoint-up(xs-375) {
        padding:calc(20px + 14 * ((100vw - 375px) / 1065)) 0;
      }
      @include media-breakpoint-up(xl-1440) {
        padding:34px 0;
      }

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:1px;
        background-color:color(med-grey);
      }
      h3, .h3 {
        line-height:26px; font-family:$font_frutiger_bold; font-weight:normal;

        @include media-breakpoint-up(xs-375) {
          line-height:calc(26px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          line-height:30px;
        }
      }
      p {
        margin-top:10px;
        font-size:14px; line-height:20px; color:color(black); font-family:$font_frutiger_light;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(10px + 2 * ((100vw - 375px) / 1065));
          font-size:calc(14px + 2 * ((100vw - 375px) / 1065)); line-height:calc(20px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:12px;
          font-size:16px; line-height:24px;
        }

        a, strong {
           font-family:$font_frutiger;

           &.phone {
             font-family:$font_frutiger_bold; font-weight:normal;
           }
        }
      }

      &:first-child {
        &:before {
          content:none;
        }
      }
    }
  }
}

.simplebar-scrollable-y {
  padding-right:10px;

  .simplebar-track.simplebar-vertical {
    width:10px;

    &:before {
      content:'';
      position:absolute; right:0; top:0;
      width:1px; height:100%;
      background-color:color(med-grey);
      @include prefix(transform, translateX(-50%), webkit moz o ms);
    }

    .simplebar-scrollbar.simplebar-visible {
      &:before {
        left:calc(100% - 2px); right:0;
        width:2px; height:100%;
        background-color:color(scroll-grey);
        opacity:1;
      }
    }
  }
}

.cta {
  align-items:center;

  h3, .h3 {
    font-size:24px; line-height:30px; font-family:$font_frutiger_light;

    @include media-breakpoint-up(xs-375) {
      font-size:calc(24px + 4 * ((100vw - 375px) / 1065)); line-height:calc(30px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(md) {
      padding-right:30px;
    }
    @include media-breakpoint-up(xl-1440) {
      font-size:28px; line-height:34px;
    }
  }
  p {
    margin-top:8px;
    font-size:14px; line-height:22px; font-family:$font_frutiger; color:color(dark-grey);

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(8px + 8 * ((100vw - 375px) / 1065));
      font-size:calc(14px + 1 * ((100vw - 375px) / 1065)); line-height:calc(22px + 2 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(md) {
      padding-right:30px;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:16px;
      font-size:15px; line-height:24px;
    }
  }
  .btn {
    float:left;
    margin-top:20px;

    @include media-breakpoint-up(md) {
      float:right;
      margin-top:0;
    }
  }
}

.cta-box {
  display:block; float:left;
  width:100%;
  padding:30px 22px; margin-top:34px;
  background-color:color(grey);

  @include media-breakpoint-up(xs-375) {
    padding:calc(30px + 10 * ((100vw - 375px) / 1065)) calc(22px + 8 * ((100vw - 375px) / 1065)); margin-top:calc(34px + 14 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    padding:40px 30px; margin-top:48px;
  }

  .cta {
    h3, .h3 {
      padding-right:0;

      @include media-breakpoint-up(lg) {
        padding-right:30px;
      }
    }
    p {
      padding-right:0;

      @include media-breakpoint-up(lg) {
        padding-right:30px;
      }
    }
    .btn {
      float:left;
      margin-top:20px;

      @include media-breakpoint-up(lg) {
        float:right;
        margin-top:0;
      }
    }
  }
}

.service-user {
  float:left;
  width:100%;
  margin-top:16px;

  ul {
    padding:8px 0;
    border-radius:6px;
    background-color:color(white);
    @include prefix(box-shadow, 0px 4px 40px 0px rgba(0,0,0,0.06), webkit moz o ms);

    li {
      width:50%;
      padding:12px 20px;
      text-align:center;

      @include media-breakpoint-up(sm) {
        width:calc(99.99% / 3);
      }
      @include media-breakpoint-up(lg) {
        width:calc(99.99% / 6);
      }

      a {
        display:flex; flex-direction:column; align-items:center;
        width:100%;

        picture {
          height:40px; width:auto;
          margin-bottom:6px;

          @include media-breakpoint-up(xs-375) {
            height:calc(40px + 10 * ((100vw - 375px) / 1065));
            margin-bottom:calc(6px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            height:50px;
            margin-bottom:10px;
          }

          img {
            height:100%; width:auto;
          }
        }
        span {
          position:relative;
          padding-right:20px;
          font-size:16px; line-height:24px; color:color(dark); font-family:$font_frutiger_bold; text-align:center;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            padding-right:calc(20px + 6 * ((100vw - 375px) / 1065));
            font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(24px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            padding-right:26px;
            font-size:20px; line-height:28px;
          }


          &:after {
            content:$icon-mendrisio-chevron-right;
            position:absolute; right:0; top:50%;
            width:12px; height:12px;
            margin-top:0;
            font-size:12px; line-height:12px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
            @include prefix(transform, translateY(-50%), webkit moz o ms);

            @include media-breakpoint-up(xs-375) {
              width:calc(12px + 1 * ((100vw - 375px) / 1065)); height:calc(12px + 1 * ((100vw - 375px) / 1065));
              margin-top:calc(0px + 1 * ((100vw - 375px) / 1065));
              font-size:calc(12px + 1 * ((100vw - 375px) / 1065)); line-height:calc(12px + 1 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              width:13px; height:13px;
              margin-top:1px;
              font-size:13px; line-height:13px;
            }
          }
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            span {
              color:color(red);
            }
          }
        }
      }
    }
  }
}
