.search-results-list {
  li {
    padding:20px 0;
    border-bottom:1px solid color(med-grey);

    @include media-breakpoint-up(xs-375) {
      padding:calc(20px + 26 * ((100vw - 375px) / 1065)) 0;
    }
    @include media-breakpoint-up(xl-1440) {
      padding:46px 0;
    }

    .result {
      display:block; float:left;
      width:100%;

      a {
        display:block; float:left;
        width:100%;

        h3, .h3 {
          font-size:20px; line-height:26px; color:color(red);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            font-size:calc(20px + 4 * ((100vw - 375px) / 1065)); line-height:calc(26px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            font-size:24px; line-height:30px;
          }
        }
        > span {
          position:relative;
          display:block; float:left;
          width:auto;
          padding:5px 13px 4px 13px; border-radius:5px;
          margin-top:8px;
          font-size:11px; line-height:18px; font-family:$font_frutiger_bold; letter-spacing:0.1em; text-transform:uppercase; color:color(dark);
          background-color:color(white);

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(8px + 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:12px;
          }

          &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:100%; height:100%;
            border:2px solid color(dark-grey,0.3); border-radius:5px;
          }
        }
        p {
          margin-top:12px;
          font-family:$font_frutiger_light; letter-spacing:0.02em;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(12px + 8 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:20px;
          }

          mark {
            padding:0;
            font-family:$font_frutiger_bold; color:color(red);
            background-color:color(grey);
          }
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            h3, .h3 {
              color:color(dark);
            }
          }
        }
      }
    }
  }
}
