.header {
  position:sticky; top:0; left:0;
  display:block; float:left;
  width:100%;
  padding-left:6px; padding-right:6px;
  background-color:color(white);
  z-index:99;

  > .container {
    position:relative;
    padding-top:20px; padding-bottom:20px;
    z-index:2;

    @include media-breakpoint-up(lg) {
      padding-top:17px; padding-bottom:calc(25px + 10 * ((100vw - 992px) / 448));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-bottom:35px;
    }

    .row {
      > div {
        display:flex; justify-content:space-between; justify-content:flex-start;
      }
    }
  }

  .header-logo {
    display:block; float:left;
    width:117px;
    flex-shrink:0;

    @include media-breakpoint-up(lg) {
      width:170px;
      margin-right:calc(50px + 50 * ((100vw - 992px) / 448)); margin-top:12px;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-right:100px;
    }
  }
  .menu-wrapper {
    float:left;
    width:100%;

    .utility-menu, .main-menu {
      float:left;
      width:100%;

      ul {
        width:auto;

        li {
          width:auto;
        }
      }
    }
    .utility-menu {
      ul {
        display:flex; flex-direction:row; justify-content:flex-end; align-items:center;
        float:right;

        @include media-breakpoint-up(lg) {
          align-items:flex-start;
        }

        li {
          margin-left:14px;

          @include media-breakpoint-up(lg) {
            margin-left:25px;
          }

          &:first-child {
            margin-left:0;
          }

          a {
            display:block; float:left;
            width:auto;
            font-size:14px; line-height:16px; color:color(dark); letter-spacing:0; text-decoration:none;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            i {
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }

            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(red);
              }
            }
          }

          &.active {
            a {
              color:color(red); font-family:$font_frutiger_bold; font-weight:normal;
            }
          }
          &.highlight {
            position:relative;

            a {
              position:relative;
              padding-right:28px;
              color:color(red); font-family:$font_frutiger_bold; text-align:right; font-weight:normal;

              @include media-breakpoint-up(lg) {
                padding-right:24px;
              }

              i {
                position:absolute; right:0; top:50%;
                display:block; float:right;
                height:16px;
                font-size:18px; line-height:16px;
                @include prefix(transform, translateY(-50%), webkit moz o ms);

                @include media-breakpoint-up(lg) {
                  font-size:14px;
                }
              }

              @include media-breakpoint-up(lg) {
                &:hover {
                  color:color(dark);
                }
              }
            }

            &.active {
              &:before {
                content:'';
                position:absolute; right:-8px; top:-21px;
                width:34px; height:3px;
                background-color:color(red);

                @include media-breakpoint-up(xs-375) {
                  width:calc(34px + 10 * ((100vw - 375px) / 1065));
                }
                @include media-breakpoint-up(lg) {
                  right:calc(-13px - 2 * ((100vw - 992px) / 448)); top:-17px;
                  width:44px;
                }
                @include media-breakpoint-up(xl-1440) {
                  right:-15px;
                }
              }
              a {
                color:color(red)!important;
              }
            }
          }
          &.lang {
            position:relative;
            padding-right:25px;
            border-right:1px solid color(med-grey);

            > a {
              position:relative;
              padding-right:24px;
              text-transform:uppercase;

              &:after {
                content:$icon-mendrisio-lang;
                position:absolute; right:0; top:0;
                width:16px; height:16px;
                font-size:16px; line-height:16px; color:color(dark); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
              }

              @include media-breakpoint-up(lg) {
                &:hover {
                  color:color(dark);

                  &:after {
                    color:color(dark);
                  }
                }
              }
            }

            ul {
              position:absolute; right:25px; top:-9999px;
              display:flex; flex-direction:column;
              padding:3px 20px;
              border-radius:4px;
              background-color:color(white);
              opacity:0;
              @include prefix(transition, opacity .3s ease-out, webkit moz o ms);
              @include prefix(box-shadow, 0px 4px 40px 0px rgba(0,0,0,0.06), webkit moz o ms);

              &:before {
                content:'';
                position:absolute; left:0; bottom:100%;
                width:100%; height:12px;
                background-color:transparent;
              }

              li {
                width:100%;
                padding:12px 0; margin-left:0;
                border-bottom:1px solid color(grey);

                a {
                  letter-spacing:0.03em; font-family:$font_frutiger_light;
                }

                &:last-child {
                  border-bottom:0 none;
                }

                &.active {
                  a {
                    font-family:$font_frutiger_bold;
                  }
                }
              }
            }
            @include media-breakpoint-up(lg) {
              &:hover {
                ul {
                  top:calc(100% + 12px);
                  opacity:1;
                }
              }
            }
          }
          &.search {
            position:relative;
            margin-top:-17px; margin-left:0; margin-bottom:-10px;

            &:before {
              content:'';
              position:absolute; right:100%; top:0;
              width:2px; height:100%;
              background-color:color(white);
            }

            a {
              height:43px;
              padding:16px 22px 11px 22px;
              background-color:color(grey);

              @include media-breakpoint-up(lg) {
                &:hover {
                  background-color:color(red);

                  i {
                    color:color(white);
                  }
                }
              }
            }
          }
          &.mobile-menu {
            a {
              position:relative;
              display:block; float:left;
              margin-right:-12px;
              width:44px; height:34px;

              i {
                position:absolute; left:50%; top:50%;
                width:20px; height:20px;
                font-size:20px; line-height:20px; color:color(black);
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
              }
            }
          }
        }
      }
    }
    .main-menu {
      margin-top:22px;

      ul {
        float:left;

        li {
          margin-right:40px;

          &:last-child {
            margin-right:0;
          }

          a {
            display:block; float:left;
            width:auto;
            font-size:20px; line-height:24px; color:color(dark); letter-spacing:0.4px; text-decoration:none;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              a {
                color:color(red);
              }
            }
          }

          &.active {
            margin-right:37px;

            a {
              color:color(red); font-family:$font_frutiger_bold; font-weight:normal;
            }
          }
          &.sub {
            position:relative;

            &.open {
              a {
                color:color(red);
              }
            }
          }
        }
      }
    }
  }

  .submenu-wrapper {
    position:absolute; left:0; top:100%;
    display:none;
    width:100%;
    z-index:1;

    @include media-breakpoint-up(lg) {
      display:block;
    }

    > div {
      position:absolute; left:50%; top:0;
      width:100%; height:auto;
      background-color:color(white);
      opacity:0;
      @include prefix(transform, translate(-50%,-9999px), webkit moz o ms);
      @include prefix(transition, opacity .3s ease-out, webkit moz o ms);
      overflow:hidden;

      &.open {
        opacity:1;
        @include prefix(transform, translate(-50%,0), webkit moz o ms);
      }
      &.closing {
        opacity:0!important;
      }

      .container {
        position:relative;
        padding-top:40px; padding-bottom:50px;

        @include media-breakpoint-up(lg) {
          padding-top:calc(40px + 5 * ((100vw - 992px) / 448)); padding-bottom:calc(50px + 8 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          padding-top:45px; padding-bottom:58px;
        }

        &:before {
          content:'';
          position:absolute; left:50%; top:0;
          width:calc(100% - 28px); height:2px;
          background-color:color(grey);
          @include prefix(transform, translateX(-50%), webkit moz o ms);
        }
        
        .submenu-cover {
          display:flex; flex-direction:column; align-items:flex-start;
          padding-right:20px;

          @include media-breakpoint-up(xl) {
            flex-direction:row; align-items:center;
            padding-right:calc(20px + 48 * ((100vw - 1200px) / 240));
          }
          @include media-breakpoint-up(xl-1440) {
            padding-right:68px;
          }

          picture {
            width:220px;
            margin-bottom:20px;
            flex-shrink:0;

            @include media-breakpoint-up(lg) {
              width:calc(220px + 8 * ((100vw - 992px) / 448));
            }
            @include media-breakpoint-up(xl) {
              margin-right:calc(24px + 4 * ((100vw - 1200px) / 240)); margin-bottom:0;
            }
            @include media-breakpoint-up(xl-1440) {
              width:228px;
              margin-right:28px;
            }
          }
          div {
            h3, .h3 {
              font-family:$font_frutiger_bold; font-weight:normal;
            }
            p {
              margin-top:2px;
              font-size:13px; line-height:20px; color:color(dark-grey); font-family:$font_frutiger;

              @include media-breakpoint-up(xl) {
                margin-top:calc(2px + 6 * ((100vw - 1200px) / 240));
                font-size:calc(13px + 2 * ((100vw - 1200px) / 240)); line-height:calc(20px + 4 * ((100vw - 1200px) / 240));
              }
              @include media-breakpoint-up(xl-1440) {
                margin-top:8px;
                font-size:15px; line-height:24px;
              }
            }
          }
        }
        .submenu-menu {
          float:left;
          width:calc(100% + 14px);
          margin-right:-14px;

          @include media-breakpoint-up(lg) {
            width:calc(100% + (14px + 20 * ((100vw - 992px) / 448)));
            margin-right:calc(-14px - 20 * ((100vw - 992px) / 448));
          }
          @include media-breakpoint-up(xl-1440) {
            width:calc(100% + 34px);
            margin-right:-34px;
          }

          ul {
            li {
              position:relative;
              width:50%;
              padding-right:14px; margin-top:14px;

              @include media-breakpoint-up(lg) {
                padding-right:calc(14px + 20 * ((100vw - 992px) / 448)); margin-top:calc(14px + 6 * ((100vw - 992px) / 448));
              }
              @include media-breakpoint-up(xl) {
                width:33.33%;
              }
              @include media-breakpoint-up(xl-1440) {
                padding-right:34px; margin-top:20px;
              }

              &:before {
                content:'';
                position:absolute; left:0; bottom:0;
                width:calc(100% - 14px); height:1px;
                background-color:color(med-grey);

                @include media-breakpoint-up(lg) {
                  width:calc(100% - (14px + 20 * ((100vw - 992px) / 448)));
                }
                @include media-breakpoint-up(xl-1440) {
                  width:calc(100% - 34px);
                }
              }

              a {
                position:relative;
                display:block; float:left;
                width:100%;
                padding:0 24px 14px 0;
                font-size:16px; line-height:21px; color:color(black); letter-spacing:0.36px;
                z-index:2;
                @include prefix(transition, all .2s ease-out, webkit moz o ms);

                @include media-breakpoint-up(xl) {
                  padding:0 calc(24px + 6 * ((100vw - 1200px) / 240)) calc(14px + 6 * ((100vw - 1200px) / 240)) 0;
                }
                @include media-breakpoint-up(xl-1440) {
                  padding:0 30px 20px 0;
                }

                &:before {
                  content:'';
                  position:absolute; left:0; bottom:0;
                  width:0%; height:1px;
                  background-color:color(red);
                  @include prefix(transition, all .2s ease-out, webkit moz o ms);
                }
                &:after {
                  content:$icon-mendrisio-chevron-right;
                  position:absolute; right:2px; top:4px;
                  width:13px; height:13px;
                  font-size:13px; line-height:13px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal;

                  @include media-breakpoint-up(xl) {
                    right:calc(2px + 10 * ((100vw - 1200px) / 240));
                  }
                  @include media-breakpoint-up(xl-1440) {
                    right:12px;
                  }
                }
                @include media-breakpoint-up(lg) {
                  &:hover {
                    &:before {
                      width:100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .header-search-wrapper {
    position:absolute; left:0; top:-9999px;
    display:none;
    width:100%;
    opacity:0;
    z-index:1;
    background-color:color(white);
    @include prefix(transition, opacity .3s ease-out, webkit moz o ms);

    @include media-breakpoint-up(lg) {
      display:block;
    }

    .container {
      position:relative;
      max-width:1288px;
      padding-top:30px; padding-bottom:44px;

      @include media-breakpoint-up(lg) {
        padding-top:calc(30px + 10 * ((100vw - 992px) / 448)); padding-bottom:calc(44px + 10 * ((100vw - 992px) / 448));
      }
      @include media-breakpoint-up(xl-1440) {
        padding-top:40px; padding-bottom:54px;
      }

      &:before {
        content:'';
        position:absolute; left:50%; top:0;
        width:calc(100% - 28px); height:2px;
        background-color:color(grey);
        @include prefix(transform, translateX(-50%), webkit moz o ms);
      }

      a.close {
        position:absolute; right:8px; top:14px;
        width:24px; height:24px;
        padding:6px;

        @include media-breakpoint-up(lg) {
          top:calc(14px + 16 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
          top:30px;
        }

        i {
          display:block; float:left;
          width:12px; height:12px;
          font-size:12px; line-height:12px; color:color(black);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            i {
              color:color(med-grey);
            }
          }
        }
      }
    }

    .header-search {
      position:relative;
      float:left;
      width:100%;

      form {
        position:relative;
        z-index:2;

        fieldset {
          position:relative;

          &:before {
            content:$icon-mendrisio-search;
            position:absolute; left:0; top:50%;
            width:20px; height:20px;
            font-size:20px; line-height:20px; color:color(dark); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
            @include prefix(transform, translateY(-50%), webkit moz o ms);
            z-index:2;

            @include media-breakpoint-up(lg) {
              width:calc(20px + 5 * ((100vw - 992px) / 448)); height:calc(20px + 5 * ((100vw - 992px) / 448));
              font-size:calc(20px + 5 * ((100vw - 992px) / 448)); line-height:calc(20px + 5 * ((100vw - 992px) / 448));
            }
            @include media-breakpoint-up(xl-1440) {
              width:25px; height:25px;
              font-size:25px; line-height:25px;
            }
          }
          .btn-form {
            position:absolute; right:10px; top:50%;
            width:24px; height:24px;
            border:0 none; padding:0;
            background-color:transparent;
            @include prefix(transform, translateY(-50%), webkit moz o ms);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
            z-index:2;

            i {
              display:block; float:left;
              width:24px; height:24px;
              font-size:24px; line-height:24px; color:color(red);
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }
            @include media-breakpoint-up(lg) {
              &:hover {
                i {
                  color:color(dark);
                }
              }
            }
          }
          input {
            padding:15px 54px 15px 48px;
            font-size:20px; line-height:24px; color:color(dark); font-family:$font_frutiger;
            border-bottom:1px solid color(dark-grey);
            z-index:1;

            &::-webkit-input-placeholder { color:color(dark); }
            &::-moz-placeholder { color:color(dark); }
            &:-ms-input-placeholder { color:color(dark); }
            &:-moz-placeholder { color:color(dark); }
          }
        }
      }
      .search-layer {
        float:left;
        width:100%;
        margin-top:28px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(28px + 16 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:44px;
        }

        p {
          font-size:16px; line-height:24px; color:color(dark-grey); font-family:$font_frutiger;
        }
        ul {
          width:calc(100% + 12px);
          margin-right:-12px;

          @include media-breakpoint-up(xs-375) {
            width:calc(100% + (12px + 4 * ((100vw - 375px) / 1065)));
            margin-right:calc(-12px - 4 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            width:calc(100% + 16px);
            margin-right:-16px;
          }

          li {
            width:auto;
            margin-top:12px; margin-right:12px;

            @include media-breakpoint-up(xs-375) {
              margin-top:calc(12px + 4 * ((100vw - 375px) / 1065)); margin-right:calc(12px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              margin-top:16px; margin-right:16px;
            }

            a {
              position:relative;
              display:block; float:left;
              width:auto;
              padding:6px 14px;
              font-size:14px; line-height:20px; color:color(black); font-family:$font_frutiger_bold; font-weight:normal;

              @include media-breakpoint-up(xs-375) {
                padding:calc(6px + 1 * ((100vw - 375px) / 1065)) calc(14px + 9 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                padding:7px 23px;
              }

              &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                border:2px solid color(black);
                border-radius:6px;
                opacity:0.2;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
              }

              @include media-breakpoint-up(lg) {
                &:hover {
                  &:before {
                    opacity:0.7;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

body.search-open {
  .header {
    .menu-wrapper {
      .utility-menu {
        ul {
          li {
            &.search {
              a {
                background-color:color(red);

                i {
                  color:color(white);
                }
              }
            }
          }
        }
      }
    }
    .header-search-wrapper {
      top:100%;
      opacity:1;
    }
  }
}

#mobile-menu:not(.mm-menu) {
    display:none;
}
#mobile-menu {
  width:100%;
  --mm-color-background-emphasis:transparent!important;

  .mobile-menu-close {
    position:absolute; right:0; top:0;
    width:54px; height:54px;
    color:color(black)!important;
    z-index:3;

    i {
      position:absolute; right:20px; bottom:6px;
      width:14px; height:14px;
      font-size:14px; line-height:14px; color:color(black)!important; font-weight:bold;
      z-index:2;
    }
    &:before {
      content:'';
      position:absolute; right:10px; bottom:-4px;
      width:34px; height:34px;
      border-radius:50%;
      background-color:color(white);
      z-index:1;
    }
  }

  .mm-panels {
    z-index:1;
  }

  .mm-panel {
    padding-top:20px; padding-bottom:50px;
    background-color:color(white);

    .mm-navbar {
      position:relative;
      border:0 none;
      padding-bottom:3px;
      background-color:transparent;

      &:before {
        content:'';
        position:absolute; left:20px; bottom:0;
        width:calc(100% - 40px); height:3px;
        background-color:color(grey);
      }

      .mm-btn {
        width:50px;

        &:before {
          content:none;
        }
        &:after {
          content:$icon-mendrisio-chevron-left;
          position:absolute; left:20px; top:50%;
          width:13px; height:13px;
          margin-top:-4px;
          font-size:13px; line-height:13px; color:color(black); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
          @include prefix(transform, translateY(-50%), webkit moz o ms);
          z-index:1;
        }
      }
      .mm-navbar__title {
        > span {
          width:100%;
          padding:8px 0 20px 0;
          text-align:left; color:color(dark); font-size:22px; line-height:24px; font-family:$font_frutiger_bold; letter-spacing:0.44px; font-weight:normal;
        }
        &:last-child {
          padding-inline-start:0;
        }
      }
    }

    .mm-listview {
      .mm-listitem {
        position:relative;
        padding-bottom:3px;

        &:before {
          content:'';
          position:absolute; left:20px; bottom:0;
          width:calc(100% - 40px); height:3px;
          background-color:color(grey);
        }

        a {
          padding:20px;
          font-size:18px; line-height:24px; font-family:$font_frutiger; color:color(dark); letter-spacing:0.36px;

          &.mm-btn {
            width:44px;
            padding:0;
            border:0 none;

            &:after {
              content:none;
            }
            &:before {
              content:$icon-mendrisio-chevron-right;
              position:absolute; right:20px; top:50%;
              width:13px; height:13px;
              font-size:13px; line-height:13px; color:color(black); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
              @include prefix(transform, translateY(-50%), webkit moz o ms);
              z-index:1;
            }
          }
        }
        &:after {
          content:none;
        }
        &.close {
          position:relative;
          height:0;
          padding-bottom:0;

          &:before {
            content:none;
          }
        }
        &.logo {
          picture {
            width:117px;
            margin-bottom:20px; margin-left:20px;
          }
        }
        &.utility {
          padding-bottom:0;
          margin-top:26px;

          &:first-child {
            margin-top:34px;
          }
          &:before {
            content:none;
          }

          a {
            padding:0 20px;
            font-size:16px; line-height:20px; letter-spacing:0.32px;
          }

          &.highlight {
            display:block;
            margin-top:34px;
            padding:0 20px;

            a {
              display:flex; align-items:center; justify-content:flex-start;
              float:left; width:auto;
              padding:18px 33px;
              border-radius:4px!important; border:0 none;
              font-size:17px; line-height:20px; color:color(white)!important; font-family:$font_frutiger_bold; letter-spacing:0.34px; font-weight:normal;
              background-color:color(red);
              @include prefix(transition, all .3s ease-out, webkit moz o ms);

              @include media-breakpoint-up(xs-360) {
                padding:18px 53px;
              }

              i {
                width:19px; height:19px;
                margin-left:10px;
                font-size:19px; line-height:19px; color:color(white);
              }
            }
          }
        }
        &.search {
          margin-top:40px;
          padding:0 20px;

          form {
            position:relative;
            z-index:2;

            fieldset {
              position:relative;
              border-radius:4px;
              background-color:color(grey);

              .btn-form {
                position:absolute; top:0; left:0;
                width:48px; height:100%;
                border:0 none;
                background-color:transparent;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                z-index:2;

                i {
                  position:absolute; left:50%; top:50%;
                  width:18px; height:18px;
                  font-size:18px; line-height:18px; color:color(black); font-weight:bold;
                  @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                }
                @include media-breakpoint-up(lg) {
                  &:hover {
                    width:90px;
                    background-color:color(dark);
                  }
                }
              }
              input {
                padding:24px 24px 24px 48px;
                font-size:16px; line-height:22px; color:color(black);
                z-index:1;

                &::-webkit-input-placeholder { color:color(black); }
                &::-moz-placeholder { color:color(black); }
                &:-ms-input-placeholder { color:color(black); }
                &:-moz-placeholder { color:color(black); }
              }
            }
          }
        }
        &.lang {
          display:block;
          position:relative;
          margin-top:40px;
          padding:0 20px 0 36px;

          &:before {
            content:$icon-mendrisio-lang;
            position:absolute; left:20px; top:0;
            width:16px; height:16px;
            font-size:16px; line-height:16px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
            background-color:transparent;
          }

          a {
            display:block; float:left;
            width:auto;
            padding:0 14px;
            border-right:2px solid color(grey);
            font-size:14px; line-height:16px; color:color(dark);

            &.active {
              font-family:$font_frutiger_bold; color:color(red);
            }
            &:last-child {
              border-right:0 none;
            }
          }
        }
      }
    }
  }
  #mm-1 {
    .mm-navbar {
      display:none;
    }
  }
}
