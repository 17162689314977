.profile-list {
  float:left;
  width:100%;
  margin-top:30px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(30px + 30 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:60px;
  }

  > h3, > .h3 {
    font-size:24px; line-height:30px; font-family:$font_frutiger_light;

    @include media-breakpoint-up(xs-375) {
      font-size:calc(24px + 4 * ((100vw - 375px) / 1065)); line-height:calc(30px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      font-size:28px; line-height:34px;
    }
  }

  ul {
    margin-top:14px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(14px + 6 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:20px;
    }

    li {
      margin-top:14px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(14px + 4 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:18px;
      }

      .profile {
        display:block; float:left;
        width:100%;
        border-radius:6px;
        background-color:color(white);
        @include prefix(box-shadow, 0px 4px 40px 0px rgba(0,0,0,0.06), webkit moz o ms);
        overflow:hidden;

        a {
          display:flex; flex-direction:column;
          width:100%;
          text-decoration:none;

          @include media-breakpoint-up(xs-480) {
            flex-direction:row;
          }

          .image {
            width:100%;
            padding-bottom:70%;
            flex-shrink:0;
            background-position:center; background-repeat:no-repeat; background-size:cover;

            @include media-breakpoint-up(xs-480) {
              width:40%;
              padding-bottom:0;
            }
            @include media-breakpoint-up(lg) {
              width:31.4%;
            }
          }
          .content {
            float:left;
            width:100%;
            padding:20px;

            @include media-breakpoint-up(xs-480) {
              padding:calc(20px + 25 * ((100vw - 480px) / 960)) calc(20px + 18 * ((100vw - 480px) / 960)) calc(20px + 10 * ((100vw - 480px) / 960)) calc(20px + 18 * ((100vw - 480px) / 960));
            }
            @include media-breakpoint-up(xl-1440) {
              padding:45px 38px 30px 38px;
            }
            h4, .h4 {
              font-size:18px; line-height:26px; font-family:$font_frutiger_bold;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);

              @include media-breakpoint-up(xs-375) {
                font-size:calc(18px + 4 * ((100vw - 375px) / 1065)); line-height:calc(26px + 4 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                font-size:22px; line-height:30px;
              }
            }
            p {
              margin-top:6px;
              line-height:22px; font-family:$font_frutiger_light;

              @include media-breakpoint-up(xs-375) {
                margin-top:calc(6px + 2 * ((100vw - 375px) / 1065));
                line-height:calc(22px + 2 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                margin-top:8px;
                line-height:24px;
              }

              &.role {
                display:flex; flex-direction:row;
                margin-top:22px;
                font-size:14px; line-height:22px; font-family:$font_frutiger; color:color(dark-grey);

                @include media-breakpoint-up(xs-375) {
                  margin-top:calc(22px + 26 * ((100vw - 375px) / 1065));
                }
                @include media-breakpoint-up(xl-1440) {
                  margin-top:46px;
                }

                strong {
                  font-size:11px; color:color(red); text-transform:uppercase; font-family:$font_frutiger; font-weight:normal;
                  padding-right:16px;

                  @include media-breakpoint-up(xs-375) {
                    padding-right:calc(16px + 4 * ((100vw - 375px) / 1065));
                  }
                  @include media-breakpoint-up(xl-1440) {
                    padding-right:20px;
                  }
                }
              }
            }
            .btn-link {
              float:right;
              text-decoration:none;
              padding-right:25px; margin-top:12px;
              font-size:14px; line-height:20px; letter-spacing:0.02em; color:color(red); font-family:$font_frutiger_bold; font-weight:normal;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);

              @include media-breakpoint-up(xs-375) {
                margin-top:calc(12px + 4 * ((100vw - 375px) / 1065));
                font-size:calc(14px + 2 * ((100vw - 375px) / 1065));
              }
              @include media-breakpoint-up(xl-1440) {
                margin-top:16px;
                font-size:16px;
              }

              &:after {
                content:$icon-mendrisio-arrow-right;
                position:absolute; right:0; top:2px;
                width:16px; height:16px;
                font-size:16px; line-height:16px; color:color(red); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                @include media-breakpoint-up(xs-375) {
                  top:calc(2px - 1 * ((100vw - 375px) / 1065));
                  width:calc(16px + 2 * ((100vw - 375px) / 1065)); height:calc(16px + 2 * ((100vw - 375px) / 1065));
                  font-size:calc(16px + 2 * ((100vw - 375px) / 1065)); line-height:calc(16px + 2 * ((100vw - 375px) / 1065));
                }
                @include media-breakpoint-up(xl-1440) {
                  top:1px;
                  width:18px; height:18px;
                  font-size:18px; line-height:18px;
                }
              }
            }
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              .content {
                h4, .h4 {
                  color:color(red);
                }
              }
            }
          }
        }
      }
    }
  }
}
