html {
  scroll-behavior:smooth;
}

body {
  position:relative;
  background-color:color(white);
}
.container {
  width:100%; max-width:1288px;
  padding-right:14px; padding-left:14px;

  .row {
    margin-right:-14px; margin-left:-14px;

    > div {
      padding-right:14px; padding-left:14px;
    }
  }

  &.social-box-wrapper {
    max-width:1412px;
  }
}
.container-fluid {
  width:100%;
  padding-right:0; padding-left:0;

  .row {
    margin-right:0; margin-left:0;

    > div {
      padding-right:0; padding-left:0;
    }
  }
}

.alert-wrapper {
  .container {
    max-width:1358px;
  }
}
.header, .footer {
  .container {
    max-width:1412px;
  }

  .submenu-wrapper {
    .container {
      max-width:1372px;
    }
  }
}

.content-wrapper {
  float:left;
  width:100%;
}
.main-content {
  float:left;
  width:100%;
}

section {
  position:relative;
  float:left;
  width:100%;
  margin-top:40px;
  padding-left:6px; padding-right:6px;
  z-index:1;
  overflow:hidden;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(40px + 16 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:56px;
  }

  &[class^='bkg-'], &[class*=' bkg-']{
    padding-top:36px; padding-bottom:46px;

    @include media-breakpoint-up(xs-375) {
      padding-top:calc(36px + 10 * ((100vw - 375px) / 1065)); padding-bottom:calc(46px + 10 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-top:46px; padding-bottom:56px;
    }
  }

  &.pt-sm {
    @include media-breakpoint-down(lg) {
      padding-top:70px;
    }
  }
  &.mt-more {
    margin-top:40px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(40px + 40 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:80px;
    }
  }
  &.mb-more {
    margin-bottom:10px;

    @include media-breakpoint-up(xs-375) {
      margin-bottom:calc(10px + 50 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:60px;
    }
  }

  .mt-mobile {
    margin-top:32px;

    @include media-breakpoint-up(lg) {
      margin-top:0;
    }
  }
}

.overflow-visible {
  overflow:visible;
}
.overflow-hidden {
  overflow:hidden;
}

ul, ol {
  display:block; float:left;
  width:100%;
  padding:0; margin:0;
  list-style:none;

  li {
    display:block; float:left;
    width:100%;
  }
}

a {
  text-decoration:none!important; color:inherit;
}
* {
  outline:0!important; outline:none!important;
}

picture, figure, img {
  display:block; float:left;
  width:100%; height:auto;
}
